import ActionButton from 'components/CustomButtons/ActionButton';
import GridContainer from 'components/Grid/GridContainer';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import ReactPaginate from 'react-paginate';

const useStyles = makeStyles({
  'centered-pagination-link': {
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  }
});



const PaginatedItems = ({ itemsPerPage, items, renderItem }) => {
  const classes = useStyles();

  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems.map(renderItem)}
      </>
    );
  }

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
    setCurrentPage(event.selected);  // Update the current page
    
  };

  return (
    <>
      <ReactPaginate
        containerClassName="pagination justify-content-end align-items-center mr-1"
        pageLinkClassName={classes['centered-pagination-link']}
        breakLabel="..."
        breakLinkClassName={classes['centered-pagination-link']}
        nextLabel={<ActionButton style={{margin: 0}} color="primary" size="sm" round disabled={currentPage == pageCount - 1}> {"next"} </ActionButton>}
        previousLabel={<ActionButton style={{margin: '0 13px 0 0'}} color="primary" size="sm" round disabled={currentPage == 0}> {"previous"} </ActionButton>}
        onPageChange={handlePageClick}
        forcePage={currentPage}
        pageCount={pageCount}
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
      <GridContainer>
        <Items currentItems={currentItems} />
      </GridContainer>
      <ReactPaginate
        containerClassName="pagination justify-content-end align-items-center mr-1"
        pageLinkClassName={classes['centered-pagination-link']}
        breakLabel="..."
        breakLinkClassName={classes['centered-pagination-link']}
        nextLabel={<ActionButton style={{margin: 0}} color="primary" size="sm" round disabled={currentPage == pageCount - 1}> {"next"} </ActionButton>}
        previousLabel={<ActionButton style={{margin: '0 13px 0 0'}} color="primary" size="sm" round disabled={currentPage == 0}> {"previous"} </ActionButton>}
        onPageChange={handlePageClick}
        forcePage={currentPage}
        pageCount={pageCount}
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
}

export default PaginatedItems;
