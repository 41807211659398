import React from "react";
import withStyles from "@mui/styles/withStyles";

import {Modal as BootstrapModal} from "react-bootstrap";

//Styles
import clsx from 'clsx';

import { IconButton } from "@mui/material";
import icon7 from "assets/img/icon7.png";

const styles = {
	'root' : {
		'& .modal-content' : {
			padding:'40px',
			borderRadius:'20px',
		},
		'& h1' : {
			fontSize:'23px',
			color:'#000'
		},
		'& h2' : {
			fontSize:'20px',
			color:'#125a40'
		},
	},
	modalCancelButton : {
		padding:'0',
		float:'right'
	},
};

class Modal extends React.Component {

	handleCloseClick(){
		if (this.props.onHide){
			this.props.onHide();
		}
	}

	render(){
		const {children, classes, className, noPattern,customSize, ...other} = this.props;

		return (
            <BootstrapModal
				centered
				size={customSize ? "":"lg"}
				dialogClassName={customSize ? "modal-90w":""}
				className={clsx(classes.root, className)}
				{...other}>
				<div>
					<IconButton
                        onClick={()=>this.handleCloseClick()}
                        className={classes.modalCancelButton}
                        size="large">
						<img
							src={icon7}
							alt="cancel icon"
							style={{ width: "40px", height: "40px" }}
						/>
					</IconButton>
				</div>
				{children}
			</BootstrapModal>
        );
	}
}

export default withStyles(styles)(Modal);