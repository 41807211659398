import React from "react";
import withStyles from "@mui/styles/withStyles";

// Forms
import TextField from "@mui/material/TextField";


const styles = ({borderRadius,borderColor,theme}) => ({
	'root' : {
		'& .MuiOutlinedInput-notchedOutline' : {
			borderColor : ({borderColor}) =>  (typeof borderColor!=="underfined") ? borderColor:'grey',
			borderRadius : ({borderRadius}) =>  (typeof borderRadius!=="underfined") ? borderRadius:'20px',
		},
		'& .Mui-focused .MuiOutlinedInput-notchedOutline' : {
			borderColor : 'grey',
		},
	},
});

class TextArea extends React.Component {

	render = () => {
		const {children,borderRadius,borderColor, ...other} = this.props;

		return (
			<TextField
				multiline
				minRows="4"
				fullWidth
				variant="outlined"
				{...other}
			/>
		);
	};
}

export default withStyles(styles)(TextArea);