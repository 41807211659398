import React from 'react';
import { ResponsiveLine } from '@nivo/line'

import { BasicTooltip } from '@nivo/tooltip';


export default (datat,nDays,t) => (
  <ResponsiveLine
  data={[datat]}
  margin={{ top: 20, right: 50, bottom: 50, left: 50 }}
        xScale={{
          type: "time",
          format: "%d-%m-%Y"
        }}
        xFormat="time:%d-%m-%Y"
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false
        }}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          format: e => Math.floor(e) === e && e,
          legend: 'Employees that completed a module',
          legendOffset: -40,
          legendPosition: "middle"
        }}
        axisBottom={{
          format: "%b %d",
          tickValues: "every "+nDays+" days",
          tickRotation: 45,
          legend: "",
          legendOffset: 50
        }}
  pointSize={10}
  pointColor={{ theme: 'background' }}
  pointBorderWidth={2}
  pointBorderColor={{ from: 'serieColor' }}
  pointLabelYOffset={-12}
  tooltip={({ point }) => {
    return (
      <div
      key={point.id}
      style={{
          padding: '3px 0',
          backgroundColor: "white",
          padding: '9px 12px',
          border: '1px solid #ccc',
      }}
  >
      <strong>{"Employees:"}</strong> {point.data.yFormatted}<br></br>
      <strong>{"Date:"}</strong> {point.data.xFormatted}
  </div>
    )
}}
  useMesh={true}
/>
);