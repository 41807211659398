/*!
* Description: this file contains a component
*  that renders a disabled lookalike button that contains numbers or text
*/

import React, { useState } from 'react';
import withStyles from "@mui/styles/withStyles";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

import analyticsStyle from "assets/jss/views/analyticsStyle.jsx"; //style for all analytics
import { ourBlueLight, ourBlue, ourOrange } from "assets/jss/incept-sustainability-variables.jsx";

import CardAnalytics from "components/CardAnalytics/Card.jsx";
import CardAnalyticsHeader from "components/CardAnalytics/CardHeader.jsx";
import CardAnalyticsBody from "components/CardAnalytics/CardBody.jsx";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import FakeButton from "components/CustomButtons/FakeButton";
import DropDown from 'components/CustomInput/DropDown.jsx';
import MenuItem from '@mui/material/MenuItem';

import { useMediaQuery } from 'react-responsive'; //for mobile responsiveness

const useStyles = (theme) => ({
	smallButton: ({color, textColor}) => ({
		backgroundColor: color,
		color: textColor,
		border: "0px",
		borderRadius: "20px",
		fontSize: "14px",
		padding: "5px 30px",
		marginBottom: "0.5rem",
	})
});

function withMyHook(Component) {
    return function WrappedComponent(props) {
      const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
      return <Component {...props} isMobile={mobile} />;
    }
}

function MyActions_Header(props) {
	const {classes, content} = props;

	return (
            <CardAnalytics>
                <CardAnalyticsHeader title="General Overview">
                </CardAnalyticsHeader>
                <CardAnalyticsBody>
                <GridContainer alignItems="center">
                        <GridItem xs={12} sm={12} md={12} lg={3} otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"0"}:{}}>
                            <TableContainer className={classes.standardTableNoPadding}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow
                                        key={"Activeactions"}
                                        >
                                            <TableCell component="th" scope="row">
                                                {"Active actions:"}
                                            </TableCell>
                                            <TableCell align={props.isMobile ? "right":"left"}>
                                                <FakeButton 
                                                color={ourBlueLight}
                                                textColor={ourBlue}
                                                content={props.headerInfo.active} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={3} otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"0"}:{}}>
                            <TableContainer className={classes.standardTableNoPadding}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow
                                        key={"Completedactions"}
                                        >
                                            <TableCell component="th" scope="row">
                                                {"Completed actions:"}
                                            </TableCell>
                                            <TableCell align={props.isMobile ? "right":"left"}>
                                                <FakeButton 
                                                color={ourBlueLight}
                                                textColor={ourBlue}
                                                content={props.headerInfo.completed} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={3} otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"0"}:{}}>
                            <TableContainer className={classes.standardTableNoPadding}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow
                                        key={"Unassignedactions"}
                                        >
                                            <TableCell component="th" scope="row">
                                                {"Unassigned actions:"}
                                            </TableCell>
                                            <TableCell align={props.isMobile ? "right":"left"}>
                                                <FakeButton 
                                                color={ourBlueLight}
                                                textColor={ourBlue}
                                                content={props.headerInfo.unassigned} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </GridItem>
                        {/*<GridItem xs={12} sm={12} md={12} lg={3} otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"0"}:{}}>
                            <TableContainer className={classes.standardTableNoPadding}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow
                                        key={"Follow-up"}
                                        >
                                            <TableCell component="th" scope="row">
                                                {"Follow-up:"}
                                            </TableCell>
                                            <TableCell align={props.isMobile ? "right":"left"}>
                                                <FakeButton 
                                                color={ourBlueLight}
                                                textColor={ourBlue}
                                                content={"1"} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </GridItem>*/}
                    </GridContainer>
                </CardAnalyticsBody>
            </CardAnalytics>
	);
}
export default
withMyHook(withStyles(
    (theme) => ({
      ...analyticsStyle(theme),
      ...useStyles(theme),
    }),
    { withTheme: true },
  )(MyActions_Header));
