/*!
 * Description: this file contains the component for
 *  the type of exercise SimpleChoiceImage, ex. guide 4, step 1,
 *  					"Exercise: The Shopping Bag Choice"
 * Components: SimpleChoiceImage
 */
import React from "react";

// Styles
import withStyles from "@mui/styles/withStyles";
import {
	ourBlue,
	standardRadious
} from "assets/jss/incept-sustainability-variables.jsx";

// Style components
import Container from "@mui/material/Container";
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import checked from 'assets/img/checkedWhiteBg.png';
// Forms
import ImageRadio from "components/CustomInput/ImageRadio.jsx";
import TextField from "@mui/material/TextField";

//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";

//Translations
import { withTranslation } from 'react-i18next';

const styles = {
	root: {
		paddingTop: '20px',
	},
	buttonContainer: {
		textAlign: "right",
		marginTop: "20px",
		paddingRight: "0",
		marginBottom: "60px"
	},
	card: {
		backgroundColor: 'transparent',
		fontWeight: '500',
		borderRadius: '1rem',
	},
	qaContainer: {
		color: ourBlue,
	},
	questionContainer: {
		fontSize: "22px",
		fontWeight: "bold",
	},
	textarea: {
		color: "white",
	},
	cardAnswer: {
		borderRadius: standardRadious,
		"@media only screen and (max-width: 960px)": {
			borderRadius: "1rem",
		}
	},
	statusContainer: {
		textAlign: "right",
		paddingRight: "0"
	},
	footertext: {
		fontStyle: "italic",
		fontSize: "11px"
	}
};

/*
 * Component that renders exercise of choosing image options
 */
class SimpleChoiceImage extends React.Component {

	constructor(props) {
		super(props);
		
		this.state = {
			selectedAnswer: { 'value': -1, 'statement_id': -1 },
			explanation: { 'value': "", 'statement_id': -1 },
			submittedanswer: (typeof this.props.datasav2 !== "undefined") ? true: false
		};

		if (typeof this.props.datasav2 !== "undefined") {
			this.state.selectedAnswer = this.props.datasav2["content"][0];
			this.state.explanation = (this.props.datasav2["content"].length > 1)
				? this.props.datasav2["content"][1] : { 'value': "", 'statement_id': -1 };
		}
	}

	handleSubmit(e) {
		e.preventDefault();
		//Commented to allow empty answers
		//if (this.state.selectedAnswer.statement_id!==-1 || this.state.explanation.value!=="") {
			this.setState({ submittedanswer: true });
			this.props.save2(
				"SimpleChoiceIMG",
				[this.state.selectedAnswer, this.state.explanation],
				this.props.inkey,
				this.props.exercise_id
			);
		//}
	}

	handleChange(e, value_stat) {
		const answerIndex = { 'value': value_stat.value, 'statement_id': value_stat.statement_id }
		this.setState({
			selectedAnswer: answerIndex,
		});
		/*this.props.save2(
			"SimpleChoiceIMG",
			[answerIndex, this.state.explanation],
			this.props.inkey,
			this.props.exercise_id
		);*/
	}

	handleChangeTextArea(e) {
		const answer = e.target.value;
		this.setState({ explanation: { 'value': answer, 'statement_id': -1 } });
	}

	/*saveexplanation = () => {
		this.props.save2(
			"SimpleChoiceIMG",
			[this.state.selectedAnswer, this.state.explanation],
			this.props.inkey,
			this.props.exercise_id
		)
	};*/

	_renderRowOptions(options) {
		const gridItems = options.map((opt, index) => {
			return this._renderOption(opt, index);
		});
		return (
			<GridContainer spacing={3} alignItems="stretch">
				{gridItems}
			</GridContainer>
		);
	}

	_renderOption(option, index) {
		const { imageURL, label, statement_id } = option;
		const valueInt = index;
		const value = index.toString();
		const answer = this.state.selectedAnswer.value;
		const radioGroupName = "radio-grouping";
		const value_stat = { value: value, statement_id: statement_id };

		return (
			<GridItem xs={12} sm={6} md={6} lg={3} key={value}>
				<ImageRadio
					imageURL={imageURL}
					label={label}
					value={value}
					onChange={(e) => this.handleChange(e, value_stat)}
					checked={answer == valueInt}
					name={radioGroupName}
				/>
			</GridItem>
		);
	}

	_renderTextArea(statement) {
		const { classes, t } = this.props;

		return (
			<React.Fragment>
				<div style={{ margin: "20px 0 10px 0" }}>{statement}</div>
				<Card className={classes.cardAnswer}>
					<CardBody className={classes.answerBox} isexercise={true}>
						<Container>
							<TextField
								label={t('Write your answer here')}
								className={classes.textField}
								margin="normal"
								placeholder={t('Write your answer here')}
								fullWidth
								onChange={(e) => this.handleChangeTextArea(e)}
								value={this.state.explanation.value !== "" ? this.state.explanation.value : ""}
							/>
							<p className={classes.footertext}>
							{t('no personal data',
								{
									defaultValue: 'Please keep your answer anonymous, do not write any personal data in your answer.'})}
							</p>
						</Container>
					</CardBody>
				</Card>
				{/*<Container className={classes.buttonContainer}>
        	<Button type="submit" variant="contained" className={classes.buttonAnswer} style={{margin:'20px 0'}} onClick={ (e) => this.handleChange2(e) }>Submit</Button>
        </Container>*/}
			</React.Fragment>
		);
	}

	render() {
		const { classes, exercise, t } = this.props;
		const { statement, textarea } = exercise;
		const { options } = statement;

		return (
			<div className={classes.root}>
				<div>
					{this._renderRowOptions(options)}
				</div>
				<div>
					{this._renderTextArea(textarea.statement)}
				</div>
				<div className={classes.statusContainer}>
				{this.state.submittedanswer && <p>{t("Great! Your answer has been saved.")}</p>}
				</div>
				<Container className={classes.buttonContainer}>
					<ActionButton
						size="auto"
						type="submit"
						nomargin={true}
						centered={true}
						onClick={(e) => this.handleSubmit(e)}
					>
						{t('Submit')}
					</ActionButton>
				</Container>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(SimpleChoiceImage));
