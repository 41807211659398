import React, { useState } from "react";

const useModalActionsDivData = () => {
    let date = new Date();
    const postd = new Date().setDate(date.getDate() + 30);


    const [modalSelAction, setmodalSelAction] = useState("");
    const [showideabox, setshowideabox] = useState(false);
	const [nonCheckedideas, setnonCheckedideas] = useState(true);
	const [openArchModal, setopenArchModal] = useState(false);
    const [addMyActionModalValue, setaddMyActionModalValue] = useState(false);
	const [listActions, setlistActions] = useState([]);
	const [listActionsdiv, setlistActionsdiv] = useState([]);
	const [modalideasdiv, setmodalideasdiv] = useState([]);
    const [addActionModalValue, setaddActionModalValue] = useState(false);
    const [modalactioninfo, setmodalactioninfo] = useState({
		title: "",
		description: "",
		market: 0,
		responsible: 0,
		compltedby: new Date(postd)
	});
    
	let modalactionTitle = React.createRef();
	let modalactionDescription = React.createRef();

    return {
        modalSelAction, setmodalSelAction,
        showideabox, setshowideabox,
        nonCheckedideas, setnonCheckedideas,
        openArchModal, setopenArchModal,
        addMyActionModalValue, setaddMyActionModalValue,
        listActions, setlistActions,
        listActionsdiv, setlistActionsdiv,
        modalideasdiv, setmodalideasdiv,
        addActionModalValue, setaddActionModalValue,
        modalactioninfo, setmodalactioninfo,
        modalactionTitle, modalactionDescription
    };
}

export default useModalActionsDivData;