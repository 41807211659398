import React, { createContext, ReactNode, useContext } from 'react';
import useDepartmentsData from "../hooks/useDepartmentsData";

const IdeasDepartmentsContext = createContext<ReturnType<typeof useDepartmentsData> | null>(null);

const IdeasDepartmentsProvider = ({ children }: { children: ReactNode }) => {
    const ideasDepartmentFilter = useDepartmentsData();

    return (
        <IdeasDepartmentsContext.Provider value={ideasDepartmentFilter}>
            {children}
        </IdeasDepartmentsContext.Provider>
    );
}

const useIdeasDepartmentsContext = () => {
    const context = useContext(IdeasDepartmentsContext);
    if (context === null) {
        throw new Error('useIdeasDepartmentsContext must be used within an IdeasDepartmentsProvider');
    }
    return context;
}

export { IdeasDepartmentsProvider, useIdeasDepartmentsContext, IdeasDepartmentsContext };