
export function flipCards_MobileFriendly(update=true) {
    let elements = document.getElementsByClassName("card-wrapper");
        for (const element of elements) {
            element.dataset.turned = "0";
			if (update) {
				let cardHeight = parseInt(element.getElementsByClassName("back")[0].scrollHeight)+20;
				element.style.height = cardHeight+"px";
				element.getElementsByClassName("card")[0].style.height = cardHeight+"px";
			}
            element.onclick = function () {
                const isturn = element.dataset.turned;
                const direction = element.className.substring(element.className.indexOf('flip-') + 5);
                let rotate = "";
                switch(direction) {
                    case "left":
                        rotate = (isturn==="1") ? "":"rotateY(-180deg)";                        
                        break;
                    case "right":
                        rotate = (isturn==="1") ? "":"rotateY(180deg)";                        
                      break;
                    case "up":
                        rotate = (isturn==="1") ? "":"rotateX(180deg)";                        
                      break;
                    case "down":
                        rotate = (isturn==="1") ? "":"rotateX(-180deg)";                        
                      break;
                    case "diagonal-right":
                        rotate = (isturn==="1") ? "":"rotate3d(1, 1, 0, 180deg)";                        
                      break;
                    case "diagonal-left":
                        rotate = (isturn==="1") ? "":"rotate3d(1, 1, 0, -180deg)";                        
                      break;
                    case "inverted-diagonal-right":
                        rotate = (isturn==="1") ? "":"rotate3d(-1, 1, 0, 180deg)";                        
                      break;
                    case "inverted-diagonal-left":
                        rotate = (isturn==="1") ? "":"rotate3d(1, -1, 0, 180deg)";                        
                      break;
                    default:
                      // code block
                  } 
                 element.getElementsByClassName("card")[0].style.transform = rotate;
                 element.getElementsByClassName("back")[0].style.transform = rotate;
                element.dataset.turned = (isturn==="1") ? "0":"1";
            }
    }
}