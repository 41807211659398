import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import useModulesData from "../hooks/useModulesData";


const ModulesContext = createContext<ReturnType<typeof useModulesData> | null>(null);

const ModulesProvider = ({ children }: { children: ReactNode }) => {
    const modulesData = useModulesData();

    return (
        <ModulesContext.Provider value={modulesData}>
            {children}
        </ModulesContext.Provider>
    );
}

const useModulesContext = () => {
    const context = useContext(ModulesContext);
    if (context === null) {
        throw new Error('useModulesContext must be used within an ModulesProvider');
    }
    return context;
}

export {useModulesContext, ModulesProvider, ModulesContext};