import {Dispatch, SetStateAction} from "react";
import {AnalyticsIdeasFilter} from "../interfaces/AnalyticsIdeasFilter";
import {IdeasAndCommentsFilter} from "../BaseFilters/IdeasAndCommentsFilter";
import useIdeasFilter from "../../../hooks/useIdeasFilter";

class LabelFilter<T extends string, K extends ReturnType<typeof useIdeasFilter>> extends IdeasAndCommentsFilter<T, K> implements AnalyticsIdeasFilter<T, K> {
    filter(label: T, setSelfId: Dispatch<SetStateAction<T>>, ideasFilterData: K): void {
        setSelfId(label);
        ideasFilterData.setSearchVal("");

        if (label === "-1") {
            ideasFilterData.setIdeasFiltered(ideasFilterData.ideasInfo.ideas);
            ideasFilterData.setListItems(ideasFilterData.ideasInfo.ideas);
            return; 
        }
        
        const ideasFilteredL = ideasFilterData.ideasInfo.ideas.filter(ideas => {
            if (label === "0") {
                return ideas.label.length === 0;
            } else {
                return ideas.label.find(labelValue => labelValue.id === label) !== undefined;
            }
        });

        ideasFilterData.setIdeasFiltered(ideasFilteredL);

        ideasFilterData.setListItems(ideasFilteredL);
    }
    

    public static createFilter = <T extends string, K extends ReturnType<typeof useIdeasFilter>> (): IdeasAndCommentsFilter<T, K> => new LabelFilter();

    public static getClassName = (): string => "LabelFilter";

    clear = (ideasFilterData: ReturnType<typeof useIdeasFilter>): void =>  {
        this.filter("-1" as T, ideasFilterData.setCurrentLabel as Dispatch<SetStateAction<T>>, ideasFilterData as K );
    }
}

export { LabelFilter }
