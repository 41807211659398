import {AnalyticsIdeasFilter} from "../interfaces/AnalyticsIdeasFilter";
import {Dispatch, SetStateAction} from "react";
import useIdeasFilter from "../../../hooks/useIdeasFilter";


abstract class IdeasAndCommentsFilter<T, K> implements AnalyticsIdeasFilter<T, K> {
    public abstract filter(value: T, setSelfId: Dispatch<SetStateAction<T>>, setterData: K ): void
    public abstract clear(ideasFilterData: K) : void

    // Factory Method --- This acts as a guideline, each subclass implements their own version of creation.
    // @ts-ignore
        public static createFilter(): IdeasAndCommentsFilter {
        throw new Error("This method should be overridden by subclass");
    }

    public static getClassName(): string {
        throw new Error("This method should be overridden by subclass");
    }
}


export { IdeasAndCommentsFilter }


