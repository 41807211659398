  
  const cardAnalyticsHeaderStyle = {
    cardHeader: {
      padding: "1.5rem 2rem 0 2rem",
      "& h5": {
        marginTop: "0",
        fontWeight: "200"
      }
    }
  };
  
  export default cardAnalyticsHeaderStyle;
  