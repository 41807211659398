import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import useIdeaData from '../hooks/useIdeaData';

const IdeaContext = createContext<ReturnType<typeof useIdeaData> | null>(null);

const IdeaProvider = ({ children }: { children: ReactNode }) => {
    const ideaData = useIdeaData();


    return (
        <IdeaContext.Provider value={ideaData}>
            {children}
        </IdeaContext.Provider>
    );
}

const useIdeaContext = () => {
    const context = useContext(IdeaContext);
    if (context === null) {
        throw new Error('useIdeaContext must be used within an IdeaProvider');
    }
    return context;
}

export { useIdeaContext, IdeaProvider, IdeaContext };
