import { useState } from 'react';

const useDepartmentsData = () => {
    
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(0);

    return {
        departments, setDepartments,
        selectedDepartment, setSelectedDepartment
    };
};

export default useDepartmentsData;
