import React, { createContext, ReactNode, useContext } from 'react';
import useModalActionsDivData from '../hooks/useModalActionsDivData';

const ModalActionsDivContext = createContext<ReturnType<typeof useModalActionsDivData> | null>(null);

const ModalActionsDivProvider = ({ children }: { children: ReactNode }) => {
    const modalActionsDivData = useModalActionsDivData();

    return (
        <ModalActionsDivContext.Provider value={modalActionsDivData}>
            {children}
        </ModalActionsDivContext.Provider>
    );
}

const useModalActionsDivContext = () => {
    const context = useContext(ModalActionsDivContext);
    if (context === null) {
        throw new Error('useModalActionsDivContext must be used within an ModalActionsDivProvider');
    }
    return context;
}

export { useModalActionsDivContext, ModalActionsDivProvider, ModalActionsDivContext };

