import React from 'react';
import { withStyles } from '@mui/styles';

const styles = {
	guideSubtitle: {
		textTransform:'uppercase',
		color: "white",
		fontSize:'26px',
	},
	guideCardTitle: {
		/*textTransform:'capitalize',*/
		color: "rgba(0, 0, 0, 1)",
		fontWeight: "200",
		fontSize:'22px'
	},
	guideCardWhite: {
		/*textTransform:'capitalize',*/
		color: "white",
		fontWeight: "700",
		fontSize:'22px'
	}
};

const GuideSubtitle = withStyles(styles)( (props) => {

	const { classes, children } = props;
	return (<h1 className={classes.guideSubtitle}>{children}</h1>);
});

const GuideCardTitle = withStyles(styles)( (props) => {

	const { classes, children,color , ...rest} = props;
	return (<h1 className={(color==="white") ? classes.guideCardWhite:classes.guideCardTitle} {...rest}>{children}</h1>);
});


export {
	GuideSubtitle,
	GuideCardTitle
}