import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @mui/material components
import withStyles from "@mui/styles/withStyles";


import GuidePage from "components/GuidePage/GuidePage.jsx";
import GuideTopContainer from "components/GuidePage/GuideTopContainer.js";
import GuideExercise from "components/GuidePage/GuideExercise.js";
import GuideExample from "components/GuidePage/GuideExample.js";
import GuideStandardCardStyled from "components/GuidePage/GuideStandardCard.js";

//import Pagination from './Pagination';
import Pagination from "components/Pagination/Pagination.js";


import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";


class GuidesNav extends React.Component {
	constructor(props) {
		super(props);
		this.routeName = "guidesNav";
		this.initPage = 1;
	}

	render() {

		return (
			<Switch>
				<Route path="/guidesNav/:pageNum" render={props => {
					let currentPage = parseInt(props.match.params.pageNum);
					return (
						<div>
							<GuideTopContainer/>
							<GuidePage currentPage={currentPage} >
								<h1>I'm Guide Page number {currentPage}!</h1>
							</GuidePage>
							<GuideExercise />
							<GuideExample />
							<GuideStandardCardStyled />
							<Pagination numPages={5} currentPage={currentPage} />
						</div>
					);
				}} />
				<Redirect to={"/"+this.routeName+"/"+this.initPage} />
			</Switch>
		);
	}
}

export default withStyles(dashboardStyle)(GuidesNav);
