import {
  successColor,
  whiteColor,
  grayColor,
  hexToRgb,
} from "assets/jss/material-dashboard-react.jsx";
import { ourGreen, ourLightGreen,ourOrange,ourBlue  } from 'assets/jss/incept-sustainability-variables.jsx';

const dashboardStyle = (theme) => ({
  successText: {
    color: successColor[0],
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px",
  },
  stats: {
    color: grayColor[0],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
  },
  cardCategory: {
    color: grayColor[0],
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0",
  },
  cardCategoryWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Raleway', 'Helvetica', 'Arial', sans-serif",

    textDecoration: "none",
    marginBottom: "10px",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Raleway', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  containerother: {
    paddingRight: "25px",
    paddingLeft: "55px",
    marginRight: "auto",
    marginLeft: "auto",
  },
  compmod: {
    "@media only screen and (max-width: 960px)": {
			maxWidth: "200px"
		}
  },
  compmodules: {
    margin: "0 auto", 
    marginBottom: "0px",
    display: "block",
    maxWidth: "100%"
  },
  iFrameContainer: {
    textAlign: "center",
    marginTop: "30px",
  },
  gridWrappper: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: "0",
    }
  },
  welcomeGrid: {
    borderRadius: "0",
    backgroundColor: "transparent",
    boxShadow: "none",
    padding: "10px",
    width: "70%",
    color: ourBlue,
    marginBottom: "0",
    [theme.breakpoints.down('md')]: {
      width: "100%",
      marginTop: "60px",
      marginBottom: "0"
    }
  },
  DFDSSection: {
    marginTop: "0",
    padding: "0 10px 10px"
  },
  DFDSTitle: {
    color: "rgb(123, 220, 163)",
    marginBottom: "0.8rem",
  },
  signature: {
    width: "100px",
    height: "50px",
    float: "right",
    marginRight: "1rem"
  },
  moduleCard: {
    //height: "300px",
    marginTop: "0",
    height: "100%",
    display: "table"
  },
  moduleCardBody: {
    paddingTop: "1.5rem",
    paddingRight: "2rem",
    paddingBottom: "2rem",
    paddingLeft: "2rem",
    verticalAlign: "middle",
    display: "table-cell"
  },
  moduleCardBody2: {
    paddingTop: "1.5rem",
    paddingRight: "2rem",
    paddingBottom: "2rem",
    paddingLeft: "2rem",
    display:"table",
    width:"100%",
    height:"100%"
  },
  bikeSection: {
    //backgroundColor: ourLightGreen,
    fontWeight: "500",
    marginTop: "5px",
  },
  bikeSectionText: {
    padding: "2rem",
    /*fontSize: "1rem",*/
    paddingTop: "2rem",
    paddingRight: "0",
    paddingLeft: "2rem",
    paddingBottom: "2rem",
    lineHeight: "1.7",
    marginRight: "-2rem",
    [theme.breakpoints.down('md')]: {
      padding: "0",
      margin: "20px",
    }
  },
  newsSection: {
    /*fontSize: "1rem",*/
    paddingTop: "1rem",
    paddingRight: "0",
    lineHeight: "1.7",
    marginRight: "-2rem",
    [theme.breakpoints.down('md')]: {
      padding: "0",
      margin: "20px",
    }
  },
  illustrationWrapper: {
    display: "flex",
    [theme.breakpoints.down('md')]: {
      flexDirection: "column-reverse",
    },

  },
  secondWrapper: {
    display: "flex",
    paddingBottom: "30px",
    [theme.breakpoints.down('md')]: {
      flexDirection: "column"
    }
  },
  bikeText: { 
    width: "50%", 
    // border: "1px solid red",
    margin: "auto",
    [theme.breakpoints.down('md')]: {
      width: "100%"
    }
  },
  bikeImage: {
    width: "50%",
    // border: "1px solid blue",
    [theme.breakpoints.down('md')]: {
      width: "50%",
      margin: "auto"
    }
  },
  imgtip: {
    display:"flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    padding: "15px"
  },
  commentsContainer: {
    display: "flex", 
    marginBottom: "1rem",
    marginTop: "30px",  
    "& p": {
      cursor: "pointer",
    } 
  },
  ellipsistext: {
    "& p": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "@supports (-webkit-line-clamp: 2)": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "initial",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical"
      }
    },
  },
  commentsIcon: {
    width: "40px",
    height: "40px",
    marginRight: "1rem"
  },
  buttonSection: {
    paddingTop: "2.2rem",
    float: "right",
  },
  button2: {

    backgroundColor: ourOrange,
    color: "white",
    border: "1px",
    borderRadius: "20px",
    cursor: "pointer",
    fontSize: "13px",
    padding: "2px 25px",
    marginLeft: "1rem",
    marginBottom: "1rem",
    [theme.breakpoints.down('md')]: {
      marginBottom: "1rem",
    },
  },
  button3: {
    borderRadius: "20px",
    backgroundColor: ourOrange,
    color: "white",
    border: "1px",
    cursor: "pointer",
    fontSize: "13px",
    padding: "2px 25px",
    width: "100%"
  },
  backgroundPatternImage: {
    width: "25%",
    position: "absolute",
    right: "0",
    top: "0",
    [theme.breakpoints.down('md')]: {
      display: "none",
    },
  },
  iFrame: {
    width: "720px",
    height: "405px",
    [theme.breakpoints.down('md')]: {
      width: "100%",
    },
  },
  smallButton: {
    backgroundColor: ourGreen,
    color: "white",
    border: "0px",
    borderRadius: "20px",
    //cursor: "pointer",
    fontSize: "13px",
    padding: "1px 20px",
    //marginRight: "1rem",
    marginBottom: "0.5rem",
    /* [theme.breakpoints.down("sm")]: {
      marginBottom: "1rem",
    }, */
  },
  modalBox: {
    "& .modal-content": {
      borderRadius: "2rem",
    }
  },
  modalPattern: {
    transform: "rotate(0.5turn)", 
    width: "40%", 
    position: "absolute", 
    left: "1px", 
    bottom: "1px",
    zIndex: "1"
  },
  modalVideo: {
    height: "80%", 
    width: "100%", 
    padding: "50px",
    paddingTop: "10px",
    position: "relative",
    zIndex: "2"
  },
  modalCancelButton: { 
    right: "0",
    position: "relative", 
    zIndex: "3", 
    padding: "0px",
    float: "right"
  },
  textarea1: {
    backgroundColor: "rgba(255,255,255,0.5)", 
    position: "relative", 
    zIndex: "2", 
    marginBottom: "20px", 
    borderRadius: "16px", 
    borderColor: "grey", 
    resize: "none"
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    //tableLayout: "fixed", 
    borderSpacing: "0",
    borderCollapse: "separate"
  },
  tableCell: {
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle",
    fontSize: "0.8125rem",
    fontWeight: "bold",
    color: "#fff",
    textAlign: "right",
    "&:first-child":{
      textAlign: "left",
      borderTopLeftRadius: "2rem",
      borderBottomLeftRadius: "2rem",
    },
    "&:last-child":{
      borderTopRightRadius: "2rem",
      borderBottomRightRadius: "2rem",
      width: "30%",
    }
  },
  tableRow: {
    width: "100%",
    padding: "0px 20px",
    "&:nth-child(odd)":{
      backgroundColor: "#A5EAC7",
      borderColor: "#A5EAC7",
    }
  },
  processBar: {
      borderRadius: "2rem", 
      backgroundColor: "#FFE09D",
    "& .progress-bar": {
      backgroundColor: "#ffe682",
      borderRadius: "2rem"
    }
  },
  circularProgress: {
      "& .MuiCircularProgress-circle": {
      transform: "rotate(0.45turn)", 
      color: "#ffe682",
      fill: "#FFE09D"
    }
  },
  responsivePie: {
    "&:child" : {
      position: "relative", 
      zIndex: "-1"
    }
  },
  containerbody: {
    width: "100%",
  },
  showElement: {
		display:"block"
	},
	hideElement: {
		display:"none !important"
	},
  whatsnewtext: {
    "@media only screen and (max-width: 600px)": {
			marginTop: "-5px"
		}
  },
  packcompletion: {
    marginTop: "3rem",
    "@media only screen and (max-width: 600px)": {
			marginTop: "0"
		}
  },
  footertext: {
		fontStyle: "italic",
		fontSize: "11px",
    textAlign: "right"
	},
  inputreminder: {
    borderRadius: "2rem",
    position: "relative",
    zIndex: "2"
  },
  tabsMenu: {
    minHeight: "28px",
    display: "grid",
    gridAutoFlow: "column",
    '& .MuiTab-root': {
      minHeight: "28px",
      minWidth: "100px"
    },
    '& .MuiTabs-indicator': {
      /*width*/
    },
    "& .MuiTabs-scrollButtons.Mui-disabled": {
      opacity: "0.3"
    }
  },
  tableModule: {
    '& .MuiBox-root': {
      padding: "24px 0 0 0px"
    },
    '& .MuiTableCell-root': {
      borderBottom: "0",
      padding: "16px 0 0 16px"
    },
  },
  tableModule2: {
    '& .MuiBox-root': {
      padding: "24px 0 0 0px"
    },
    '& .MuiTableCell-root': {
      borderBottom: "0",
      padding: "5px 0 0 5px"
    },
  },
  rightlabel: {
    textAlign: "right",
		paddingRight: "10px",
		margin: "auto"
  },
  personalMessage: {
    backgroundColor: ourLightGreen,
    marginBottom: "60px",
    marginTop:"0"
  }
  
});

export default dashboardStyle;
