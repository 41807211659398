import {ResetFilter} from "../BaseFilters/ResetFilter";
import useIdeasFilter from "../../../hooks/useIdeasFilter";

// Because the MultiSelect might not offer a type definition, we create our own, to scope down the narrowed ref type.
interface MultiselectRef {
    resetSelectedValues: () => void;
  }


class ClearFilter extends ResetFilter {

    clear = (type:String | number, ideasFilter: ReturnType<typeof useIdeasFilter>): void => {
        if (type === "1") {
            ideasFilter.setShowActiveEmployees(true);

        } else {
            if (ideasFilter.filterFlags.country === 0 && ideasFilter.filterFlags.department === 0) {
                return;
            }
            if (ideasFilter.multiselectRef.current) {
                (ideasFilter.multiselectRef.current as MultiselectRef).resetSelectedValues();
                ideasFilter.setIdeasInfo({
                    ...ideasFilter.ideasInfo,
                    loadingfirst: true,
                    listCleared: true,
			    });
                ideasFilter.setCurrentCountry([]);
                ideasFilter.setCurrentDepartment(0);
                
            }

            console.log("ClearFilter: ended!");
        }
    }

    public static createFilter = (): ResetFilter => new ClearFilter();
    public static getClassName = (): string => "ClearFilter";

}

export { ClearFilter }
