/*!
* Description: this file contains a component
*  that renders dropable columns
*/
import React, { useState } from 'react';
import withStyles from "@mui/styles/withStyles";
//style for all analytics
import { ourMediumGreen,ourGreen,ourOrange,standardRadious } from "assets/jss/incept-sustainability-variables.jsx";
import { Droppable } from 'react-beautiful-dnd';
import { styled } from '@stitches/react';

import Item from "components/DragAndDrop/Item.jsx";

const useStyles = (theme) => ({

});

const StyledColumn = styled('div', {
	padding: '5px 0',
	display: 'flex',
	flexDirection: 'column',
	marginTop: 8,
	backgroundColor: '#ADD095',
	borderRadius: 16,
	padding: 16,
	marginBottom: 16,
  
	h2: {
	  margin: 0,
	  padding: '0 16px'
	}
})

const StyledList = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	flexGrow: 1,
	marginTop: 8,
	minHeight: "200px",
	overflow: "auto"
})

function Column(props) {
    const {classes} = props;
	let autoWidth = 'auto';
	let border = "";
	let backgroundColor = ourMediumGreen;
	let height = "300px";

	if (props.width === 'full') {
		autoWidth = '100%';
		border = "2px solid #125A40";
		backgroundColor = "transparent";
		height = "625px";
	}
    
    return (
        <Droppable droppableId={props.id}>
			{/* provided is an object, provided has property called droppableProps, this are props that need 
			to be applied to the component that you want to designate as droppable. In this case: COLUMN */}
			{/* snapshot is an object that contains a number of properties that we can use to style
			the draggable component during drag*/}
			{(provided, snapshot) => (
				<StyledColumn css={{ 'width': autoWidth,"border": border, backgroundColor: backgroundColor, height: height}}>
					{props.title!="" && <h6>{props.title}</h6>}
					<StyledList {...provided.droppableProps} css={{}} 
					ref={provided.innerRef}>					
					{props.list.map((item, index) => (
						<Item item={item} index={index} key={item.id}/>
					))}
					{provided.placeholder}
					</StyledList>
				</StyledColumn>

			)}
		</Droppable>   
    );
}

export default
withStyles(
    (theme) => ({
      ...useStyles(theme),
    }),
    { withTheme: true },
  )(Column);