import React, {createContext, useContext, useState, useEffect} from 'react';


const TokenContext = createContext(null);

const TokenProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('token'));
    //const token = localStorage.getItem('token');
    //const authString = `Bearer ${token}`;

    const updateToken = (newToken) => {
        localStorage.setItem('token', newToken);
        setToken(newToken);
    };

    return (
        <TokenContext.Provider value={{ authString: `Bearer ${token}`, updateToken }}>
            {children}
        </TokenContext.Provider>
    );
}

const UseSharedTokenInfo = () => {
    const context = useContext(TokenContext);
    if (context === null) {
        throw new Error('UseSharedTokenInfo must be used within an TokenProvider');
    }
    return context;
}

export { TokenProvider, TokenContext, UseSharedTokenInfo };
