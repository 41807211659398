

const loginStyle = (theme) => ({
  label: {
    color: "black",
    fontWeight: "700",
  },
  gridItem: {
    // border: "1px solid blue",
    width: "50%",
    margin: "auto",
    [theme.breakpoints.down('md')]: {
      paddingTop: "1rem",
      width: "90%",
      margin: "auto",
      marginTop: "6rem",
      marginBottom: "2rem"
    },
  },
  input: {
    borderRadius: "2rem",
    height: "50px",
    border: "1px solid gray",
    marginBottom: "1rem",
  },
  card: {
    backgroundColor: "inherit",
    boxShadow: "none",
    width: "390px",
    margin: "auto",
    [theme.breakpoints.down('md')]: {
      margin: "auto",
      width: "100%",
    },
	boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
	width: "390px",
	backgroundColor: "white",
	padding: "20px"
  },
  button: {
    width: "100px",
    height: "50px",
    backgroundColor: "rgb(255, 111, 105)",
    color: "white",
    border: "1px",
    borderRadius: "20px",
    cursor: "pointer",
    fontSize: "15px",
    padding: "2px 8px",
    display: "block",
    position: "inherit",
    margin: "auto",
    "&:hover": {
      backgroundColor: "#FF9894",
    },
  },
  topBackgroundPattern: {
    // border: "1px solid red",
    //width: "40%",
    position: "fixed",
    top: "0",
    right: "0",
    [theme.breakpoints.down('md')]: {
      display: "none",
    },
  },
  bottomBackgroundPattern: {
    // border: "1px solid red",
    width: "34%",
    position: "absolute",
    bottom: "0",
    left: "0",
    [theme.breakpoints.down('md')]: {
      display: "none",
    },
  },
  inputShort: {
		width: "300px",
    marginTop: "1rem"
	},
  errorfield: {
    color: "red",
  },
  successfield: {
    color:"green"
  },
});

export default loginStyle;
