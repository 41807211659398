/*!
 * Description: this file contains the component for
 *  the type of exercise QA, ex. guide 1, step 2,
 *  					" Exercise: The Danish Overshoot Day"
 * Components: QA
 */
import React from "react";
import ReactHtmlParser from "react-html-parser";

// Styles
import withStyles from "@mui/styles/withStyles";
import {  standardRadious } from "assets/jss/incept-sustainability-variables.jsx";

// Style components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import checked from 'assets/img/checked.png';


// Forms
import TextField from "@mui/material/TextField";

// Effects
import Collapse from "@mui/material/Collapse";

//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";

//Translations
import { withTranslation } from 'react-i18next';

const styles = {
	root: {},
	buttonContainer: {
		textAlign: "right",
		marginTop: "20px"
	},
	statusContainer: {
		textAlign: "right",
		paddingRight: "24px"
	},
	buttonContainerAnswers: {
		textAlign: "right",
	},
	buttonAnswer: {
		color: "#fff",
		borderRadius: "3rem",
		textTransform: "none",
		backgroundColor: "#ff6f69",
		width: "160px",
		height: "25px",
		overflow: "hidden",
		"&:hover, &:active, &:focus,": {
			filter: "brightness(100%)",
			backgroundColor: "#ff6f69",
		},
		"@media only screen and (max-width: 960px)": {
			marginBottom: "20px",
		}
	},
	cardAnswer: {
		borderRadius: standardRadious,
		"@media only screen and (max-width: 960px)": {
			borderRadius: "1rem",
		}
	},
	textf2: {
		marginTop: "10px",
	},
	footertext: {
		fontStyle: "italic",
		fontSize: "11px"
	}
};

/*
 * component for exercise that has an input field
 */
class QA extends React.Component {
	state = {
		userAnswer: "",
		showAnswer: false,
		stepm: 0,
		taskm: 0,
		submittedanswer: (typeof this.props.datasav2 !== "undefined") ? true: false,
	};

	componentDidMount() {
		//retrieve exercise here
		if (typeof this.props.datasav2 !== "undefined") {
			this.setState({ userAnswer: this.props.datasav2["content"] });
		}
	}

	/*componentWillReceiveProps(nextProps) {
	  this.setState({ userAnswer: nextProps.datasav2["content"] }); 
	}*/

	showAnswer(e) {
		e.preventDefault();
		this.setState({ showAnswer: !this.state.showAnswer });
	}

	handleSubmit(e) {
		e.preventDefault();
		//Commented to allow empty answers
		//if (this.state.userAnswer!=="") {
			this.setState({ submittedanswer: true });
			this.props.save2("QA", this.state.userAnswer, this.props.inkey, this.props.exercise_id);
		//}
	}

	handleChange(e) {
		this.setState({ userAnswer: e.target.value,submittedanswer: false });
		//this.props.save2("Completed", "", "");
	}


	render() {
		const { classes, exercise, t } = this.props;
		const { question, answer } = exercise;
		return (
			<div className={classes.root}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{ReactHtmlParser(ReactHtmlParser(question))}
					</Grid>
					<Grid item xs={12}>
						<Card className={classes.cardAnswer + " " + classes.textf2}>
							<CardBody isexercise={true}>
								<form
									noValidate
									autoComplete="off"
								>
									<Container>
										<TextField
											label=" "
											className={classes.textField}
											margin="normal"
											placeholder=" "
											fullWidth
											onChange={(e) => this.handleChange(e)}
											value={this.state.userAnswer}
										/>
										<p className={classes.footertext}>
										{t('no personal data',
											{
												defaultValue: 'Please keep your answer anonymous, do not write any personal data in your answer.'})}
										</p>
									</Container>
									<div className={classes.statusContainer}>
									{/*this.state.loading && <CircularProgress color="secondary" size={25} />*/}
									{this.state.submittedanswer && <p>{t("Thank you! Your feedback has been submitted")}</p>}
									</div>
									<Container className={classes.buttonContainer}>
										<ActionButton
											size="auto"
											type="submit"
											onClick={(e) => this.handleSubmit(e)}
										>
											{t('Submit')}
										</ActionButton>
									</Container>
									<Container className={classes.buttonContainerAnswers}>
										<ActionButton
											size="auto"
											nomargin={true}
											centered={true}
											onClick={(e) => this.showAnswer(e)}
											type="submit">
											{t('See correct answer')}
										</ActionButton>
									</Container>
								</form>
								<Container>
									<Collapse in={this.state.showAnswer}>
										{ReactHtmlParser(ReactHtmlParser(answer))}
									</Collapse>
								</Container>
							</CardBody>
						</Card>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(QA));
