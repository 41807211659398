/*!
* Description: this file contains a component
*  that renders a disabled lookalike button that contains numbers or text
*/

import React, { useState } from 'react';
import withStyles from "@mui/styles/withStyles";
import clientConfig from 'client-config.js';
import axios from "axios";
import analyticsStyle from "assets/jss/views/analyticsStyle.jsx"; 

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import DropDown from 'components/CustomInput/DropDown.jsx';
import MultiDimDropdown from 'components/CustomInput/MultiDimDropdown.jsx';
import MenuItem from '@mui/material/MenuItem';
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import { useMediaQuery } from 'react-responsive'; //for mobile responsiveness
import { withTranslation, Trans,useTranslation } from 'react-i18next';

const useStyles = (theme) => ({
	paddingMobile: ({color, textColor}) => ({
		"@media only screen and (max-width: 960px)": {
			margin: "20px 0"
		}
	})
});

function withMyHook(Component) {
    return function WrappedComponent(props) {
      const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
      return <Component {...props} isMobile={mobile} />;
    }
}

function AnalyticsCompany_FilterBar(props) {
	const {classes, content} = props;
	const [load, setLoad] = useState(false);
    const { t, i18n } = useTranslation();
    const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [isEmailMessageShown, setIsEmailMessageShown] = useState(false);
    
    const nodeAPI = clientConfig.nodesiteUrl;
    const token = localStorage.getItem("token");
    const authString = "Bearer " + token;

    function downloadFeedbackData() {
        
        if (props.currentModule === "0") {
            return;
        }
    
        const nodeDownloadFeedbackActivitiesEndpoint = `${nodeAPI}feedback/report/${props.currentModule}?action=send`;
        setIsDownloadButtonDisabled(true);
    
        axios.get(nodeDownloadFeedbackActivitiesEndpoint, {
            headers: { Authorization: authString },
            responseType: 'blob', // Specify the response type as blob
        })
        .then(response => {
            console.log(response);
            if (response.status === 200) {
                // Create a URL for the blob
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
                
                // Create a link element
                const link = document.createElement('a');
                link.href = url;
                
                // Set the download attribute with a file name
                link.setAttribute('download', `UserFeedback-Module-${props.currentModule}.xlsx`);
                
                // Append the link to the body
                document.body.appendChild(link);
                
                // Programmatically click the link to trigger the download
                link.click();
                
                // Clean up by removing the link element
                document.body.removeChild(link);
            } else if(response.status === 202) {
                setIsEmailSent(true)
                setIsEmailMessageShown(true)
            }
        })
        .catch(err => {
            console.log(err);
            setIsDownloadButtonDisabled(false);
        })
        .finally(() => {
            setTimeout(() => {
                setIsEmailMessageShown(false)
            }, [10000])

            setTimeout(() => {
                setIsDownloadButtonDisabled(false);
                setIsEmailSent(false)
            }, [5000])
        });
    }

    let halfFilter = false;

    if (props.modules.length<=0 && props.activities.length<=0) {
        halfFilter = true;
    }

	return (
            <GridContainer space={false} alignItems="center">
                <GridItem xs={12} sm={12} lg={12} otherclasses={classes.paddingFilter} style={false ? {marginTop:"0"}:{}}>
                    <span style={{fontWeight:"bold",marginRight:"20px",fontSize: "1.2rem"}}>{"Filters"}</span>
                </GridItem>
                <GridItem xs={12} sm={12} lg={halfFilter ? 6:9} otherclasses={classes.paddingFilter} style={false ? {marginTop:"0"}:{}}>
                    <GridContainer space={false} alignItems="center">
                    {props.modules.length>0 && <GridItem xs={12} sm={12} lg={3} otherclasses={classes.noLeftPadding} style={false ? {marginTop:"0"}:{}}>
                        <GridContainer direction="column">
                            <GridItem otherclasses={classes.noLeftPadding}>
                            <span style={{marginRight:"15px"}}>{"Module: "}</span>
                            </GridItem>
                            <GridItem otherclasses={classes.noLeftPadding}>
                            <DropDown
                                value={props.currentModule}
                                analytics={true}
                                paddingShort={true}
                                fullWidth={false}
                                style={{width:"170px"}}
								onChange={(e) => props.filterSectionsMod(e)}
                            >
                                <MenuItem key={"0"} value={"0"} name={""}>{"None"}</MenuItem>
                                {props.modules.map(
                                    (item) => <MenuItem key={item.id} value={item.id} name={item.name}>{item.name}</MenuItem>
                                )}
                            </DropDown>
                            </GridItem>
                        </GridContainer>
                    </GridItem>}
                    {props.activities.length>0 && <GridItem xs={12} sm={12} lg={3} otherclasses={classes.noLeftPadding} style={false ? {marginTop:"0"}:{}}>
                        <GridContainer direction="column">
                            <GridItem>
                            <span style={{marginRight:"15px"}}>{"Question: "}</span>
                            </GridItem>
                            <GridItem>
                            <DropDown
                                value={props.currentActivity}
                                analytics={true}
                                paddingShort={true}
                                fullWidth={false}
                                style={{width:"170px"}}
                                onChange={(e) => props.filterSections(e,"Activity")}
                            >
                                <MenuItem key={0} value={"0"}>{"All"}</MenuItem>
                                {props.activities.map(
                                    (item) => <MenuItem key={item.id} value={item.id} name={item.name}>{item.name}</MenuItem>
                                )}
                            </DropDown>
                            </GridItem>
                        </GridContainer>
                    </GridItem>}
                    {!props.isCustomManager && <GridItem xs={12} sm={12} lg={halfFilter ? 4:3} otherclasses={classes.noLeftPadding} style={false ? {marginTop:"0"}:{}}>
                        <GridContainer direction="column">
                            <GridItem otherclasses={halfFilter ? classes.noLeftPadding:""}>
                            <span style={{marginRight:"15px"}}>{t("Market")+": "}</span>
                            </GridItem>
                            <GridItem otherclasses={halfFilter ? classes.noLeftPadding:""}>
                            <DropDown
                                value={props.currentDepartment}
                                analytics={true}
                                paddingShort={true}
                                fullWidth={false}
                                style={{width:"170px"}}
                                onChange={(e) => props.filterSectionsDep(e)}
                            >
                                <MenuItem key={0} value={0}>{"All"}</MenuItem>
                                {props.departments.map(
                                    (item) => <MenuItem key={item.id} value={item.id} name={item.name}>{item.name}</MenuItem>
                                )}
                            </DropDown>
                            </GridItem>
                        </GridContainer>
                    </GridItem>}
                    {props.showGeographyFilter && <GridItem xs={12} sm={12} lg={halfFilter ? 8:3} otherclasses={classes.noLeftPadding} style={false ? {marginTop:"0"}:{}}>
                        <GridContainer direction="column">
                            <GridItem space={0} spaceNoPadding={true}>
                            <span style={{marginRight:"15px"}}>{t("Geography")+": "}</span>
                            </GridItem>
                            <GridItem space={0} spaceNoPadding={true}>
                            <MultiDimDropdown 
                             options={props.countries}
                             keyname={"name"}
                             cat={"geography"}
                             filterSectionsCountry={props.filterSectionsCountry}
                             clearfilter={props.filterSectionsCountry}
                             selectedOptions={props.currentCountry}
                             multiselectRef={props.multiselectRef}
                             />
                            </GridItem>
                        </GridContainer>
                    </GridItem>}
                    </GridContainer>
                </GridItem>     
                <GridItem xs={12} sm={12} lg={3} otherclasses={props.isMobile ?classes.noLeftPadding:""} 
                style={props.isMobile ? {marginTop:"0"}:props.activities.length>0 ? {textAlign:"center",marginTop: "24px"}:{textAlign:"left",marginTop: "24px"}}>
                        <ActionButton
                            type="submit"
                            disabled={isDownloadButtonDisabled}
                            backGround={false}
                            style={{marginRight:"15px"}}
                            isAnalytics={true}
                            onClick={downloadFeedbackData}
                        >
                            {
                                isEmailSent ? <i style={{fontSize:"11px"}}>{t('Sending email...')}</i> :
                                t('Get data via email')
                            }
                        </ActionButton>
                        {
                            isEmailMessageShown && <i style={{fontSize:"11px"}}>{t('Generating excel file could take a while. Please check you email in few minutes')}</i>
                        }
                        <ActionButton
                            type="submit"
                            style={{marginRight:"15px"}}
                            isAnalytics={true}
                            onClick={props.searchFilter}
                        >
                            {t('Search')}
                        </ActionButton>
                        <ActionButton
                            type="submit"
                            backGround={false}
                            isAnalytics={true}
                            onClick={props.clearFilters}
                        >
                            {t('Clear')}
                        </ActionButton>
                </GridItem>
                {!halfFilter && props.showlimitmessage && <i style={{marginLeft:"2rem",fontSize:"11px"}}>{t("Results won't show when is less than 5 per market/geography")}</i>}
            </GridContainer>
	);
}
export default
withMyHook(withStyles(
    (theme) => ({
      ...analyticsStyle(theme),
      ...useStyles(theme),
    }),
    { withTheme: true },
  )(AnalyticsCompany_FilterBar));
