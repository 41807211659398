import React from "react";

// Styles
import withStyles from "@mui/styles/withStyles";
import Multiselect from 'multiselect-react-dropdown';
import { ourBlue, standardRadious } from 'assets/jss/incept-sustainability-variables.jsx';

const styles = ({color,marginB,paddingShort}) => ({

});
const style = {
    chips: {
      background: ourBlue
    },
    searchBox: {
        borderRadius: standardRadious
    }
  };

class MultiDimDropdown extends React.Component {
		
	render(){
		const {classes, options, value} = this.props;
		return (
            <span>
                <Multiselect
                    displayValue={this.props.keyname}
                    groupBy={this.props.cat}
					ref={this.props.multiselectRef}
                    onRemove={this.props.clearfilter}
                    onSearch={function noRefCheck(){}}
                    onSelect={this.props.filterSectionsCountry}
                    options={options}
                    style={style}
                    showCheckbox
                    />
            </span>
		);
	}
}

export default withStyles(styles)(MultiDimDropdown);