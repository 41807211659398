/*!
 * Description: this file contains the main base component for
 *  the rendering the main page view of analytics
 *
 * Components: Analytics_New
 */
import React, {useState, useEffect, useContext} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import withStyles from "@mui/styles/withStyles";
// core components
import Grid from "@mui/material/Grid";
import Modal from '@mui/material/Modal';
import {Link} from "react-router-dom";
//data
// import data from './data.json';
import pencil from 'assets/img/pencil.png'
import analyticsinfo from 'assets/img/analytics-info.png'
import ResponsiveLine from "components/Nivo/Line.jsx";
import LoadingOverlay from "react-loading-overlay";
import {
    n_comp_pck, ourGreen, ourDarkGreen, ourBlueLight,
    ourBlue, isAuthenticated, ourLightGreen,node_comp_dasboard
} from "assets/jss/incept-sustainability-variables.jsx";
import axios from "axios";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import CardAnalytics from "components/CardAnalytics/Card.jsx";
import CardAnalyticsHeader from "components/CardAnalytics/CardHeader.jsx";
import CardAnalyticsBody from "components/CardAnalytics/CardBody.jsx";
//import { ResponsiveLine } from '@nivo/line'
import FakeButton from "components/CustomButtons/FakeButton";
import ProgressButton from 'components/CustomButtons/Progressbutton.jsx'
import ActionButton from 'components/CustomButtons/ActionButton';
//table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import Typography from "@mui/material/Typography";
//styles
import analyticsStyle from "assets/jss/views/analyticsStyle.jsx";
import {useMediaQuery} from 'react-responsive';

import "react-circular-progressbar/dist/styles.css";
import ActiveEmployees_tooltip from "components/Tooltip/ActiveEmployees_tooltip";
//Translate
import i18n from "i18next";
import i18next from "i18next";
//commentBox
import BounceLoader from "react-spinners/BounceLoader";
import CommentBubble from "components/ContentBox/CommentBubble";
import {withTranslation, Trans, useTranslation} from 'react-i18next';
// Token
import {TokenContext} from "../../context/auth_context/TokenContext";

const useStyles = (theme) => ({
    smallButton: ({color, textColor}) => ({
        backgroundColor: color,
        color: textColor,
        border: "0px",
        borderRadius: "20px",
        fontSize: "14px",
        padding: "5px 30px",
        width: "100%"
    }),
    quoteText: {
        fontSize: "0.875rem",
        "&:before": {
            fontSize: "1.5rem",
            lineHeight: "0.5rem",
        },
        "&:after": {
            fontSize: "1.5rem",
            lineHeight: "0.5rem",
        },
    }
});

function withMyHook(Component) {
    return function WrappedComponent(props) {
        const mobile = useMediaQuery({query: `(max-width: 1024px)`});
        const isTablet = useMediaQuery({query: '(max-width: 1224px)'})
        return <Component {...props} isMobile={mobile} isTablet={isTablet}/>;
    }
}

function AnalyticsPage_manager(props) {
    const {classes, content} = props;
    const { authString } = useContext(TokenContext);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const [loadingfirst, setloadingfirst] = useState(true);
    const handleClose = () => setOpen(false);
    const [analytics_manager, setAnalyticsManager] = useState({
        started_modules: 0,
        p_started_modules: 0,
        end_onemodule: 0,
        pend_onemodule: 0,
        pend_allmodule: 0,
        nusers: 0,
        end_one_module: 0,
        end_allmodule: 0,
        nusers_today: 0,
        allusers_today: 0,
        end_onemodule_today: 0,
        end_allmodule_today: 0,
        ideas_submited: 0,
        users_ideas: 0,
        total_users: 0,
        ideas: []
    });
    const [analytics_learner, setAnalyticsLearner] = useState({
        active_module: 0,
        title_module: "",
        title_activity: "",
        answer_activity: 0,
        answerp_activity: 0,
        empty_activity: 0,
        emptyp_activity: 0
    });
    const [modulesInfo, setModulesInfo] = useState([]);
    const {t, i18n} = useTranslation();
    const username = t('Welcome username', {
        username: props.databackend.username,
        defaultValue: "Welcome, {{username}}"
    });

    const ideaList = analytics_manager.ideas.map((idea, index) => {
        if (index > 0)
            return;
        return (
            <span key={index} className={classes.quoteText}>{idea + '"'}</span>
        );
    });

    useEffect(() => {
        // You need to restrict it at some point
        // This is just dummy code and should be replaced by actual
        if (loadingfirst) {
            getncomplpack();
        }
    }, []);

    //Get ideas from backend
    const getncomplpack = async () => {
        if (!isAuthenticated()) {
            this.props.isExpired();
        }

        const useLang = i18n.language;
        //let url = n_comp_pck + "dashboard?typeuser=learner&wpml_language=" + useLang;
        //Here we will use the new node.js endpoint
        let url = node_comp_dasboard;
        axios
            .get(url, {headers: {Authorization: authString}})
            .then((response) => {
                console.log("New node response here:")
                console.log(response);
                var stateCopy = {};
                stateCopy["started_modules"] = response.data.nusers;
                stateCopy["p_started_modules"] = response.data.pnusers;
                stateCopy["end_onemodule"] = response.data.end_onemodule;
                stateCopy["pend_onemodule"] = response.data.pend_onemodule;
                stateCopy["pend_allmodule"] = response.data.pend_allmodule;
                stateCopy["nusers_today"] = response.data.nusers_today;
                stateCopy["nusers"] = response.data.nusers;
                stateCopy["end_one_module"] = response.data.end_onemodule;
                stateCopy["end_allmodule"] = response.data.end_allmodule;
                stateCopy["allusers_today"] = response.data.allusers_today;
                stateCopy["end_onemodule_today"] = response.data.end_onemodule_today;
                stateCopy["end_allmodule_today"] = response.data.end_allmodule_today;
                stateCopy["ideas_submited"] = response.data.nideas;
                stateCopy["users_ideas"] = response.data.pideas;
                stateCopy["total_users"] = response.data.allusers;
                stateCopy["ideas"] = response.data.ideas;
                setAnalyticsManager(stateCopy);
                var stateCopy2 = {};
                if (response.data.infoActivity !== null && response.data.infoActivity.total !== undefined) {
                    stateCopy2["title_module"] = response.data.infoActivity.module_name;
                    stateCopy2["title_activity"] = response.data.infoActivity.title;
                    stateCopy2["active_module"] = response.data.infoActivity.nactive;
                    stateCopy2["answer_activity"] = response.data.infoActivity.total;
                    stateCopy2["answerp_activity"] = response.data.infoActivity.pactive;
                    stateCopy2["empty_activity"] = response.data.infoActivity.empty;
                    stateCopy2["emptyp_activity"] = response.data.infoActivity.pempty;
                    setAnalyticsLearner(stateCopy2);
                }
                setModulesInfo(response.data.module_completion);
                setloadingfirst(false);
            })
            .catch((err) => {
                //this.error_ocurred("Error API "+n_comp_pck+" "+err);
                console.log("Error ", err);
                setloadingfirst(false);
            });
    }

    const activeModules = modulesInfo.map((module, index) => {
        return (
            <TableRow
                key={index}
            >
                <TableCell component="th" scope="row" className={classes.completionRowTh}>
                    {module.title}
                </TableCell>
                <TableCell className={classes.completionRow} style={{width: "80px"}}>
                    <FakeButton
                        style={{
                            width: "80px"
                        }}
                        color={ourBlueLight}
                        textColor={ourBlue}
                        atooltip={true}
                        textTooltip={"Number of employees that have completed this module"}
                        content={module.completed}/>
                </TableCell>
                <TableCell style={{color: '#add095', width: "80px"}} component="th" scope="row"
                           className={classes.completionRowTh2}>
                    <FakeButton
                        style={{
                            width: "80px"
                        }}
                        color={ourBlue}
                        textColor={"white"}
                        content={module.completed_today}/>
                </TableCell>
            </TableRow>
        );
    });

    return (
        <LoadingOverlay
            active={loadingfirst}
            spinner={<BounceLoader/>}
        >
            <div className={classes.containerAnalytics}><Grid>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} space={0}>
                        <h2 className={classes.titleHeader}>{"Analytics Overview"}</h2>

                    </GridItem>
                </GridContainer>
                <GridContainer style={{marginBottom: "15px"}}>
                    <GridItem xs={12} sm={12} md={12} lg={6} space={0} spaceNoPadding={true}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12} spaceLeft={0}
                                      style={(props.isMobile || props.isTablet) ? {marginBottom: "30px"} : {}}>
                                <CardAnalytics style={{height: "100%"}}>
                                    <CardAnalyticsHeader device={props.isMobile ? 'dashmobile' : ''}
                                                         title="General overview">
                                        <Link className={props.isMobile ? classes.Link_color : classes.Link_style}
                                              to={"/admin/analytics_completion"}>
                                            {"View all completion data"}
                                        </Link>
                                    </CardAnalyticsHeader>
                                    <CardAnalyticsBody style={{paddingTop: "0"}}>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12} space={0} spaceNoPadding={true}>
                                                <TableContainer>
                                                    <Table aria-label="simple table">
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell
                                                                    className={classes.completionRowTh}></TableCell>
                                                                <TableCell
                                                                    className={classes.completionRowTh}></TableCell>
                                                                <TableCell
                                                                    className={classes.completionRowTh}></TableCell>
                                                                <TableCell style={{
                                                                    fontWeight: '800',
                                                                    textAlign: 'center',
                                                                    padding: "21px 16px 5px"
                                                                }}
                                                                           className={classes.completionRowTh}>Today</TableCell>
                                                            </TableRow>
                                                            <TableRow
                                                                key={"Registered employees"}
                                                            >
                                                                <TableCell component="th" scope="row"
                                                                           className={classes.completionRowTh2}>
                                                                    {"Registered employees:"}
                                                                </TableCell>
                                                                <TableCell className={classes.completionRow}
                                                                           style={{width: "80px"}}>
                                                                </TableCell>
                                                                <TableCell className={classes.completionRowTh}
                                                                           style={{width: "80px"}}>
                                                                    <FakeButton
                                                                        style={{
                                                                            width: "80px"
                                                                        }}

                                                                        color={ourBlueLight}
                                                                        textColor={ourBlue}
                                                                        content={analytics_manager.total_users}/>
                                                                </TableCell>
                                                                <TableCell style={{color: '#add095', width: "80px"}}
                                                                           component="th" scope="row"
                                                                           className={classes.completionRowTh2}>
                                                                    <FakeButton
                                                                        style={{
                                                                            width: "80px"
                                                                        }}
                                                                        color={ourBlue}
                                                                        textColor={"white"}
                                                                        content={analytics_manager.allusers_today}/>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow
                                                                key={"Active employees"}
                                                            >
                                                                <TableCell component="th" scope="row"
                                                                           className={classes.completionRowTh2}>
                                                                    {"Active employees:"}
                                                                </TableCell>
                                                                <TableCell className={classes.completionRow}>
                                                                    <FakeButton
                                                                        style={{
                                                                            width: "80px"
                                                                        }}

                                                                        color={"white"}
                                                                        border={"1"}
                                                                        atooltip={true}
                                                                        textTooltip={"% of the registered employees that have started at least 1 module"}
                                                                        borderColor={ourBlue}
                                                                        textColor={ourBlue}
                                                                        content={analytics_manager.p_started_modules + "%"}/>
                                                                </TableCell>
                                                                <TableCell className={classes.completionRowTh}>
                                                                    <FakeButton
                                                                        style={{
                                                                            width: "80px"
                                                                        }}

                                                                        color={ourBlueLight}
                                                                        textColor={ourBlue}
                                                                        content={analytics_manager.nusers}/>
                                                                </TableCell>
                                                                <TableCell style={{color: '#add095'}} component="th"
                                                                           scope="row"
                                                                           className={classes.completionRowTh2}>
                                                                    <FakeButton
                                                                        style={{
                                                                            width: "80px"
                                                                        }}

                                                                        color={ourBlue}
                                                                        textColor={"white"}
                                                                        content={analytics_manager.nusers_today}/>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow
                                                                key={"Completed employees (all modules):"}
                                                            >
                                                                <TableCell component="th" scope="row"
                                                                           className={classes.completionRowTh2}>

                                                                    <ActiveEmployees_tooltip
                                                                        tooltipid={"someid"}/>{"that completed 1 module:"}
                                                                </TableCell>
                                                                <TableCell className={classes.completionRow}>
                                                                    <FakeButton
                                                                        style={{
                                                                            width: "80px"
                                                                        }}

                                                                        color={"white"}
                                                                        border={"1"}
                                                                        borderColor={ourBlue}
                                                                        textColor={ourBlue}
                                                                        atooltip={true}
                                                                        textTooltip={"% of the active employees that have completed at least 1 module"}
                                                                        content={analytics_manager.pend_onemodule + "%"}/>
                                                                </TableCell>
                                                                <TableCell className={classes.completionRowTh}>
                                                                    <FakeButton
                                                                        style={{
                                                                            width: "80px"
                                                                        }}

                                                                        color={ourBlueLight}
                                                                        textColor={ourBlue}
                                                                        content={analytics_manager.end_one_module}/>
                                                                </TableCell>
                                                                <TableCell style={{color: '#add095'}} component="th"
                                                                           scope="row"
                                                                           className={classes.completionRowTh2}>
                                                                    <FakeButton
                                                                        style={{
                                                                            width: "80px"
                                                                        }}

                                                                        color={ourBlue}
                                                                        textColor={"white"}
                                                                        content={analytics_manager.end_onemodule_today}/>
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow
                                                                key={"Completed at least 1 module:"}
                                                            >
                                                                <TableCell component="th" scope="row"
                                                                           className={classes.completionRowTh2}>
                                                                    <ActiveEmployees_tooltip
                                                                        tooltipid={"someid"}/> {"that completed all modules:"}
                                                                </TableCell>
                                                                <TableCell className={classes.completionRow}>
                                                                    <FakeButton
                                                                        style={{
                                                                            width: "80px"
                                                                        }}

                                                                        color={"white"}
                                                                        border={"1"}
                                                                        borderColor={ourBlue}
                                                                        textColor={ourBlue}
                                                                        atooltip={true}
                                                                        textTooltip={"% of the active employees that have completed at least 1 module"}
                                                                        content={analytics_manager.pend_allmodule + "%"}/>
                                                                </TableCell>
                                                                <TableCell className={classes.completionRowTh}>
                                                                    <FakeButton
                                                                        style={{
                                                                            width: "80px"
                                                                        }}

                                                                        color={ourBlueLight}
                                                                        textColor={ourBlue}
                                                                        content={analytics_manager.end_allmodule}/>
                                                                </TableCell>
                                                                <TableCell style={{color: '#add095'}} component="th"
                                                                           scope="row"
                                                                           className={classes.completionRowTh2}>
                                                                    <FakeButton
                                                                        style={{
                                                                            width: "80px"
                                                                        }}

                                                                        color={ourBlue}
                                                                        textColor={"white"}
                                                                        content={analytics_manager.end_allmodule_today}/>
                                                                </TableCell>
                                                            </TableRow>


                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </GridItem>

                                        </GridContainer>
                                    </CardAnalyticsBody>
                                </CardAnalytics>
                            </GridItem>
                        </GridContainer>

                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={6} space={0}
                              spaceNoPadding={true}
                              style={(props.isMobile || props.isTablet) ? {marginRight: "15px"} : {}}>
                        <CardAnalytics style={{height: "100%"}}>
                            <CardAnalyticsHeader device={props.isMobile ? 'dashmobile' : ''}
                                                 title="Ideas and comments">
                                <Link className={props.isMobile ? classes.Link_color : classes.Link_style}
                                      to={"/admin/analytics_ideas"}>
                                    {"View all"}
                                </Link>
                            </CardAnalyticsHeader>
                            <CardAnalyticsBody style={{paddingTop: "0"}}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} space={0}
                                              className={classes.columnCardsPadding}>
                                        <Table aria-label="simple table">
                                            <TableBody>
                                                <TableRow
                                                    key={"Total Ideas and comments"}
                                                    style={{display: "table", tableLayout: "fixed"}}
                                                >
                                                    <TableCell component="th" scope="row"
                                                               style={{
                                                                   width: "auto"
                                                               }}
                                                               className={classes.completionRowTh}>
                                                        {"Total:"}
                                                    </TableCell>
                                                    <TableCell className={classes.completionRowTh2}>
                                                        <FakeButton
                                                            style={{
                                                                width: "100%"
                                                            }}
                                                            color={ourBlueLight}
                                                            textColor={ourBlue}
                                                            content={analytics_manager.ideas_submited}/>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row"
                                                               style={{paddingLeft: "50px"}}
                                                               className={classes.completionRowTh}><ActiveEmployees_tooltip
                                                        tooltipid={"someid"}/>{"that submitted ideas:"}</TableCell>
                                                    <TableCell style={{color: '#add095'}} component="th" scope="row"
                                                               className={classes.completionRowTh}>
                                                        <FakeButton
                                                            style={{
                                                                width: "100%"
                                                            }}
                                                            color={ourBlueLight}
                                                            textColor={ourBlue}
                                                            content={analytics_manager.users_ideas + "%"}/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow
                                                    key={"Newest"}
                                                >
                                                    <TableCell component="th" scope="row"
                                                               className={classes.completionRowTh}>
                                                        {"Newest:"}
                                                    </TableCell>

                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <CommentBubble
                                            color={ourLightGreen} style={{marginTop: "10px"}}
                                            borderColor={ourGreen} textColor="black" content={ideaList}/>
                                        {/* {ideaList} */}
                                    </GridItem>

                                </GridContainer>
                            </CardAnalyticsBody>
                        </CardAnalytics>
                    </GridItem>

                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={6} spaceLeft={0}
                              style={(props.isMobile || props.isTablet) ? {marginBottom: "30px"} : {}}>
                        <CardAnalytics style={{height: "100%"}}>
                            <CardAnalyticsHeader device={props.isMobile ? 'dashmobile' : ''}
                                                 title="Active modules:">
                                <span style={{color: {ourBlue}, marginLeft: '5px', fontSize: 24}}
                                      to={"/admin/analytics_completion"}>
                               {modulesInfo.length}
                                </span>
                            </CardAnalyticsHeader>
                            <CardAnalyticsBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12} space={0} spaceNoPadding={true}>
                                        <TableContainer>
                                            <Table aria-label="simple table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className={classes.completionRowTh}></TableCell>
                                                        <TableCell style={{
                                                            fontWeight: '800',
                                                            textAlign: 'center',
                                                            width: "80px"
                                                        }}
                                                                   className={classes.completionRowTh2}>Completed</TableCell>
                                                        <TableCell style={{
                                                            fontWeight: '800',
                                                            textAlign: 'center',
                                                            width: "80px"
                                                        }}
                                                                   className={classes.completionRowTh2}>Today</TableCell>
                                                    </TableRow>
                                                    {activeModules}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </GridItem>

                                </GridContainer>
                            </CardAnalyticsBody>
                        </CardAnalytics>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={6} spaceLeft={0}
                              style={(props.isMobile || props.isTablet) ? {marginBottom: "30px"} : {}}>
                        <CardAnalytics style={{height: "100%"}}>
                            <CardAnalyticsHeader device={props.isMobile ? 'dashmobile' : ''}
                                                 title="First activity overview">
                                <Link className={props.isMobile ? classes.Link_color : classes.Link_style}
                                      to={"/admin/analytics_company"}>
                                    {"View all employee feedback"}
                                </Link>
                            </CardAnalyticsHeader>
                            <CardAnalyticsBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12} space={0} spaceNoPadding={true}>
                                        <p style={{marginTop: "14px"}}>
                                            <b>{"From Module: "}</b>{analytics_learner.title_module}</p>
                                        <TableContainer>

                                            <Table aria-label="simple table">
                                                <TableBody>
                                                    <TableRow
                                                        key={456}
                                                    >
                                                        <TableCell component="th" scope="row"
                                                                   className={classes.completionRowTh}>
                                                            {"Total answers of this module"}
                                                        </TableCell>
                                                        <TableCell className={classes.completionRow}
                                                                   style={{width: "80px"}}>
                                                            <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}
                                                                color={ourBlue}
                                                                textColor={"white"}
                                                                content={analytics_learner.active_module}/>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <p style={{marginTop: "2rem"}}>
                                            <b>{"Activity: "}</b>{analytics_learner.title_activity}</p>
                                        <TableContainer>

                                            <Table aria-label="simple table">
                                                <TableBody>
                                                    <TableRow
                                                        key={457}
                                                    >
                                                        <TableCell component="th" scope="row"
                                                                   className={classes.completionRowTh}>
                                                            {"Total answers of this activity"}
                                                        </TableCell>
                                                        <TableCell className={classes.completionRow}
                                                                   style={{width: "80px"}}>
                                                            <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}
                                                                color={ourBlueLight}
                                                                textColor={ourBlue}
                                                                atooltip={true}
                                                                textTooltip={"% of all active users for this module"}
                                                                content={analytics_learner.answerp_activity + "%"}/>
                                                        </TableCell>
                                                        <TableCell className={classes.completionRow}
                                                                   style={{width: "80px"}}>
                                                            <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}
                                                                color={ourBlue}
                                                                textColor={"white"}
                                                                content={analytics_learner.answer_activity}/>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow
                                                        key={458}
                                                    >
                                                        <TableCell component="th" scope="row"
                                                                   className={classes.completionRowTh}>
                                                            {"Empty submissions"}
                                                        </TableCell>
                                                        <TableCell className={classes.completionRow}
                                                                   style={{width: "80px"}}>
                                                            <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}
                                                                color={ourBlueLight}
                                                                textColor={ourBlue}
                                                                atooltip={true}
                                                                textTooltip={"% of all active users for this module"}
                                                                content={analytics_learner.emptyp_activity + "%"}/>
                                                        </TableCell>
                                                        <TableCell className={classes.completionRow}
                                                                   style={{width: "80px"}}>
                                                            <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}
                                                                color={ourBlue}
                                                                textColor={"white"}
                                                                content={analytics_learner.empty_activity}/>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow
                                                        key={459}
                                                    >
                                                        <TableCell component="th" scope="row"
                                                                   className={classes.completionRowTh}>
                                                            {"Non empty submissions"}
                                                        </TableCell>
                                                        <TableCell className={classes.completionRow}
                                                                   style={{width: "80px"}}>

                                                        </TableCell>
                                                        <TableCell className={classes.completionRow}
                                                                   style={{width: "80px"}}>
                                                            <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}
                                                                color={ourBlue}
                                                                textColor={"white"}
                                                                content={parseInt(analytics_learner.answer_activity) - parseInt(analytics_learner.empty_activity)}/>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </GridItem>
                                </GridContainer>
                            </CardAnalyticsBody>
                        </CardAnalytics>
                    </GridItem><br></br>
                </GridContainer>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={7} spaceNoPadding={true}
                                  style={props.isMobile ? {marginBottom: 30} : {
                                      marginBottom: 30,
                                      marginLeft: '410px',
                                      marginTop: '250px'
                                  }}>
                            <CardAnalytics style={{height: "100%"}}>
                                <CardAnalyticsHeader device={props.isMobile ? 'dashmobile' : ''} title="My goals:">
                                    <div className={props.isMobile ? classes.Link_color : classes.Link_style}
                                         onClick={handleClose}>{"X"}</div>
                                </CardAnalyticsHeader>
                                <CardAnalyticsBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12} space={0}>
                                            <TableContainer>
                                                <Table aria-label="simple table">
                                                    <TableBody>
                                                        <TableRow
                                                            key={"Registered employees"}
                                                        >
                                                            <TableCell component="th" scope="row"
                                                                       className={classes.completionRowTh}>
                                                                {""}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row"
                                                                       className={classes.completionRowTh}>
                                                                {""}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row"
                                                                       className={classes.completionRowTh}>
                                                                {""}
                                                            </TableCell>
                                                            <TableCell className={classes.completionModuleRow}>
                                                                <p style={{
                                                                    fontWeight: "700",
                                                                }}>Goal</p>

                                                            </TableCell>
                                                            <TableCell className={classes.completionModuleRow}>
                                                                <p style={{
                                                                    fontWeight: "700"
                                                                }}>Status</p>

                                                            </TableCell>

                                                        </TableRow>
                                                        <TableRow
                                                            key={"Registered employees"}
                                                        >

                                                            <TableCell component="th" scope="row"
                                                                       className={classes.completionRowTh}>
                                                                <img src={pencil} alt="pencil icon"/>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row"
                                                                       className={classes.completionRowTh}>
                                                                {"Registered employees"}
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "100%"
                                                                    }}
                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={"1230%"}/>
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "100%"
                                                                    }}

                                                                    color="#009df0"
                                                                    textColor="white"
                                                                    content={"1220%"}/>
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <ProgressButton color='secondary'/>
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <p style={{marginBottom: 0}}>01-11-2022</p>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key={"Active employees"}
                                                        >
                                                            <TableCell component="th" scope="row"
                                                                       className={classes.completionRowTh}>
                                                                <img src={pencil} alt="pencil icon"/>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row"
                                                                       className={classes.completionRowTh}>
                                                                {"Active employees"}
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "100%"
                                                                    }}

                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={"1220%"}/>
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "100%"
                                                                    }}

                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={"1220%"}/>
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <ProgressButton/>
                                                            </TableCell>
                                                            <TableCell className={classes.completionModuleRow}>
                                                                <p style={{marginBottom: 0}}>01-11-2022</p>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key={"Completed Employess(all modules)"}
                                                        >
                                                            <TableCell component="th" scope="row"
                                                                       className={classes.completionRowTh}>
                                                                <img src={pencil} alt="pencil icon"/>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row"
                                                                       className={classes.completionRowTh}>
                                                                {"Completed Employess(all modules):"}
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "100%"
                                                                    }}

                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={"1220%"}/>
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "100%"
                                                                    }}

                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={"1220%"}/>
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <ProgressButton/>
                                                            </TableCell>
                                                            <TableCell className={classes.completionModuleRow}>
                                                                <p style={{marginBottom: 0}}>01-11-2022</p>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key={"Registered employees"}
                                                        >
                                                            <TableCell component="th" scope="row"
                                                                       className={classes.completionRowTh}>
                                                                <img src={pencil} alt="pencil icon"/>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row"
                                                                       className={classes.completionRowTh}>
                                                                {"Ideas and comments"}
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "100%"
                                                                    }}
                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={"1230%"}/>
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "100%"
                                                                    }}

                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={"1220%"}/>
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <ProgressButton color='secondary'/>
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <p style={{marginBottom: 0}}>01-11-2022</p>
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <div>
                                                <FakeButton
                                                    color='#4A79B7'
                                                    textColor="white"
                                                    content={"+ add new goals"}/></div>

                                            <div><FakeButton
                                                style={{
                                                    marginLeft: '815px',
                                                    border: '1px solid #4A79B7',

                                                }}
                                                color='white'
                                                textColor='#4A79B7'
                                                content={"see completed goals"}/></div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} space={0}
                                                  style={props.isMobile ? {textAlign: "center"} : {
                                                      textAlign: "right",
                                                      marginRight: "15px"
                                                  }}>

                                        </GridItem>
                                    </GridContainer>
                                </CardAnalyticsBody>
                            </CardAnalytics>
                        </GridItem>
                    </GridContainer>
                </Modal>
            </Grid>
            </div>
        </LoadingOverlay>
    )

}

export default withMyHook(withStyles(
    (theme) => ({
        ...analyticsStyle(theme),
        ...useStyles(theme),
    }),
    {withTheme: true},
)(AnalyticsPage_manager));

