/*!
 * Description: this file contains the component for
 *  the type of exercise TextWithDropdown, ex. guide 4, step 4,
 *  					" Exercise: Trade off in plastic bags"
 * Components: DropDownWithText
 */
import React from "react";
import ReactHtmlParser from "react-html-parser";

// Styles
import withStyles from "@mui/styles/withStyles";
import { ourGreen } from "assets/jss/incept-sustainability-variables.jsx";

// Style components
import Grid from "@mui/material/Grid";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import checked from 'assets/img/checkedWhiteBg.png';
import Container from "@mui/material/Container";

// Effects
import Collapse from "@mui/material/Collapse";

//Buttons
import ActionButton from "components/CustomButtons/ActionButton";

//Translations
import { withTranslation } from 'react-i18next';

const styles = {
	root: { paddingTop: "20px" },
	card: {
		margin: "0",
		height: "100%",
		backgroundColor: 'transparent',
		color: '#FFFFFF',
		boxShadow: "none",
	},
	buttonContainer: {
		textAlign: "right",
		marginTop: "20px",
		paddingRight: "0"
	},
	buttonContainerAnswers: {
		textAlign: "right",
		paddingRight: "0",
		marginBottom: "60px"
	},
	buttonAnswer: {
		color: "#fff",
		backgroundColor: ourGreen,
		"&:hover, &:active, &:focus": {
			filter: "brightness(85%)",
			backgroundColor: ourGreen,
		},
	},
	statusContainer: {
		textAlign: "right",
		paddingRight: "0"
	},
};


/*
 * Description: component for module 4 exercise 4 with dropdown text
 */
class DropDownWithText extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			answers: Array(4).fill(-3),
			submittedanswer: (typeof this.props.datasav2 !== "undefined") ? true: false
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		var inputn = event.target.id.substr(event.target.id.length - 1);
		this.state.answers[parseInt(inputn)] = event.target.value;
		this.setState({ submittedanswer: false });
		
		//this.props.save2("DropDownWithText", this.state.answers, this.props.inkey, this.props.exercise_id);
	};

	handleSubmit(event) {
		//Commented to allow empty answers
		/*let noneSelected = this.state.answers.every(function (e) {
			return e===-3;
		});
		if (!noneSelected) {*/
			this.setState({ submittedanswer: true });
			this.props.save2("DropDownWithText", this.state.answers, this.props.inkey, this.props.exercise_id);
		//}
	};

	componentDidMount() {
		//retrieve exercise here
		if (typeof this.props.datasav2 !== "undefined") {
			this.state.answers = this.props.datasav2["content"];
		}

		var select0 = document.getElementById('bag0');
		select0.addEventListener('change', this.handleChange);
		select0.value = this.state.answers[0] !== -3 ? this.state.answers[0] : "";

		var select1 = document.getElementById('bag1');
		select1.addEventListener('change', this.handleChange);
		select1.value = this.state.answers[1] !== -3 ? this.state.answers[1] : "";

		var select2 = document.getElementById('bag2');
		select2.addEventListener('change', this.handleChange);
		select2.value = this.state.answers[2] !== -3 ? this.state.answers[2] : "";

		var select3 = document.getElementById('bag3');
		select3.addEventListener('change', this.handleChange);
		select3.value = this.state.answers[3] !== -3 ? this.state.answers[3] : "";
	}

	handleShowAnswer(e) {
		e.preventDefault();
		if (this.state.showAnswer) this.setState({ showAnswer: false });
		else this.setState({ showAnswer: true });
	}

	render() {
		const { classes, exercise, t } = this.props;
		const { statements, answerimg } = exercise;
		/*function preprocessNodes(node) {
			this.handleChange.bind(this);
			/*if (node.type=="tag" && node.attribs.name=="bag0")
			  return <input type="text" name="bag0"  onChange={func}
			  style={{height: "20px",borderStyle: "none none solid none", textAlign: "center"}}/>;*/
		/*}*/


		const gridItems = statements.map((statement, index) => (
			<Grid item md={6} key={index}>
				<div className={classes.gridContentWrapper}>
					<Grid container spacing={1} alignItems="stretch">
						<Grid item xs={12} md={6} lg={4}>
							<img alt="" style={{ height: "222px" }} src={statement.img} />
						</Grid>
						<Grid item xs={12} md={6} lg={8}>
							<Card className={classes.card}>
								<CardBody isexercise={true}>
									<p>
										<b>{statement.heading}</b>
									</p>
									{ReactHtmlParser(ReactHtmlParser(statement.text))}
									{/* {statement.modify && (
                    <select style={{ borderStyle: "none none solid none" }}>
                      <option></option>
                      <option value="use more GWP in production.">use more GWP in production.</option>
                      <option value="less robust.">less robust.</option>
                    </select>
                  )} */}
								</CardBody>
							</Card>
						</Grid>
					</Grid>
				</div>
			</Grid>
		));
		return (
			<div className={classes.root}>
				<Grid container spacing={2}>
					{gridItems}
				</Grid>
				<div className={classes.statusContainer}>
					{this.state.submittedanswer && <p>{t("Great! Your answer has been saved.")}</p>}
				</div>
				<Container className={classes.buttonContainer}>
					<ActionButton
						size="auto"
						type="submit"
						nomargin={true}
						centered={true}
						onClick={(e) => this.handleSubmit(e)}
					>
						{t('Submit')}
					</ActionButton>
				</Container>
				<Container className={classes.buttonContainerAnswers}>
					<ActionButton
						onClick={(e) => this.handleShowAnswer(e)}
						type="submit"
						size="auto"
					>
						{t('See suggested answer')}
					</ActionButton>
				</Container>

				<Container className={classes.answercontainer}>
					<Collapse in={this.state.showAnswer}>
						{ReactHtmlParser(ReactHtmlParser(answerimg))}
					</Collapse>
				</Container>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(DropDownWithText));
