/*!
 * Description: this file contains the component for
 *  the type of exercise DragAndDropTwoListGrouped, ex. guide 2, step 5,
 *						"Exercise: Current status of the three dimensions of sustainability at your company"
 * Components: DragAndDropTwoListGrouped
 */
import React from "react";


// Styles
import withStyles from "@mui/styles/withStyles";



import clsx from "clsx";

// Style components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";

//Extra component for touch screens (becomes selectable instead of drag and drop)
import DragAndDropBecomesSelect from "components/Exercise/DragAndDropTwoListGroupedBecomesSelect";
import {grayCape20,standardRadious } from 'assets/jss/incept-sustainability-variables.jsx';

import Snackbar from "@mui/material/Snackbar";
import checked from 'assets/img/checkedWhiteBg.png';
//Translations
import { withTranslation } from 'react-i18next';


const colors = {
	attention : '#FF6F69',
	idea : '#7BDCA3',
	textBase : '#000',
	scrollbar:grayCape20
};

const styles = {
	root: {},
	dropzonesContainer: {
		padding: "5px 0",
	},
	gridDropItem: {
		padding: "10px",
	},
	dropListMain: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
	},
	dropListBase: {
		textAlign: "center",
		padding: "10px 10px 40px 10px",
		border: `2px solid #FFF`,
		borderRadius: standardRadious,
		width: "100%",
	},
	dropListTitle: {
		marginBottom: "10px",
		textTransform: "uppercase",
		fontWeight: "600"
	},
	dropListOne: {},
	dropListTwo: {},
	dropZone: {
		minHeight: "100px",
	},
	optionsContainer: {
		padding: "20px 0",
		marginBottom: "20px",
		fontSize: "20px",
		height: "360px",
		overflow: "auto",
		'&::-webkit-scrollbar-track': {
			boxShadow: "inset 0 0 5px grey",
			borderRadius: "10px"
		},
		'&::-webkit-scrollbar': {
			height: '7px'
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: colors.scrollbar,
			borderRadius: '20px'
		},
		scrollbarColor: `${colors.scrollbar}`, //only firefox > 64
		scrollbarWidth: 'auto', //only firefox > 64
	},
	optionBlock: {
		borderRadius: "1.5rem",
		padding: "5px 10px",
		textAlign: "inherit",
		fontWeight: "600",
		fontSize: "16px",
		border: `1px solid #FFF`,
		margin: "10px",
		"&[draggable=true]": {
			cursor: "grab",
			"&:hover": {
				fontWeight: "bold",
				border: "2px solid rgba(255, 255, 255, 1)",
			},
			"&:active": {
				cursor: "grabbing",
			},
		},
	},
	dragListContainer: {
		borderRadius: standardRadious,
		padding: "10px",
		height: "100%",
		border: `2px solid #FFF`,
	},
	dragListTitle: {
		fontWeight: "bold",
		textAlign: "center",
		padding: "5px",
	},
	gridDragListItem: {
		padding: "10px",
	},
	circle: {
		height: "30px",
		width: "30px",
		marginLeft: "10px",
		marginRight: "15px",
	},
	buttonContainer: {
		textAlign: "right",
		marginTop: "20px",
		paddingRight: "10px",
		marginBottom: "60px",
	},
	statusContainer: {
		textAlign: "right",
		paddingRight: "0",
		"@media only screen and (max-width: 960px)": {
			marginTop: "20px"
		  }
	},
};

const Option = withStyles(styles)(({ classes, children, color, ...rest }) => (
	<div draggable className={classes.optionBlock} style={{backgroundColor: color}} {...rest}>
		{children}
	</div>
));

const DragList = withStyles(styles)(
	({
		classes,
		children,
		options,
		title,
		containerColor,
		optionColor,
		onDragStart,
	}) => (
		<Grid item xs={12} md className={classes.gridDragListItem}>
			<div
				className={classes.dragListContainer}
				style={{ backgroundColor: containerColor }}
			>
				<div className={classes.dragListTitle}>{title}</div>
				<div className={classes.dragList}>
					{options.map((option, index) => (
						<Option
							key={index}
							color={option.optionColor}
							onDragStart={(e) => onDragStart(e, option.originalIndex)}
						>
							{option.text}
						</Option>
					))}
				</div>
			</div>
		</Grid>
	)
);

const DropList = withStyles(styles)(
	({
		classes,
		classForList,
		options,
		title,
		onDragStart,
		onDrop,
		onDragOver,
	}) => (
		<Grid item xs={12} md={6} className={classes.gridDropItem}>
			<div className={classes.dropListMain}>
				<div className={classForList} onDrop={onDrop} onDragOver={onDragOver}>
					<div className={classes.dropListTitle}>{title}</div>
					<div className={classes.dropZone}>
						{options.map((option, index) => (
							<Option
								key={index}
								color={option.optionColor}
								onDragStart={(e) =>
									onDragStart(e, option.originalIndex, option.dragListIndex)
								}
							>
								{option.text}
							</Option>
						))}
					</div>
				</div>
			</div>
		</Grid>
	)
);

/*
 * Description: component for dropdown exercise
 */
class DragAndDropTwoListGrouped extends React.Component {
	//Copy original dragLists into optionsByList,
	//but it allows each option to retain information that is useful
	// in the future.

	//dropListIndex refers to current drop list that this option is dropped in.
	//A value of -1 means it hasn't been dropped.

	//Other values should only be used by reference.
	constructor(props) {
		super(props);
		this.state = {
			optionsByList: this.props.exercise.dragLists.map((list, dragListIndex) =>
				list.options.map((option, optIndex) => ({
					text: option.text,
					optionColor: list.containerColor,
					originalIndex: optIndex,
					dragListIndex: dragListIndex,
					dropListIndex: -1,
					statement_id: option.statement_id
				}))
			),
			boxOneLength: 0,
			boxTwoLength: 0,
			answer_to_save: [],
			openAlert: false,
			startdrag:"",
			submittedanswer: (typeof this.props.datasav2 !== "undefined") ? true: false
		};

		//Saved answers
		if (typeof this.props.datasav2 !== "undefined" && this.props.datasav2["content"].length>0) {
			var clonedraglist = this.clone(this.state.optionsByList); 
			var aux = this.props.datasav2["content"];

			for (var index0 = 0; index0 < aux.length; index0++) {
				for (var index2 = 0; index2 < aux[index0].length; index2++) {
					if (aux[index0][index2].dropListIndex===0)
						this.state.boxOneLength += 1;
					else if (aux[index0][index2].dropListIndex===1)
						this.state.boxTwoLength += 1;
					let original_text = clonedraglist[index0].find(x => x.statement_id === parseInt(aux[index0][index2].statement_id)).text;
					aux[index0][index2].text =  original_text;
					aux[index0][index2].optionColor =  clonedraglist[index0][index2].optionColor;
				}
			}
			this.state.optionsByList = aux;
		}
	}

	clone(obj) {
		if (null == obj || "object" != typeof obj) return obj;
		var copy = obj.constructor();
		for (var attr in obj) {
		  if (obj.hasOwnProperty(attr)) copy[attr] = this.clone(obj[attr]);
		}
		return copy;
	  }


	componentDidMount() {
		
	}

  	componentDidUpdate() { //Saving for the backend
    		
	}

	onDragStart = (event, optionIndex, dragListIndex, droppableSquare="") => {
		event.dataTransfer.setData("optionIndex", optionIndex.toString());
		event.dataTransfer.setData("dragListIndex", dragListIndex.toString());
		this.setState({startdrag:droppableSquare});
	};

	onDragOver = (event) => {
		event.preventDefault();
	};

	//If we are dropping with a -1 value (its default) means we are dropping
	//this option back to drag lists.
	onDrop = (event, dropListIndexBeingDropped = -1) => {
		let optionIndex = parseInt(event.dataTransfer.getData("optionIndex"));
		let dragListIndex = parseInt(event.dataTransfer.getData("dragListIndex"));

		const max = this.props.exercise.maxSelection;
      	if (dropListIndexBeingDropped === 0 && (this.state.boxOneLength) >= max) {
			this.setState({ openAlert: true });
			return;
		}
		if (dropListIndexBeingDropped === 1 && (this.state.boxTwoLength) >= max) {
			this.setState({ openAlert: true });
			return;
		}

		this.setState((prevState) => {
			let sizeOne = this.state.boxOneLength;
			let sizeTwo = this.state.boxTwoLength;
			
			let prevBox = -1;
			const aux_options = prevState.optionsByList.map((value, index) =>
							index === dragListIndex
							? value.map(({ dropListIndex, ...rest }, optIndex) => {
								var aux = optIndex === optionIndex
								? dropListIndexBeingDropped
								: dropListIndex;
								if  (optIndex === optionIndex)
									prevBox = dropListIndex;
								return ({...rest, dropListIndex:aux});
							})
							: value);

			
			/*if (((!this.state.startdrag) && dropListIndexBeingDropped!==-1) ||
				(!!this.state.startdrag))
				this.props.save2("DragDropTwo_st", aux_options, 
							this.props.inkey, this.props.exercise_id);*/

			if (dropListIndexBeingDropped===0) {
				sizeOne += 1;
				if (prevBox===1)
					sizeTwo -= 1;
			}
			else if (dropListIndexBeingDropped===1) {
				sizeTwo += 1;
				if (prevBox===0)
					sizeOne -= 1;
			}
			else if ((dropListIndexBeingDropped===-1 && prevBox===0))
				sizeOne -= 1;
			else if ((dropListIndexBeingDropped===-1 && prevBox===1))
				sizeTwo -= 1;

			if (((!this.state.startdrag) && dropListIndexBeingDropped!==-1) ||
				(!!this.state.startdrag))
				return {
					boxOneLength: sizeOne,
					boxTwoLength: sizeTwo,
					optionsByList: aux_options,
					answer_to_save: aux_options,
					submittedanswer: false
				}
			else 
				return {
					boxOneLength: sizeOne,
					boxTwoLength: sizeTwo,
					submittedanswer: false,
					optionsByList: aux_options
				}
		});
		
	};

	handleSelectChange(event, optIndex, dragListIndex){
		const dropListIndexBeingDropped = parseInt(event.target.value);

		const max = this.props.exercise.maxSelection;
      	if (dropListIndexBeingDropped === 0 && (this.state.boxOneLength) >= max) {
			this.setState({ openAlert: true });
			return;
		}
		if (dropListIndexBeingDropped === 1 && (this.state.boxTwoLength) >= max) {
			this.setState({ openAlert: true });
			return;
		}

		this.setState((prevState) => {
			let sizeOne = this.state.boxOneLength;
			let sizeTwo = this.state.boxTwoLength;
			let prevBox = -1;

			const aux_options = prevState.optionsByList.map((value, index) =>
							index === dragListIndex
							? value.map(({ dropListIndex, ...rest }, optIndexInner) => {
								var aux = optIndexInner === optIndex
								? dropListIndexBeingDropped
								: dropListIndex;
								if  (optIndexInner === optIndex)
									prevBox = dropListIndex;
								return ({...rest, dropListIndex:aux});
							})
							: value);

			/*this.props.save2("DragDropTwo_st", aux_options, 
			this.props.inkey, this.props.exercise_id);*/

			if (dropListIndexBeingDropped===0) {
				sizeOne += 1;
				if (prevBox===1)
					sizeTwo -= 1;
			}
			else if (dropListIndexBeingDropped===1) {
				sizeTwo += 1;
				if (prevBox===0)
					sizeOne -= 1;
			}
			else if ((dropListIndexBeingDropped===-1 && prevBox===0))
				sizeOne -= 1;
			else if ((dropListIndexBeingDropped===-1 && prevBox===1))
				sizeTwo -= 1;
			return {
				boxOneLength: sizeOne,
				boxTwoLength: sizeTwo,
				optionsByList: aux_options,
				answer_to_save: aux_options,
				submittedanswer: false
			}
		});
	}

	handleSubmit(e) {
		//Commented to allow empty answers
		//if (this.state.answer_to_save.length>0) {
			this.setState({ submittedanswer: true });
			this.props.save2("DragDropTwo_st", this.state.answer_to_save, this.props.inkey,
					this.props.exercise_id);
		//}
	}

	handleClose(e, reason) {
		if (reason === "clickaway") {
		  return;
		}
		this.setState({ openAlert: false });
	}

	render() {
		const { classes, t, exercise } = this.props;
		const { dragLists, dropListOne, dropListTwo } = exercise;

		const renderDragLists = this.state.optionsByList.map(
			(options, listIndex) => {
				let { optionColor, containerColor, title } = dragLists[listIndex];
				let listProps = { optionColor, containerColor, title };
				return (
					<DragList
						key={listIndex}
						options={options.filter(
							({ dropListIndex }) => dropListIndex === -1
						)}
						{...listProps}
						onDragStart={(e, optionIndex) =>
							this.onDragStart(e, optionIndex, listIndex)
						}
					/>
				);
			}
		);

		const dragAndDrop = (
			<React.Fragment>
				<div
					className={classes.optionsContainer}
					onDrop={(e) => this.onDrop(e)}
					onDragOver={(e) => this.onDragOver(e)}
				>
					<Grid container alignItems="stretch">
						{renderDragLists}
					</Grid>
				</div>
				<div className={classes.dropzonesContainer}>
					<Grid container>
						<DropList
							classForList={clsx(classes.dropListBase, classes.dropListOne)}
							title={dropListOne.title}
							options={this.state.optionsByList
								.reduce((acc, list) => acc.concat(list))
								.filter(({ dropListIndex }, index) => dropListIndex === 0)}
							onDrop={(e) => this.onDrop(e, 0)}
							onDragOver={(e) => this.onDragOver(e)}
							onDragStart={(e, optionIndex, dragListIndex) =>
								this.onDragStart(e, optionIndex, dragListIndex,"lft")
							}
						/>
						<DropList
							classForList={clsx(classes.dropListBase, classes.dropListTwo)}
							title={dropListTwo.title}
							options={this.state.optionsByList
								.reduce((acc, list) => acc.concat(list))
								.filter(({ dropListIndex }, index) => dropListIndex === 1)}
							onDrop={(e) => this.onDrop(e, 1)}
							onDragOver={(e) => this.onDragOver(e)}
							onDragStart={(e, optionIndex, dragListIndex) =>
								this.onDragStart(e, optionIndex, dragListIndex,"rght")
							}
						/>
					</Grid>
				</div>
			</React.Fragment>
		);

		const select = 
		<DragAndDropBecomesSelect
				lists={this.state.optionsByList}
				dragLists={dragLists}
				answers={[dropListOne.title, dropListTwo.title]}
				onChange={(event, optIndex, dragListIndex) => this.handleSelectChange(event, optIndex, dragListIndex)}
		/>;

		//const touch = window.matchMedia('(hover: none)').matches;
		//This works on andrid as well:
		const touch = window.matchMedia('(pointer: coarse)').matches;

		return (
			<div className={classes.root}>
				{touch ? select : dragAndDrop}
				<div className={classes.statusContainer}>
				{this.state.submittedanswer && <p>{t("Thank you! Your feedback has been submitted")}</p>}
				</div>
				<Container className={classes.buttonContainer}>
					<ActionButton
						size="auto"
						type="submit"
						nomargin={true}
						centered={true}
						onClick={(e) => this.handleSubmit(e)}
					>
						{t('Submit')}
					</ActionButton>
				</Container>
				<Snackbar
				open={this.state.openAlert}
				autoHideDuration={3000}
				onClose={(e, reason) => this.handleClose(e, reason)}
				message={t('You must choose up to ')+this.props.exercise.maxSelection+t(' for each box')}
				></Snackbar>
			</div>
		);
	}
}


export default withTranslation()(withStyles(styles)(DragAndDropTwoListGrouped));
