import {
    blackColor,
    whiteColor,
    hexToRgb
  } from "assets/jss/material-dashboard-react.jsx";

  import {
    standardRadious
  } from "assets/jss/incept-sustainability-variables.jsx";
  
  const cardAnalyticsStyle = {
    card: {
      border: "0",
      borderRadius: standardRadious,
      color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
      background: whiteColor,
      width: "100%",
      boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      minWidth: "0",
      fontFamily: "gotham-font-medium",
    }
  };
  
  export default cardAnalyticsStyle;