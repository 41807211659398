/*!
* Description: this file contains a component
*  that renders a disabled lookalike button that contains numbers or text
*/

import React, { useState } from 'react';
import withStyles from "@mui/styles/withStyles";
import moreInfo from 'assets/img/moduleIcon.svg';
import analyticsStyle from "assets/jss/views/analyticsStyle.jsx"; //style for all analytics
import { ourGreen20,ourGreen,ourGreen40,ourGreen60 } from "assets/jss/incept-sustainability-variables.jsx";
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import CardAnalytics from "components/CardAnalytics/Card.jsx";
import CardAnalyticsHeader from "components/CardAnalytics/CardHeader.jsx";
import CardAnalyticsBody from "components/CardAnalytics/CardBody.jsx";
import ResponsivePie from "components/Nivo/ResponsivePie.jsx";
//import { ResponsivePie } from '@nivo/pie';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import LegendGraphs from "components/Nivo/LegendGraphs.jsx"
import Tooltip from '@mui/material/Tooltip';
import { useMediaQuery } from 'react-responsive'; //for mobile responsiveness

const useStyles = (theme) => ({
    responsivePie: {
        '& g:nth-child(2)':{
			'& g:nth-child(1) text':{
                fontSize: "28px !important"
            }
		}
    },
    frameGraph: {
        //boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;",
        margin: "10px 50px 50px 50px"
    },
    pieLabel: {
        fontSize: "18px",
        fontWeight: "bold"
    },
    pieLabelVal: {
        fontSize: "26px",
        fontWeight: "bold",
        color: ourGreen
    }
});


const themePie = {
    "legends": {
        "text": {
            "fontSize": 18,
        },
    }
  };

function withMyHook(Component) {
    return function WrappedComponent(props) {
      const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
      return <Component {...props} isMobile={mobile} />;
    }
}

const TextOnlyTooltip = withStyles({
	tooltip: {
		fontSize: "14px"
	}
})(Tooltip);

function PieaRankingCard(props) {
	const {classes, content} = props;

    const pieColors = [ourGreen,ourGreen40,ourGreen60,ourGreen20]

    const items_bars = [];
	const items_pies = [];
    const defs = [
        
    ];
    let i = 0;
    props.data.forEach((arrayItem,index) => {
        let constructPie = {"id":"","label":"","value":arrayItem.rest}
        let pieColors2 = [];
        for (let j=0;j<arrayItem.arr1.length;j++) {
            pieColors2[j] = arrayItem.arr1[j].color;
        }
        items_pies.push(
            <GridItem xs={12} sm={12} md={12} lg={6} key={i}>
                <div className={classes.frameGraph}>
                <div className={classes.responsivePie} style={{height:"300px",width:"500px",margin:"0 auto"}}>
						<div style={{textAlign:"center",paddingTop:"20px"}}><h4>{(props.titlesGraph[index]!==undefined) ? Object.values(props.titlesGraph[index])[1]:""}</h4></div>
                            <ResponsivePie
                                data={arrayItem.arr1}
                                halfpie={false}
                                pieColors={pieColors2}
                                enableArcLinkLabels={true}
                                theme={null}
                                defs={[]}
                            />  
						</div>    
                        <LegendGraphs
                                dataLegends={arrayItem.arr1}
                                colors={pieColors2}
                                style={{paddingBottom:"50px",paddingTop: "20px"}}
                            />                
                </div>
                
            </GridItem>
        );
        i++;
    });

	return (
            <CardAnalytics>
                <CardAnalyticsHeader title={props.title}>
                    <div style={{marginLeft:"auto",cursor:"pointer"}}>
                        {/*<ActionButton
                                type="submit"
                                isAnalytics={true}
                                style={{marginRight:"10px"}}
                            >
                                {'+add to actions page'}
	</ActionButton>*/}
                        <TextOnlyTooltip title={"How are the stats calculated?"} arrow>
                            <img
                                className="responsive2"
                                src={moreInfo}
                                alt="How are the stats calculated?"
                                onClick={(e) => props.showHideModal("pieRanking")}
                            />
                        </TextOnlyTooltip>
                    </div>
                </CardAnalyticsHeader>
                <CardAnalyticsBody>
                    {props.children}
                    <div style={{marginBottom:"60px"}}>
                        <GridContainer justifyContent="center">
                            {items_pies}
                        </GridContainer>
                    </div>
                </CardAnalyticsBody>
            </CardAnalytics>
	);
}
export default
withMyHook(withStyles(
    (theme) => ({
      ...analyticsStyle(theme),
      ...useStyles(theme),
    }),
    { withTheme: true },
  )(PieaRankingCard));
