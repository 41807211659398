/*!
* Description: this file contains the component for
*  a section that has only content: ex. "Introduction"
* Components: GuideStandardCard
*/
import React from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Container from '@mui/material/Container';
import withStyles from "@mui/styles/withStyles";
import Collapse from "@mui/material/Collapse";
import { GuideCardTitle } from "components/GuidePage/GuideStyleCommon.js";
import WrapperFeedback from "components/Feedback/WrapperFeedback.js";
import {  ourOrange,ourGreen,ourBlue } from "assets/jss/incept-sustainability-variables";
import arrowdown from 'assets/img/down-arrow.svg';
import checkmark from 'assets/img/checkmark.png';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import CarouselSection from "components/Exercise/CarouselSection.js";
import { Carousel } from 'react-responsive-carousel';
import greyCheckmark from 'assets/img/checkmark-grey.svg';
import greenCheckmark from 'assets/img/checkmark-green.svg';
import { useMediaQuery } from 'react-responsive';
//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import {flipCards_MobileFriendly} from "assets/js/flip-cards.js";
//Translations
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from "react-html-parser";

//pass parameters to the styles function
const styles = (theme) => ({
	root: {},
	cardColor: {
		backgroundColor: ourGreen,
		color: "white",
	},
	cardNoColor: {
		color: "#000000",
	},
	notopPadding: {
		padding: "0px 20px"
	},
	cardExample: {
		marginBottom: "10px",
		minHeight: "110px",
		marginTop: "0"
	},
	cardHeader: {
		borderBottom: 'none'
	},
	cardBodyText: {
		fontSize: ".95rem",
		"@media only screen and (max-width: 960px)": {
			marginTop: "30px",
		}
	},
	iconNavBack: {
		fontSize: '30px',
	},
	customDivider: {
		marginTop: '20px'
	},
	titlecolor: {
		color: "white"	
	},
	container: {
		"@media only screen and (max-width: 960px)": {
			padding: "0",
		}
	},
	buttonContainer: {
		textAlign: "end",
		margin: "3px 20px 20px 0",
		paddingRight: "0",
		"@media only screen and (max-width: 960px)": {
			margin: "3px 20px 20px 0",
		},
		"@media only screen and (max-width: 600px)": {
			textAlign: "center",
		},
	},
	checkmark: {
		"@media only screen and (max-width: 960px)": {
			float: "right",
		},
	},
	iconDownArrow: {
		cursor: "pointer",
	},
	iconDownArrowDiv: {
		paddingTop: "20px",
		textAlign: "center",
	},
	heightFix: {
		height: "80vh",
		"@media only screen and (max-width: 960px)": {
			height: "auto"
		},
	},
    titlePage: (props) => ({
        textAlign: "center",
        marginTop: "10px",
        "@media only screen and (max-width: 960px)": {
            fontSize: "0.70rem",
        },
		"& h2": {
			color: props.colorTitle || ourBlue,
		}
    }),
	cardHead: {
		alignItems: "center",
		justifyContent: "center",
		minHeight: "110px"
	},
	titleMargin: {
		marginLeft: "20px"
	},
	carouselPer: {
		"& .carousel": {
			margin: "0 auto",
			textAlign: "center"
        },
        "& .carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover,.carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover": {
            background: ourOrange,
        },
		"& .carousel .thumb.selected, .carousel .thumb:hover": {
			border: "3px solid "+ourOrange,
		},
		"& .carousel .control-dots .dot": {
			boxShadow: "2px 2px 3px "+ourOrange,
		}
    }
});

function withMyHook(Component) {
    return function WrappedComponent(props) {
      const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
      return <Component {...props} isMobile={mobile} />;
    }
}

const GuideStandardCardStyled = withStyles(styles)(({ colorTitle,...props }) => (
    <GuideStandardCard {...props} />
));
class GuideStandardCard extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			isdefaultView: (this.props.videoContent!="") ? true:false,
			defaultView: (this.props.defaultView==="Video content") ? false:true,
			loading: true,
			notOpen: true
		}
	}

	componentWillReceiveProps(nextProps) {
        //console.log('componentWillReceiveProps', nextProps);
		if (this.state.loading) {
			this.setState({isdefaultView:(nextProps.videoContent.length!==0) ? true:false,
				defaultView: (nextProps.defaultView==="Video content") ? false:true, loading: false});
		}
    }

	componentDidMount() {
		//add onclick
		//if (this.props.isMobile)
			flipCards_MobileFriendly();
	};

	decodeHtml(html) {
		var txt = document.createElement("textarea");
		txt.innerHTML = html;
		return txt.value;
	}

	handleSubmit(e) {
		e.preventDefault();
		this.setState({ defaultView: !this.state.defaultView });
	}

	openSectionComp() {
		this.setState({ notOpen: false });	
		this.props.openSection();
	}

	render() {
		const { classes, title, t,feedback,saveresloc,stepn,
			isexercise, isitrich,textContent,videoContent,isFirstSection,
			showcard,openSection,
			readCard,datasav,smoothtrans,type,images,index } = this.props;
		const classrich = (isitrich) ? classes.cardNoColor:classes.cardColor;
		let stylePaddingBody = (showcard) ? {padding: "0 30px 30px 30px"}:{padding: "0 30px"};
		//cARROUSEL
		let htmlContent = textContent;
		let is_carrousel = false;
		let carousel_content = "";
		if (htmlContent!=="" && htmlContent[0]!== undefined && htmlContent[0].ref!==undefined) {
			if (htmlContent[0].ref==="carousel_feature_is") {
				is_carrousel = true;
				carousel_content = htmlContent[0].props.children.filter(function(image,index) {
					if (image.type!=="img") {
					  return false; // skip
					}
					return true;
				  }).map(function(image) { return <div key={index} className={"carrouselImage_isxf"}>{image}</div> });
			}
		}
		
		return (
			<div className={classes.root}>
				<Container className={classes.container}>
					{/*Title of the page*/}
					{isFirstSection && 
						<div className={classes.titlePage}>{textContent}</div> 
					}
					{!isFirstSection && 
						<Card className={classes.cardExample+" "+classrich+((!this.state.defaultView && showcard) ? classes.heightFix:"")}>
						<CardBody isexercise={isexercise} 
						style={ (this.props.isMobile) ? {padding: "0 0 0 30px"}:stylePaddingBody}									
						className={(isitrich) ? "":classes.notopPadding}>
							<GridContainer justifyContent="center" 
								style={
									{
										alignItems: "center",
										justifyContent: "space-between",
										minHeight: "110px",
										flexWrap: (this.props.isMobile) ? "wrap" : "nowrap",
										flexDirection: (this.props.isMobile) ? "column" : "row",
										gap: (this.props.isMobile) ? "1rem" : undefined,
										margin: (this.props.isMobile) ? "20px 0" : "auto",
									}
							}>
									{/* Making sure it fills out horizontally. Remove at your own risk! */}
									<GridItem style={{ width: "100%" }} spaceLeft={0}>
										<GridContainer
											alignItems="center"
											justifyContent="space-between"
											style={this.props.isMobile ? { width: "100%", flexDirection: "row"}:{ width: "100%", flexDirection: "row", flexWrap: "nowrap" }}
										>
											<GuideCardTitle color={(isitrich) ? "":"white"} style={{marginBottom:"0"}}>
												<GridContainer
													style={{
														alignItems:"center",
														flexDirection: "row",
														flexWrap: "nowrap",
														gap: "1rem",
														flexGrow: "1",
														width: "100%"
													}}
												>
													{/* Static width is required here. */}
													<GridItem  space={0} spaceNoPadding={true}>
														<img
															src={ (typeof datasav === "undefined") ? greyCheckmark : greenCheckmark}
															className={classes.dotimage2}
															width="80px"
															style={{paddingRight:"20px"}}
															alt='Information'
														/>
													</GridItem>
													<GridItem 
														md="auto"
														lg="auto"
														spaceLeft={0}
														style={{ flexGrow: "1", width: "90%", maxWidth: "100%" }}
													>
														<span>{title}</span>
													</GridItem>
												</GridContainer>
											</GuideCardTitle>
											{(isitrich) &&
												<GridItem xs={12} md={3} spaceLeft={0} 
												style={this.props.isMobile ? {cursor:"pointer",
												marginTop:`${showcard ? "20px":"10px"}`}:
															{cursor:"pointer"}}>
														<div className={`${classes.iconDownArrowDiv}`}
															style={(title=="") ? 
																{marginBottom: "12.8px",
																paddingTop:"7px",
																textAlign:"right",
																display:"flex"} :
																{paddingTop:"0",
																textAlign:`${this.props.isMobile ? "center":"right"}`}}
														>
																{showcard && this.state.isdefaultView && 
															<ActionButton 
																onClick={(e) => this.handleSubmit(e)} 
																type="submit"  
																centered={true}
																style={(this.state.isdefaultView && showcard) ? 
																	{width:"auto",
																	marginRight: `${this.props.isMobile ? "0":"20px"}`,
																	marginLeft: `${this.props.isMobile ? "30px":"0"}`,
																	marginBottom:`${this.props.isMobile ? "20px":"0"}`}:
																	{visibility:"hidden"}}
																
															>
																{(this.state.defaultView) && t('See video instead')}
																{(!this.state.defaultView) && t('See text instead')}
															</ActionButton>	}
														</div>
												</GridItem>
											}
										</GridContainer>
									</GridItem>
									<div
									style={{cursor:"pointer"}}
									onClick={()=> this.openSectionComp()}>
									<img
										src={arrowdown}
										style={{
											transform: (showcard) ? "rotate(-180deg)": undefined,
											display: "block",
											margin: `${this.props.isMobile ? "0":"0 auto"}`,
											flexGrow: 0,
											marginLeft:`${this.props.isMobile ? "-30px":"0"}`
										}}
										alt="Go to next section"
										title="Go to next section"
										className={classes.iconDownArrow+((this.state.notOpen && !this.props.isMobile && !showcard && (parseInt(stepn)===1) && index===1) ? " arrowPulseDown":"")}
									/>
									</div>
							</GridContainer>

						
							<Collapse in={(isitrich) ? showcard:true}
							style={{margin:`${this.props.isMobile ? "10px 0 20px -20px":"auto"}`}}
								addEndListener={() => {
								// use the css transitionend event to mark the finish of a transition
									if (showcard) {
										saveresloc();
										setTimeout(() => {smoothtrans.scrollIntoView({behavior: "smooth"})}, 100);
									}
								}}
							>
							{(this.state.defaultView) && 
							<div 
							className={classes.cardBodyText+" word-break"}
							style={{margin:`${this.props.isMobile ? "0px 20px":"auto"}`}}
							>
								{is_carrousel &&
									<Carousel 
									showArrows={true} 
									showStatus={false}
									className={classes.carouselPer} 
									width={"1'0%"}
									>
										{carousel_content}
									</Carousel>
								}
								{!is_carrousel && textContent}	
							</div>}
							{(!this.state.defaultView) && <div className={classes.cardBodyText+" word-break"}>
								{videoContent}</div>}
							{(this.state.defaultView && type==="content_carousel") &&
								<CarouselSection
								imagesProps={images}
								/>
							}

							{feedback && <WrapperFeedback {...feedback} />}
							{ /*(isitrich && !isLastSection) && <div onClick={goNextSection} className={`${classes.iconDownArrowDiv} moveArrow`}>
								<img
									src={arrowdown}
									alt="Go to next section"
									title="Go to next section"									
									className={classes.iconDownArrow}
								/>
						</div>*/}
							</Collapse>
						</CardBody>						
					</Card>
					}
				</Container>
				
			</div>
		);
	}
}

GuideStandardCard.defaultProps = {
	children: (<p>Here comes the content for this Standard Card</p>),
	title: 'Standard Card',
};

export default withTranslation()(withMyHook(GuideStandardCardStyled));