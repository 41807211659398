import {FilterFactory} from "./FilterFactory";
import {Dispatch, SetStateAction} from "react";
import useIdeasFilter from "../../hooks/useIdeasFilter";
import {ResetFilter} from "./BaseFilters/ResetFilter";
import {IdeasAndCommentsFilter} from "./BaseFilters/IdeasAndCommentsFilter";

/**
 *
 * @param type Declare which Filter you intent to filter with (department, module or label)
 * @param filterId  Declare the filter id example: (deptId | moduleId | labelId)
 * @param setSelfId Parsing the setter method for type example: (setCurrentDepartment(filterId) | setSelectedModule(filterId)
 * @param filterData Parse the setter methods, the filter instance needs to manipulate
 */
export const AnalyticsIdeasHandleFilter =<K> ( 
    type: string, filterId: any, setSelfId: Dispatch<SetStateAction<any>>, filterData: K 
    ): void => {
    const filterInstance = FilterFactory.createFilter(type);
    filterOrClear(type, filterInstance, filterId, setSelfId, filterData)
};


const filterOrClear = <T, K> (
    type: string,
    filterInstance: IdeasAndCommentsFilter<T, K> | ResetFilter,
    filterId: any,
    setSelfId : Dispatch<SetStateAction<any>>,
    filterData: K
) => {
    if (filterInstance instanceof ResetFilter) {
        clearAll_FiltersExcept_ResetFilter(filterData);

        const clearFilter = FilterFactory.createFilter(type);
        clearFilter.clear(filterId, filterData);

    } else if (!(filterInstance instanceof ResetFilter)) {
        filterInstance.filter(filterId, setSelfId, filterData);
    }
}

function clearAll_FiltersExcept_ResetFilter<K>(filterData: K) {
    const allFilterTypes = FilterFactory.getFilterTypes();

    for (let filterType in allFilterTypes) {
        const filter = FilterFactory.createFilter(filterType);
        if (!(filter instanceof ResetFilter)) {
            filter.clear(filterData);
        }
    }
}

