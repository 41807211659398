
const guidesStyle = (theme) => ({
  '@global': {
    'img.responsive': {
      maxWidth:'100%',
      display:'block',
      margin:'0 auto',
      cursor: 'pointer'
    },
    '.carrouselImage_isxf': {
      cursor: "pointer"
    },
    '.guide-subheading' : {
      textTransform:'capitalize',
      fontSize:'22px'
    },
    '.twocolumns' : {
        columnCount: 2,
        columnGap: '40px',
        /*textAlign: 'justify',*/
        "&:img": {
            width: "80%",
        },
        "@media only screen and (max-width: 960px)": {
          columnCount: "auto",
        }
    },
    ".paddingExercise": {
      padding: "30px 0",
      "@media only screen and (max-width: 960px)": {
        padding: "30px 0",
      }
    },
    a: {
      color: "#009df0"
    }
  },
  textareaAutoSize: {
	  backgroundColor: "rgba(255,255,255,0.5)", 
	  position: "relative",  
	  borderRadius: "11px", 
	  borderColor: "grey", 
	  resize: "none",
	  width: "100%",
	  padding: "5px",
	  border: "1px solid #ced4da",
	  color: "#495057",
	  "&:focus": {
		  borderColor: "#80bdff",
		  outline: "0",
		  boxShadow: "0 0 0 .2rem rgba(0,123,255,.25)"
	  }
  },
  texttitle: {
	  backgroundColor: "rgba(255,255,255,0.5)", 
	  position: "relative", 
	  borderRadius: "11px", 
	  borderColor: "grey", 
	  resize: "none",
	  width: "100%",
	  padding: "5px"
  }

});

export default guidesStyle;