/*!
* Description: this file contains the component for
*  the type of exercise SimpleChoiceSelectionInfo, ex. guide 4, step 6, 
*  					"Exercise: System Thinking"
* Components: SimpleChoiceSelectionInfo
*/
import React from "react";

// Styles
import withStyles from "@mui/styles/withStyles";
import { standardRadious, ourBlue, ourBlue60, ourGreen40 } from 'assets/jss/incept-sustainability-variables.jsx';
import clsx from 'clsx';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';


//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";


const styles = (theme) => ({
	root: {paddingTop: "20px"},
	card: {
		minHeight: '120px',
		padding: '20px 10px',
		backgroundColor: 'transparent',
		color: '#FFFFFF',
		borderRadius: standardRadious,
		border: '2px solid #FFF',
		[theme.breakpoints.down('lg')]: {
			margin: '1rem 0'
		}
	},
	title: {
		fontWeight: 'bold'
	},
	body: {},
	button: {
		fontSize: '1rem',

		width: '100%',
		borderRadius: '2rem',
		backgroundColor: ourBlue,
		color: '#FFF',
		boxShadow: 'none',
		whiteSpace: 'normal',
		'&:hover': {
			backgroundColor: ourBlue,
			opacity: "0.6",
			boxShadow: 'none',
		},
		'&:active': {
			backgroundColor: ourGreen40
		},
	},
	buttonSelected: {
		backgroundColor: ourBlue60
	},
	buttonWrapper: {},
	buttonsWrapper: {
		padding: '0px 15px'
	},
	imgWrapper: {
		textAlign: 'center',
		padding: '20px',
		backgroundColor: 'white',
		borderRadius: standardRadious,
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('md')]: {
			marginBottom: '1rem'
		}
	},
	img: {
		maxWidth: '150px',
		height: 'auto',
	},
	gridImageItem: {
		padding: '20px 20px 50px'
	},
	gridContainer: {
		paddingTop: '20px'
	}
});

/*
* Component that renders exercise with buttons and displaying info
*/
class SimpleChoiceSelectionInfo extends React.Component {

	state = {
		selectedAnswer: -1
	}

	handleClick(e, index) {
		const answerIndex = parseInt(index);
		this.setState({
			selectedAnswer: answerIndex
		});
	}

	_renderButton(option, index) {
		const { classes } = this.props;
		const { text } = option;
		var extraProps = {};

		if (this.state.selectedAnswer === index) {
			extraProps['className'] = clsx(classes.button, classes.buttonSelected);
		} else {
			extraProps['className'] = classes.button
		}

		return (
			<div className={classes.buttonWrapper} key={index}>
				<ActionButton 
					size="large" 
					{...extraProps} 
					onClick={(e) => this.handleClick(e, index)}
					>
					{text}
				</ActionButton>
			</div>
		);
	}

	_renderButtons(options) {
		const { classes } = this.props;
		const opts = options.map(
			(option, index) => this._renderButton(option, index)
		);

		return (
			<div className={classes.buttonsWrapper}>
				{ opts}
			</div>
		);
	}

	_renderCardInfo(info) {
		const { classes } = this.props;
		const { title, body } = info ? info : {};

		return (
			<div className={classes.card}>
				<div className={classes.title}>
					{title}
				</div>
				<div className={classes.body}>
					{body}
				</div>
			</div>
		);
	}

	_renderCardsAndButtons(answerIndex, options) {
		
		const { left, right } = answerIndex > -1 ? options[answerIndex] : {};

		return (
			<GridContainer justify="center" nonunset={true} style={{paddingTop: "20px"}}>
				<GridItem sm={9} md={7} lg={4}>
					{this._renderCardInfo(left)}
				</GridItem>
				<GridItem sm={9} md={6} lg={4}>
					{this._renderButtons(options)}
				</GridItem>
				<GridItem sm={9} md={7} lg={4}>
					{this._renderCardInfo(right)}
				</GridItem>
			</GridContainer>
		);
	}

	_renderImages(leftImage, mainImage, rightImage) {
		const { classes } = this.props;

		return (
			<GridContainer justify="center">
				<GridItem sm={9} md={4} className={classes.gridImageItem}>
					<div className={classes.imgWrapper}>
						<img alt="" className={classes.img} src={leftImage.imageURL} />
					</div>
				</GridItem>
				<GridItem sm={9} md={4} className={classes.gridImageItem}>
					<div className={classes.imgWrapper}>
						<img alt="" className={classes.img} src={mainImage.imageURL} />
					</div>
				</GridItem>
				<GridItem sm={9} md={4} className={classes.gridImageItem}>
					<div className={classes.imgWrapper}>
						<img alt="" className={classes.img} src={rightImage.imageURL} />
					</div>
				</GridItem>
			</GridContainer>
		);
	}

	render() {
		const { classes, exercise } = this.props;
		const { mainImage, leftImage, rightImage, options } = exercise;

		if (mainImage.imageURL !== null && mainImage.imageURL !== "") {
			return (
				<div className={classes.root}>
					<GridContainer justify="center">
						{this._renderImages(leftImage, mainImage, rightImage)}
					</GridContainer>
					<GridContainer justify="center">
						{this._renderCardsAndButtons(this.state.selectedAnswer, options)}
					</GridContainer>
				</div>
			);
		}
		else {
			return (
				<div className={classes.root}>
					<div className={classes.gridContainer}>
						{this._renderImages(leftImage, mainImage, rightImage)}
					</div>
					<div className={classes.gridContainer}>
						{this._renderCardsAndButtons(this.state.selectedAnswer, options)}
					</div>
				</div>
			);
		}
	}
}

export default withStyles(styles)(SimpleChoiceSelectionInfo);