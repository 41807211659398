import React from "react";
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
//Styles
import withStyles from "@mui/styles/withStyles";
import about from "assets/img/about.svg";

import { withTranslation } from 'react-i18next';

const TextOnlyTooltip = withStyles({
	tooltip: {
		fontSize: "14px"
	}
})(Tooltip);

class General_click_tooltip extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            tooltipOpenID : ''
        }
	}

	handleTooltipClick(_e,tooltipID){
		this.setState({tooltipOpenID:tooltipID});
	}

	handleTooltipClose(_e,tooltipID){
		if (this.state.tooltipOpenID===tooltipID)
			this.setState({tooltipOpenID:""});
	};

	render(){
	const { tooltipid, lengthM, t } = this.props;
	return (
		<ClickAwayListener onClickAway={(e) => this.handleTooltipClose(e,tooltipid)}>
			<TextOnlyTooltip 
			key={this.state.tooltipOpenID}
            placement="top" 
			disableFocusListener
			disableHoverListener
			disableTouchListener
			title={this.props.toltipText}
			open={tooltipid === this.state.tooltipOpenID}
			onClick={(e) => this.handleTooltipClick(e, tooltipid)}
            arrow
			>
				{this.props.children}
			</TextOnlyTooltip>
		</ClickAwayListener>
    );
    }
};

export default withTranslation()(General_click_tooltip);