/*!
 * Description: this file contains a component
 *  that renders a disabled lookalike button that contains numbers or text
 */

import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

import analyticsStyle from "assets/jss/views/analyticsStyle.jsx"; //style for all analytics
import {
  ourBlueLight,
  ourBlue,
  ourOrange,
} from "assets/jss/incept-sustainability-variables.jsx";

import CardAnalytics from "components/CardAnalytics/Card.jsx";
import CardAnalyticsHeader from "components/CardAnalytics/CardHeader.jsx";
import CardAnalyticsBody from "components/CardAnalytics/CardBody.jsx";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import ReactExport from "react-data-export";

import FakeButton from "components/CustomButtons/FakeButton";
import downloaddata from "assets/img/downloaddata.png";
import DropDown from "components/CustomInput/DropDown.jsx";
import MenuItem from "@mui/material/MenuItem";
import ActiveEmployees_tooltip from "components/Tooltip/ActiveEmployees_tooltip";
import ActionButton from "components/CustomButtons/ActionButton";
import { withTranslation, Trans,useTranslation } from 'react-i18next';

import { useMediaQuery } from "react-responsive"; //for mobile responsiveness
let arrayCount = 0;
let lab = "";
let maxCount = [];
const useStyles = (theme) => ({
  smallButton: ({ color, textColor }) => ({
    backgroundColor: color,
    color: textColor,
    border: "0px",
    borderRadius: "20px",
    fontSize: "14px",
    padding: "5px 30px",
    marginBottom: "0.5rem",
  }),
});

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
    return <Component {...props} isMobile={mobile} />;
  };
}

function findArrayElementByEdit(array, iddep) {
  return array.find((element) => {
    return element.id === iddep;
  });
}

function AnalyticsIdeas_Header(props) {
  const { classes, content } = props;
  const [load, setLoad] = useState(false);
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const mainData = [
    {
      columns: [
        { title: "Total ideas" },
        { title: "% of active employees", width: { wpx: 180 } },
      ],
      data: [
        [
          { value: props.nideas.toString() },
          { value: props.nparticipants ? props.nparticipants.toString() : '' },
        ],
      ],
    },
  ];
  const { t, i18n } = useTranslation();

  const ideastoExport = props.ideas2.map((idea, index) => {
    let market = findArrayElementByEdit(props.departments, idea.departmentid);
    const labelArray = [...idea.label.map((Val) => Val.name)];
    arrayCount = labelArray.length;
    // countArray.push(arrayCount);

    // maxCount = Math.max(...Array.from(new Set(countArray)));
    // console.log("jlkajsflkjsalfkjlsa92", arrayCount);
    return [
      { value: idea.dateidea.toString() },
      { value: idea.module !== null ? idea.module : "Dashboard" },
      { value: idea.module !== null ? idea.module : "Dashboard" },
      {
        value: idea.description.toString(),
        style: { alignment: { wrapText: true, vertical: "top" } },
      },
      ...labelArray.map((lab, ind) => {
        return { value: lab };
      }),
    ];
  });
  // const LabelTitle = () => {
  //   let maxCount = [];
  //   console.log("Label TItle", props.ideas2);
  //   const labels = props.ideas2.map((idea, index) => {
  //     const labelArray = [...idea.label.map((Val) => Val.name)];
  //     let arrayCount = labelArray.length;
  //     let countArray = [];
  //     countArray.push(arrayCount);
  //     maxCount = Math.max(...Array.from(new Set(countArray)));
  //     // console.log("maxCount", maxCount);
  //     // if (maxCount <= count) {
  //     //   count++;
  //     //   return { title: "Label", width: { wpx: 500 } };
  //     // }
  //     return maxCount;
  //   });
  //   console.log("Labels", labels);
  //   return labels;
  // };
  // console.log("ideastoExport1", new Set(LabelTitle()));
  // const labelValue = () => {
  //   let count = 0;
  //   let title = {};
  //   const countValue = Math.max(...Array.from(new Set(LabelTitle())));
  //   if (countValue <= count) {
  //     count++;
  //     return { title: "label", width: { wpx: 500 } };
  //   }
  // };
  const ideasData = [
    {
      columns: [
        { title: "Received" },
        { title: "Module", width: { wpx: 370 } },
        { title: "Idea", width: { wpx: 500 } },
        { title: "description", width: { wpx: 500 } },
        { title: "label", width: { wpx: 500 } },
        //labelValue(),
      ],
      data: ideastoExport,
    },
  ];

  /*
    [
        {value: "% of active employees", wpx: 250, style: {width: {wpx: 280},font: {bold: true},alignment: {wrapText: true, horizontal: 'center', vertical: 'top'}}},
        {value: props.nparticipants.toString()}
    ]*/

  var dataSet2 = [
    {
      ideasn: props.nideas,
      percentageAct: props.nparticipants,
      style: { fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } } },
    },
  ];

  const handleChange = (e, statementIndex) => {
    const imageIndex = parseInt(e.target.value);
    this.setState((state) => {
      const sa = state.selectedAnswers;
      sa[statementIndex] = imageIndex;
      //save exercise
      //this.props.save2("DropDownWithImage", sa, this.props.inkey, this.props.exercise_id);
      return { selectedAnswers: sa, submittedanswer: false };
    });
  };

  return (
    <CardAnalytics>
      <CardAnalyticsHeader title={t("General Overview")}>
        <div style={{ marginLeft: "auto", cursor: "pointer" }}>
          <div>
            <ExcelFile
              filename={"ideas_and_comments"}
              element={
                <img className="responsive2" src={downloaddata} alt="" />
              }
            >
              <ExcelSheet dataSet={mainData} name="General Data" />
              <ExcelSheet dataSet={ideasData} name="Ideas" />
            </ExcelFile>
          </div>
        </div>
      </CardAnalyticsHeader>
      <CardAnalyticsBody>
        <GridContainer space={false}>
          <GridItem
            otherclasses={classes.noLeftPadding}
            style={props.isMobile ? { marginTop: "0" } : {}}
          >
            <GridContainer justifyContent="center" alignItems="center">
              <GridItem
                otherclasses={classes.noLeftPadding}
                style={props.isMobile ? { marginTop: "0" } : {}}
              >
                <TableContainer className={classes.standardTable}>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow key={"IdeasAndComments"}>
                        <TableCell component="th" scope="row">
                          {t("Ideas and comments")+":"}
                        </TableCell>
                        <TableCell
                          align={props.isMobile ? "right" : "left"}
                          style={{ paddingLeft: "0" }}
                        >
                          <FakeButton
                            color={ourBlueLight}
                            textColor={ourBlue}
                            style={{ width: "80px" }}
                            content={props.nideas}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </GridItem>
              <GridItem
                otherclasses={classes.noLeftPadding}
                style={
                  props.isMobile ? { marginTop: "0" } : { marginLeft: "50px" }
                }
              >
                <TableContainer className={classes.standardTable}>
                  <Table aria-label="simple table">
                    <TableBody>
                      {!props.isArchived && props.showactive && (
                        <TableRow key={"IdeasAndComments"}>
                          <TableCell component="th" scope="row">
                            {"% of"}{" "}
                            <ActiveEmployees_tooltip tooltipid={"someid"} />
                            {t("that submitted ideas")+":"}
                          </TableCell>
                          <TableCell
                            align={props.isMobile ? "right" : "left"}
                            style={{ paddingLeft: "0" }}
                          >
                            <FakeButton
                              color={ourBlueLight}
                              textColor={ourBlue}
                              style={{ width: "80px" }}
                              content={props.nparticipants + "%"}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </GridItem>
              {/*<GridItem xs={12} sm={12} md={12} lg={4} otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"16px"}:{}}>
					<div style={{minWidth:"200px"}} align={props.isMobile ? "left":"right"}>
						<DropDown
							value={props.currentDepartment}
							analytics={true}
							paddingShort={true}
							fullWidth={false}
							onChange={(e) => props.onChange(e,"department")}
						>
							<MenuItem key={0} value={0}>{"All"}</MenuItem>
							{props.departments.map(
								(item) => <MenuItem key={item.id} value={parseInt(item.id)}>{item.name}</MenuItem>
							)}
						</DropDown>
					</div>
							</GridItem>*/}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </CardAnalyticsBody>
    </CardAnalytics>
  );
}

export default withMyHook(
  withStyles(
    (theme) => ({
      ...analyticsStyle(theme),
      ...useStyles(theme),
    }),
    { withTheme: true }
  )(AnalyticsIdeas_Header)
);
