/*!
 * Description: this file contains a component
 *  that renders a disabled lookalike button that contains numbers or text
 */

import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";

import analyticsStyle from "assets/jss/views/analyticsStyle.jsx"; //style for all analytics
import {
  ourGreen20,
  ourGreen,
  ourOrange,
} from "assets/jss/incept-sustainability-variables.jsx";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import Checkbox from "@mui/material/Checkbox";
import CardAnalytics from "components/CardAnalytics/Card.jsx";
import CardAnalyticsHeader from "components/CardAnalytics/CardHeader.jsx";
import CardAnalyticsBody from "components/CardAnalytics/CardBody.jsx";
import ResponsivePie from "components/Nivo/ResponsivePie.jsx";
import ProgressBar from "react-bootstrap/ProgressBar";
import newtab from "assets/img/newtab.png";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import { Link } from "react-router-dom";
import ProgressButton from "components/CustomButtons/Progressbutton.jsx";
import CustomBox from "components/CustomLayout/CustomBox.jsx";
import { useMediaQuery } from "react-responsive"; //for mobile responsiveness
import moreInfo from "assets/img/moduleIcon.svg";
import Tooltip from "@mui/material/Tooltip";
import ResponsiveScatterPlot from "components/Nivo/ScatterPlot.jsx";
import ReactHtmlParser from "react-html-parser";

const useStyles = (theme) => ({
	boxdiv: {
        alignItems:"center",
        display:"flex"
    },
    box: {
        height:"20px",
		width:"20px",
        marginRight: "10px",
        display: "inline-block",
        verticalAlign: "middle"
    }
});

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
    return <Component {...props} isMobile={mobile} />;
  };
}

const TextOnlyTooltip = withStyles({
  tooltip: {
    fontSize: "14px",
  },
})(Tooltip);

function ScatterChartCard(props) {
  const { classes, content } = props;

  const contenst = props.data;

  //need array with key area => color

  let lbel_formatted = (label) => (label.length > 15 && `${label}`.substring(0, 11) + "...") || label;

  const annotations_formated = props.annotations.map((obj, index) => {	
	return {
	  ...obj,
	  note: lbel_formatted(obj.note)
	};
  });

  const boxesLegends = props.boxes.slice(0).reverse().map((obj,index) => {
	return (
		<GridItem xs={3} sm={3} md={3} key={obj.id}>
			<div className={classes.boxdiv}>
				<span><b>{obj.id+" -"}</b></span>
				<span style={{flex: "1 1",marginLeft:"5px"}}>{" "+obj.title}</span>
			</div>
		</GridItem>);
  });

  //iterate colors array and create legend
  const legends = Object.keys(props.colors).map(key => {
	return (
		<GridItem xs={3} sm={3} md={3} key={props.colors[key]}>
			<div className={classes.boxdiv}>
				<div className={classes.box} style={{backgroundColor: props.colors[key]}}></div>
				<span style={{flex: "1 1"}}>{key}</span>
			</div>
		</GridItem>);
  });

  return (
    <CardAnalytics style={{marginBottom:"50px"}}>
      <CardAnalyticsHeader title={props.title}>
      </CardAnalyticsHeader>
      <CardAnalyticsBody>
		{props.children}
		<GridContainer key={"boxContainer"} style={{margin: "50px 0 0px 40px"}}>    
			{boxesLegends}
		</GridContainer>
		<div
            className={classes.responsivePie}
            style={{ height: "500px", width: "100%", position: "relative" }}
          >
        <ResponsiveScatterPlot 
		data={contenst} 
		keydata={annotations_formated} 
		colors={props.colors}
		legendX={props.legends[1]} 
		legendY={props.legends[0]}/>
		</div>
		{/*Custom LegendsÇ*/}
		<GridContainer key={"legendcontainer"} style={{margin: "30px 0 50px 40px"}}>    
			{legends}
		</GridContainer>
      </CardAnalyticsBody>
    </CardAnalytics>
  );
}
export default withMyHook(
  withStyles(
    (theme) => ({
      ...analyticsStyle(theme),
      ...useStyles(theme),
    }),
    { withTheme: true }
  )(ScatterChartCard)
);
