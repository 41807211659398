/*!
* Description: this file contains the component for
*  the pagination arrows
* Components: PaginationArrows
*/
import React from 'react';
import withStyles from "@mui/styles/withStyles";
import Icon from "@mui/material/Icon";

import { Link as RouterLink } from "react-router-dom";
import Link from '@mui/material/Link';

import clsx from 'clsx';
import {  ourOrange } from 'assets/jss/incept-sustainability-variables.jsx';
import endmodule from 'assets/img/endmodule.png';

const styles = {
  root: {
  },
  link:{
    display: "flex",
    justifyContent: "center",
    /*height: "100%",*/
    width: "100%",
  },
  iconFinish: {
	padding: "3px",
	height: "100%",
	width: "100%"
  },
  /*hide:{
	  display:"none"
  },*/
  iconNav:{
	display: "flex",
    /*height: "100%",*/
    /*width: "100%",*/
  	fontSize:'30px',
	  color: "white",
	  /*"@media only screen and (max-width: 960px)": {
		fontSize: "5.2vh",
	  },
	  "@media only screen and (max-width: 660px)": {
		fontSize: "5vh"
	  },
	  "@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
		  fontSize: "4vh"
	  },*/
  },
  noclick: {
	  color: "transparent",
  }
};

/*
* Description: this file contains the component for
*  the pagination arrows
*/
class PaginationArrows extends React.Component {

	constructor(props) {
		super(props);
		const [t, current, total] = [this.props.type, parseInt(this.props.currentPage), 
			parseInt(this.props.numPages)];

		this.state = {
			endPage: this._arrowHide(t,current,total),
			toPage : this._toPage(t,current,total),
		};
	}

	//Only for initialization, to hide arrows in border cases
	_arrowHide(type,current,total){
		if (type === 'back'){
			if (current > 1){
				return 0;
			}else{
				return -1;
			}
		}

		if (type === 'forth'){
			if (current < total){
				return 0;
			}else{
				return total+1;
			}
		}
	}

	//return page to go and also hide arrows if it's border case
	_toPage(type,current,total){

		if (type === 'back'){
			if (current > 1){
				return current - 1;
			}else{
				return 1;
			}
		}

		if (type === 'forth'){
			if (current < total){
				return current + 1;
			}else{
				return total;
			}
		}
	}

	//Icon for the arrows
	_decideIconDirection(type){

		if (type === 'back'){
			return 'keyboard_arrow_left';
		}
		return 'keyboard_arrow_right';
	}

	componentDidUpdate(prevProps){

		const [t, current, total, prevCurrent] = [this.props.type, parseInt(this.props.currentPage), parseInt(this.props.numPages), parseInt(prevProps.currentPage)];

		if (current !== prevCurrent){
			this.setState({
				toPage: this._toPage(t,current,total),
				endPage: this._arrowHide(t,current,total),
			})
		}
	}

	render() {
		const { classes, numPages, basePath, type, onClick } = this.props;

		const decideClassicon = ((this.state.endPage === -1 || this.state.endPage===numPages+1) ? clsx(classes.iconNav,classes.noclick) :classes.iconNav);
		
		var iconDir = this._decideIconDirection(type);
		if (this.state.endPage===numPages+1)
			iconDir = <img
							src={endmodule}
							title="End module"
							alt="end module"
							className={classes.iconFinish}
						/>;

		//const topath = (currenthis.state.toPagetPage<1) ? '#':`${basePath}/${this.state.toPage}`;

		return (
			<Link className={classes.link} 
			component={RouterLink}
			 to={(this.props.iskb) ? `${basePath}/${this.state.toPage}/${this.props.typemode}/`:`${basePath}/${this.state.toPage}/act/`} 
			 onClick={onClick}>
				<Icon className={decideClassicon}>{iconDir}</Icon>
			</Link>
		);
	}
}

export default withStyles(styles)(PaginationArrows);