import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @mui/material components
import withStyles from "@mui/styles/withStyles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
// @mui/icons-material
import Clear from "@mui/icons-material/Clear";
import Check from "@mui/icons-material/Check";
import InputAdornment from '@mui/material/InputAdornment';
// core components
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";

function CustomInput({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    img="",
    value,
    onChange,
    onBlur,
    classreq,
    success,
	onKeyDown=false,
	isSearch=false,
	customMarginTop="",
	...other
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });
  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
	  style={customMarginTop!=="" ? {marginTop:customMarginTop}:{}}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        classes={{
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        className={classreq}
        id={id}
        {...inputProps}
		style={customMarginTop!=="" ? {marginTop:"0"}:{}}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
		onKeyDown={(e) => {
			if (e.key  === "Enter") {
			  e.preventDefault();
			  onKeyDown();
			}
		  }}
		endAdornment={
			isSearch && <InputAdornment position="start">
			  <img src={img} style={{width:"20px"}} alt="search"/>
			</InputAdornment>
		  }
		{...other}
      />
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool
};

export default withStyles(customInputStyle)(CustomInput);
