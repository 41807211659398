/**
 * A reusable input component that renders an input element with customizable styles and error message.
 * @param {string} type - The type of input element.
 * @param {string} name - The name of the input element.
 * @param {string} value - The value of the input element.
 * @param {string} borderRadius - The border radius of the input element.
 * @param {string} height - The height of the input element.
 * @param {string} marginBottom - The margin bottom of the input element.
 * @param {string} padding - The padding of the input element.
 * @param {function} onChange - The function to be called when the input element value changes.
 * @param {string} error - The error message to be displayed when the input element value is invalid.
 */
import PropTypes from 'prop-types';



const InputForm = ({ type, name, value, borderRadius, height, width, marginBottom, padding, onChange, error,...other }) => {

    const style = {
        input: {
            padding: padding ? padding : "10px 10px",
            borderRadius: borderRadius ? borderRadius : "25px",
            height: height ? height : "3rem",
            width: width ? width : "100%",
            border: "1px solid gray",
            marginBottom: marginBottom ? marginBottom : "1rem",
        },
    };

    return (
        <>
            <input
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                style={style.input}
				{...other}
            />
            {error && <div className="alert alert-danger">{error}</div>}
        </>
    );
}

InputForm.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    borderRadius: PropTypes.string,
    height: PropTypes.string,
    marginBottom: PropTypes.string,
    padding: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.string
};


export default InputForm;