abstract class ResetFilter {
    abstract clear(type:string | number, filterDataForClear: any): void

    // Factory Method --- This acts as a guideline, each subclass implements their own version of creation.
    // @ts-ignore
    public static createFilter(): ResetFilter {
        throw new Error("This method should be overridden by subclass");
    }

    public static getClassName = (): string => {
        throw new Error("This method should be overridden by subclass");
    }
}

export { ResetFilter }
