/*!
* Description: this file contains a component
*  that renders a disabled lookalike button that contains numbers or text
*/

import React, { useState } from 'react';
import withStyles from "@mui/styles/withStyles";
//style for all analytics
import { ourGreen20,ourGreen,ourOrange,standardRadious } from "assets/jss/incept-sustainability-variables.jsx";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import FakeButton from "components/CustomButtons/FakeButton";
import { useMediaQuery } from 'react-responsive'; //for mobile responsiveness
import { blackColor } from 'assets/jss/material-dashboard-react';
import CustomCheckbox from "components/CustomInput/CustomCheckbox.jsx";

const useStyles = (theme) => ({
	smallButton: ({color, textColor}) => ({
		backgroundColor: color,
		color: textColor,
		border: "0px",
		borderRadius: "20px",
		fontSize: "14px",
		padding: "5px 30px",
		marginBottom: "0.5rem",
	}),
    textCard: {
        backgroundColor: ourGreen20,
        borderRadius: standardRadious,
        padding: "10px 30px 30px 30px",
        minHeight: "200px"
    },
    commentsContainer: {
        display: "flex", 
        marginBottom: "1rem",
        "& p": {
            cursor: "pointer",
        } 
    },
    ellipsistext: {
        "& p": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            "@supports (-webkit-line-clamp: 4)": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "initial",
            display: "-webkit-box",
            WebkitLineClamp: "4",
            WebkitBoxOrient: "vertical"
            }
        },
    },
    quoteText: {
        color: blackColor,
        "&:before": {
            fontSize: "1.5rem",
            lineHeight: "0.5rem",
        },
        "&:after": {
            fontSize: "1.5rem",
            lineHeight: "0.5rem",
        },
    }
});


function withMyHook(Component) {
    return function WrappedComponent(props) {
      const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
      return <Component {...props} isMobile={mobile} />;
    }
}

function CustomBox(props) {
	const {classes, exerciseid, index, content} = props;
    const [seemore1, setSeemore1] = useState(false);

    const seemoreF = (e,id) => {
		try {
			e.preventDefault();
             setSeemore1(!seemore1);            
		}
		catch(error) {
			throw new Error(error);
		}
	};

	return (
        <div className={classes.textCard} style={{width:"100%",position:"relative",textAlign: "right"}}>
			{!props.generalPage && !props.isexported && <CustomCheckbox
				checked={props.checked}
				onChange={() => props.handleChangeCheckbox(props.id,props.index)}
			/>}
			{!props.generalPage && props.isexported && <FakeButton
				color={ourGreen}
				textColor={"rgba(0, 0, 0, 0.87)"}
				style={{
					fontWeight: "200",
					padding: "2px 20px 2px 20px",
					marginLeft: "10px",
					marginBottom: "5px",
					marginTop: "10px"
				}}
				disabledb={false}
				content={"Exported"}
				labelIconCheck={true}
			/>}
            <div className={(seemore1 ? classes.commentsContainer:classNames(classes.ellipsistext, classes.commentsContainer))} 
            style={{ display: "flex" }}>				
                <Typography
                        component="p"
                        variant="body1"
                        style={{ textAlign: "justify",color:ourOrange,paddingTop:"10px" }}
                        onClick={(e) => seemoreF(e,seemore1)}
                    >
                        <span className={classes.quoteText}>{content}</span>
                </Typography>
            </div>    
        </div>     
	);
}
export default
withMyHook(withStyles(
    (theme) => ({
      ...useStyles(theme),
    }),
    { withTheme: true },
  )(CustomBox));
