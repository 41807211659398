import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @mui/material components
import withStyles from "@mui/styles/withStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Hidden from "@mui/material/Hidden";
// @mui/icons-material
import Menu from "@mui/icons-material/Menu";
import archive from "assets/img/archive.png";
// core components
import Button from "components/CustomButtons/Button.jsx";

import headerStyle from "assets/jss/material-dashboard-react/components/headerStyle.jsx";
import gobackimg from "assets/img/return.png";
import Tooltip from "@mui/material/Tooltip";
import {Link } from "react-router-dom";


function Header({ ...props }) {
	var goback = false;
	var ideaspage = false;
	function gobackpage(props) {
		props.history.goBack();
	}
	const { classes, color } = props;
	const appBarClasses = classNames({
		[" " + classes[color]]: color
	});
	const TextOnlyTooltip = withStyles({
		tooltip: {
		  fontSize: "14px",
		},
	  })(Tooltip);
	props.routes.map(prop => {
		if (!window.location.href.includes("analytics_ideas") &&
		(window.location.href.indexOf(prop.layout + prop.path) !== -1 || 
		window.location.href.includes("custom_table"))) {
			if (prop.subpage) {
				goback = true;
			}
		}
		if (window.location.href.indexOf(prop.layout + prop.path) !== -1 && window.location.href.includes("analytics_ideas") 
		&& prop.subpage) {
			goback = true;
		}
		if (window.location.href.indexOf(prop.layout + prop.path) !== -1 && window.location.href.includes("analytics_ideas") && !window.location.href.includes("analytics_ideas/:archived") 
		&& !prop.subpage) {
			ideaspage = true;
		}
		return "";
	});
	return (
        <AppBar className={classes.appBar + appBarClasses}>
			<Toolbar className={classes.container}>
				<div className={classes.flex}>
					{/* Here we create navbar brand, based on route name */}
					{goback ? <Button onClick={(e) => gobackpage(props)} style={{ backgroundColor: "transparent", boxShadow: "none", padding: "0" ,margin:"40px 0 0 30px"}}>
						<img alt="" src={gobackimg} width="30" /></Button> : ""}
					{props.iscustomManager && props.customManager!==null && 
						<div style={{height:"30px",textAlign:"right",fontSize:"12px"}}>
							{"This is user is custom manager for "}
							<b>{props.customManager.department_filter+" "}
							{props.customManager.country_filter}</b>
						</div>
					}
					{ideaspage && <div
						style={{height:"30px",cursor:"pointer", textAlign:"right"}}>
						<Link to={"/admin/analytics_ideas/:archived"}>
						<TextOnlyTooltip title={"Go to Archived Ideas and comments"} arrow>
						<img
								src={archive}
								alt="archive icon"
								style={{ width: "25px", height: "25px" }}
							/>
						</TextOnlyTooltip>
						</Link>
					</div>}
					{/* <Button color="transparent" href="#" className={classes.title}>
            {makeBrand()}
          </Button> */}
				</div>
				<Hidden mdUp implementation="css">
					<IconButton
                        color="inherit"
                        classes={{root:classes.menuMobile}}
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}
                        size="large">
						<Menu />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
    );
}

Header.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
	rtlActive: PropTypes.bool,
	handleDrawerToggle: PropTypes.func,
	routes: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(headerStyle)(Header);
