/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import withStyles from "@mui/styles/withStyles";
import { isAuthenticated, mgfile_st } from "assets/jss/incept-sustainability-variables.jsx";
import axios from "axios";
import jwt from "jwt-decode";
import GridContainer from "components/Grid/GridContainer.jsx";

//Translations
import { withTranslation } from 'react-i18next';
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import ActionButton from "components/CustomButtons/ActionButton";
import { BrowserRouter as Router, Redirect, Link } from "react-router-dom";

const style = (theme) => ({
    container: {
        margin: "50px",
        backgroundColor: "white",
        textAlign: "center",
        width: "100%",
        padding: "50px",
        borderRadius: "20px"
    }
});

class UploadFile extends React.Component {
	constructor(props) {
		super(props);
		var redlog = false;
		var decodedtoken = "";
		if (!isAuthenticated()) {
			redlog = true;
		}
		else
			decodedtoken = jwt(localStorage.getItem("token"));
		this.state = {
			loading: false,
            redirect: redlog,
			userid: (decodedtoken!="") ? decodedtoken.userid:"",
            selectedFile: null,
            isFilePicked: false,
            inputKey: 1,
            manager: //Determines if it is a manager or a employee
                decodedtoken.role.includes("Manager") || decodedtoken.role == "Admin"
					? true
					: false,
		};
	}

    /*logout*/
	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect to="/login" />;
		}
	};

    changeHandler = (event) => {
        let file = event.target.files[0];
        var fileExtension = file.name.split('.').pop();
        if (fileExtension!=="csv") {
            alert("File must be a spreadsheet. Type csv.");
            return;
        }
        this.setState({ selectedFile: event.target.files[0], isFilePicked: true });
	};

    handleSubmission = () => {
        let file = this.state.selectedFile;
        if (!isAuthenticated()) {
			this.props.isExpired();
		}
        if (file===null) {
            alert("File must be a spreadsheet. Type csv.");
            return;
        }

        this.setState({loading:false,inputKey:1});
        document.getElementById("thankyoudiv").style.display = "none";
        document.getElementById("errordiv").style.display = "none";

		const user_id = this.state.userid;
		this.setState({ loading: true });
		/*const formdata = {
			fileUp: file,
            filename: this.state.selectedFile.name,
		};*/
        const formData = new FormData();        
        formData.append('fileUp',this.state.selectedFile);

		const token = localStorage.getItem("token");
		const authstr = "Bearer " + token;

		this.setState({ loading: true }, () => {
			axios
				.post(mgfile_st, formData, { headers: { Authorization: authstr } })
				.then((res) => {
                    this.setState({loading:false,inputKey:0});
                    document.getElementById("thankyoudiv").style.display = "block";
				})
				.catch((err) => {
					if (err.response!==undefined)
						console.log(err.response.data.message);
					else 
						console.log(err);
					this.setState({ loading: false });
                    this.setState({loading:false,inputKey:0});
                    document.getElementById("errordiv").style.display = "block";
				});
		});
	};

	
	render() {
		const { classes, t } = this.props;
        if (this.state.manager) {
            return (
                <LoadingOverlay active={this.state.loading} spinner={<BounceLoader />}>
                    <GridContainer>
                    {this.renderRedirect()}
                        <div className={classes.container}>
                            <p>Upload here the spreadsheet with the employees info:</p>
                            <input type="file" name="file" accept=".csv" key={this.state.inputKey} onChange={this.changeHandler} />
                            <div
                                id="thankyoudiv"
                                style={{
                                    color: "green",
                                    marginBottom: "20px",
                                    marginTop:"20px",
                                    display: "none",
                                }}
                            >
                                {t('Thank you. Your file is submitted.', { defaultValue: 'Thank you. Your file is submitted. We will soon onboard the employees from your company,' })}
                                <div><Link to="/">Go to Dashboard {"->"}</Link></div>
                            </div>
                            <div
                                id="errordiv"
                                style={{
                                    color: "red",
                                    marginBottom: "20px",
                                    marginTop:"20px",
                                    display: "none",
                                }}
                            >
                                {t('There was an error2', {
                                    defaultValue: 'There was an error, please try again or contact us at inceptsustainability@ramboll.com.'
                                })}
                            </div>
                            <div>
                                <ActionButton
                                    size="auto"
                                    onClick={this.handleSubmission}
                                >
                                    {t('Submit')}
                                </ActionButton>
                            </div>
                        </div>
                    </GridContainer>
                </LoadingOverlay>
            );
        }
        else
            return (
                <div>
                        <div className={classes.container}>
                        {this.renderRedirect()}
                            No permission to access this page.
                            <div><Link to="/">Go to Dashboard {"->"}</Link></div>
                        </div>
                </div>
            )
	}
}

UploadFile.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(style)(UploadFile));
