import React from "react";

import axios from "axios";

import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import clientConfig from "../client-config";
import withStyles from "@mui/styles/withStyles";
import loginStyle from "assets/jss/material-dashboard-react/views/loginStyle.jsx";

import Button from "components/CustomButtons/Button.jsx";
import { Redirect } from "react-router-dom";

import { Form, FormGroup, Label } from "reactstrap";
import { default as CustomModal } from "components/Modal/Modal.jsx";
import jwt from "jwt-decode";

import loadinggif from "assets/img/loading.gif";
import incept_logo from "assets/img/incept_logo.svg";
import rambolllogo from "assets/img/Ramboll_Logo.svg-1.png";
import rambolllogoWhite from "assets/img/Ramboll_LogoWhite.png";
import {
  ourDarkGreen,
  ourOrange,
  ourOrange60,
  reset_password,
  stagesite,
} from "assets/jss/incept-sustainability-variables.jsx";
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import { TokenContext, UseSharedTokenInfo } from "context/auth_context/TokenContext.jsx";

const pStyle = {
  input: {
    borderRadius: "2rem",
    height: "2.5rem",
    border: "1px solid gray",
    marginBottom: "0.8rem",
  },
  button: {
    width: "70%",
    height: "40px",
    backgroundColor: ourOrange,
    color: "white",
    border: "1px",
    borderRadius: "20px",
    cursor: "pointer",
    fontSize: "15px",
    padding: "2px 8px",
	fontWeight: "bold",
    display: "block",
    position: "inherit",
    margin: "auto",
    "&:hover": {
      backgroundColor: ourOrange60,
    },
  },
  button2: {
    width: "70%",
    margin: "auto",
	marginBottom: "3rem",
    height: "40px",
    backgroundColor: "#009de0",
    color: "white",
    border: "1px",
    borderRadius: "20px",
    cursor: "pointer",
    fontSize: "15px",
    padding: "2px 8px",
    display: "block",
    position: "inherit",
    "&:hover": {
      backgroundColor: ourOrange60,
    },
  },
  linkPointer: {
    cursor: "pointer",
  }
};
class LoginNew extends React.Component {
  static contextType = TokenContext;
  constructor(props) {
    super(props);
    let params = props.match.params;
    let is_logged = false;
    let is_loading = false;
    let is_role = "";

    //localStorage.setItem("token", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2lzd3AuaW5jZXB0c3VzdGFpbmFiaWxpdHkuY29tIiwiaWF0IjoxNjUwNDQyMDAyLCJuYmYiOjE2NTA0NDIwMDIsImV4cCI6MTY1MTA0NjgwMiwiZGF0YSI6eyJ1c2VyIjp7ImlkIjoiOSJ9fSwiY29tcGFueSI6InlvdXIgQ29tcGFueSIsInJvbGUiOiJFbXBsb3llZSIsInVzZXJpZCI6OX0.kJn54js3yr0wqFmKrMY1o7MTwb1cOiNsaOaRtjyz8ZU");

    //Check if it is already logged in
    if (localStorage.getItem("token") !== null) {
      is_logged = true;
    } else {
      const authResult = new URLSearchParams(window.location.search);
      const token = authResult.get("jwt_token");

      if (token !== null) {
        localStorage.setItem("token", token);
        //save info
        is_loading = false;

        const decodedtoken = jwt(token);
        localStorage.setItem("is_compside", JSON.stringify(decodedtoken.sidebarInfo));
        is_role = decodedtoken.role;
        is_logged = true;
        const redir = authResult.get("redbck");
        if (redir !== "") {
          window.location.assign(redir);
        }
      }
    }

    this.state = {
      username: "",
      password: "",
      userEmail: "",
      currentsite: window.location.host,
      loggedInM: false,
      loggedInE: false,
      loggedin: is_logged,
      loading: is_loading,
      email: "",
      forgotpassword: false,
      role: is_role,
      missEmail: false,
      sendingmail: false,
      afterMailError: false,
      afterMail: false,
      afterMailMessage: "",
      error: "",
      invalidkey: params.invalidkey,
      acceptTerms: false,
      termsAccepted: true,
    };

    this.forgotPassowordCloseModal = this.forgotPassowordCloseModal.bind(this);
    this.acceptTermsCloseModal = this.acceptTermsCloseModal.bind(this);
  }

  handleOnChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    document.getElementById("errorlogin").style.display = "none";
  };

  clickenter = (event) => {
    if (event.keyCode === 13) {
      this.handleClick(event);
    }
  };

  handleClick = (event) => {
    const siteUrl = clientConfig.siteUrl;

    const loginData = {
      username: this.state.username,
      password: this.state.password,
    };

    //setState(updater[, callback])
    this.setState({ loading: true }, () => {
      document.getElementById("loadinggd").style.display = "block";
      axios
        //.post(`${siteUrl}wp-json/jwt-auth/v1/token`, loginData)
        .post(`${siteUrl}wp-json/jwt-auth/v1/token`, loginData)
        .then((res) => {
          if (undefined === res.data.token) {
            this.setState({ error: res.data.message, loading: false });
            return;
          }
          //get info from api
          const { token, user_nicename, user_email, sidebarInfo } = res.data;

          localStorage.setItem("token", token);
          //localStorage.setItem("is_compside", JSON.stringify(sidebarInfo));
          this.context.updateToken(token);

          //localStorage.setItem("userName", user_nicename);

          //save info
          this.setState({
            loading: false,
            token: token,
            userEmail: user_email,
          });

          const decodedtoken = jwt(token);
          localStorage.setItem("is_compside", JSON.stringify(decodedtoken.sidebarInfo));
          this.setState({ role: decodedtoken.role });
          this.setState({ loggedin: true });

        })
        .catch((err) => {
          console.log(`${siteUrl}wp-json/jwt-auth/v1/token`);
          console.log(err);
          document.getElementById("errorlogin").style.display = "block";
          document.getElementById("loadinggd").style.display = "none";

          //this.setState( { error: err.response.data.message, loading: false } );
        });
    });
  };

  sendemail_password() {
    const insdata = {
      email: this.state.email,
    };
    //post
    this.setState({ loading: true }, () => {
      axios
        .post(reset_password, insdata)
        .then((response) => {
          this.setState({
            afterMail: true,
            afterMailMessage: "Email sent!",
            sendingmail: false,
            afterMailError: false,
            invalidkey: "",
          });
          document.getElementById("loadinggd2").style.display = "none";
        })
        .catch((err) => {
          if (err.response !== undefined)
            this.setState({
              afterMail: true,
              afterMailMessage: err.response.data.message,
              sendingmail: false,
              afterMailError: true,
              invalidkey: "",
            });
          else
            this.setState({
              afterMail: true,
              afterMailMessage: "Error",
              sendingmail: false,
              afterMailError: true,
              invalidkey: "",
            });
          document.getElementById("loadinggd2").style.display = "none";
        });
    });
  }

  resetPasswordEmail = (event) => {
    this.setState({
      email: event.target.value,
      missEmail: false,
      afterMail: false,
    });
  };

  forgotPassowordModal() {
    this.setState({ forgotpassword: true });
  }

  forgotPassowordCloseModal() {
    if (this.state.sendingmail) return;
    this.setState({ forgotpassword: false, afterMail: false, email: "" });
  }

  resetPassword(e) {
    //Check if mail is empty
    if (this.state.email === "") {
      this.setState({ missEmail: true });
      return;
    }
    document.getElementById("loadinggd2").style.display = "block";
    this.setState({ sendingmail: true });
    this.sendemail_password();
  }

  // Terms and Conditions Modal
  acceptTermsModal() {
    if (this.state.acceptTerms === "") return;
    this.setState({ acceptTerms: true });
  }

  acceptTermsCloseModal() {
    this.setState({ acceptTerms: false, acceptTermsError: false });
  }

  render() {
    const { classes } = this.props;
    const { username, password, email, loggedin } = this.state;

    if (loggedin) {
      return <Redirect to={`/admin/`} noThrow />;
    } else {
      return (
                    <GridContainer style={{ height: "100vh" }}>
                      <div className={classes.gridItem}>
                        <Card className={classes.card} style={{padding:"40px 20px"}}>
                  <CardHeader
                  style={{ padding: "0" }}
                  color="primary"
                  className="text-center"
                  >
                  <div>
                    <img
                    width="35%"
                    height="100%"
                    src={incept_logo}
                    alt="incept logo"
                    />
                  </div>
                  </CardHeader>
                  <CardBody style={{ boder: "1px solid yellow", paddingTop: "20px" }}>
                    <FormGroup>
                                <Label className={classes.label} for="username">
                                  E-mail/Username
                                </Label>
                                <input
                                  style={pStyle.input}
                                  type="text"
                                  className="form-control"
                                  name="username"
                                  value={username}
                                  onChange={this.handleOnChange}
                                />
                                {/* </FormGroup>
                                          <FormGroup> */}
                                <Label className={classes.label} for="password">
                                  Password
                                </Label>
                                <input
                                  style={pStyle.input}
                                  type="password"
                                  className="form-control"
                                  name="password"
                                  value={password}
                                  onKeyDown={(event) => this.clickenter(event)}
                                  onChange={this.handleOnChange}
                                />
                            </FormGroup>
                    <div
                      id="errorlogin"
                      style={{
                      color: "red",
                      marginBottom: "20px",
                      display: "none",
                      }}
                    >
                      Wrong user or password
                    </div>
                    <div id="loadinggd" style={{ display: "none" }}>
                      <img
                      className="responsive"
                      src={loadinggif}
                      width="25"
                      alt=""
                      style={{ marginBottom: "20px" }}
                      />
                    </div>
                    <a
                      onClick={(e) => this.forgotPassowordModal()}
                      style={pStyle.linkPointer}
                    >
                      <p
                      style={{
                        textAlign: "center",
                        color: ourDarkGreen,
                        fontWeight: "bold",
                      }}
                      >
                      Forgot your password?
                      </p>
                    </a>
                      {/*<div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  padding: "5% 0 10% 0",
                                }}
                              >
                                <a
                                  onClick={(e) => this.acceptTermsModal()}
                                  style={pStyle.linkPointer}
                                >
                                  <p
                                    style={{
                                      textDecoration: "underline",
                                      margin: 0,
                                      color: ourDarkGreen,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    I accept the Terms and Conditions
                                  </p>
                                </a>
                                <input
                                  id="acceptCheckbox"
                                  type="checkbox"
                                  style={{
                                    transform: "scale(2)",
                                    filter: "hue-rotate(-30deg)",
                                  }}
                                  onChange={() => {
                                    this.setState({
                                      termsAccepted: !this.state.termsAccepted,
                                    });
                                  }}
                                />
                      </div>*/}

                    {this.state.invalidkey === ":invalidkey" && (
                    <p style={{ color: "red", fontWeight: "bold" }}>
                      Activation link is no longer valid, click on "Forgot your
                      password" to create your password.
                    </p>
                    )}
                    <Button
                    style={pStyle.button}
                    color="info"
                    onClick={(event) => this.handleClick(event)}
                    id="loginbutton"
                    disabled={!this.state.termsAccepted}
                    >
                    Login
                    </Button>
                    <hr></hr>
                    <div style={{textAlign:"center"}}>
                      <p><b>Or continue with:</b></p>
                      <Form style={pStyle}>
                        <Button
                        style={pStyle.button2}
                        color="info"
                        onClick={(event) =>
                          (window.location.href = "/Login?fr=rmbacd")
                        }
                        >
                        <span style={{ whiteSpace: "initial" }}>
                          <img
                          src={rambolllogoWhite}
                          className="jss593"
                          width="120"
                          height="25"
                          style={{
                            margin: "0 5px 2px 5px",
                            marginRight: "5px",
                          }}
                          ></img>
                        </span>
                        </Button>
                      </Form>
                    </div>
                        </CardBody>
                        </Card>
                      </div>

                      {/* This modal is for forgot password */}
                      <CustomModal
                        show={this.state.forgotpassword}
                        onHide={this.forgotPassowordCloseModal}
                        backdrop={true}
                      >
                        <div style={{ minHeight: "100px" }}>
                          <Form style={pStyle}>
                            <FormGroup>
                              <Label className={classes.label} for="username">
                                Please enter your e-mail address and you will receive an
                                e-mail to reset your password:
                              </Label>
                              <input
                                style={pStyle.input}
                                className={classes.inputShort + " form-control"}
                                type="text"
                                name="email"
                                value={email}
                                onChange={this.resetPasswordEmail}
                              />
                              {this.state.missEmail && (
                                <p className={classes.errorfield}>
                                  You need to fill out e-mail
                                </p>
                              )}
                              {this.state.afterMail && (
                                <p
                                  className={
                                    this.state.afterMailError
                                      ? classes.errorfield
                                      : classes.successfield
                                  }
                                >
                                  {this.state.afterMailMessage}
                                </p>
                              )}
                            </FormGroup>
                            <div id="loadinggd2" style={{ display: "none" }}>
                              <img
                                className="responsive"
                                src={loadinggif}
                                width="25"
                                alt=""
                                style={{ marginBottom: "20px" }}
                              />
                            </div>
                            <ActionButton
                              size="auto"
                              onClick={(e) => this.resetPassword(e)}
                            >
                              Submit
                            </ActionButton>
                          </Form>
                        </div>
                      </CustomModal>

                      {/* This is the modal for accepting terms and conditions */}
                      <CustomModal
                        show={this.state.acceptTerms}
                        onHide={this.acceptTermsCloseModal}
                        backdrop={true}
                      >
                        <div style={{ maxHeight: "500px" }}>
                          <div id="loadinggd2" style={{ display: "none" }}>
                            <img
                              className="responsive"
                              src={loadinggif}
                              width="25"
                              alt=""
                              style={{ marginBottom: "20px" }}
                            />
                          </div>
                          <h1 style={{ alignSelf: "flex-start" }}>Terms and Conditions</h1>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              overflow: "auto",
                              maxHeight: " 400px",
                            }}
                          >
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                              do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                              Sodales neque sodales ut etiam sit amet nisl. Sodales neque
                              sodales ut etiam sit amet nisl. Lectus magna fringilla urna
                              porttitor rhoncus dolor purus non enim. Pellentesque elit eget
                              gravida cum sociis. Adipiscing at in tellus integer feugiat
                              scelerisque varius. Diam ut venenatis tellus in metus
                              vulputate eu. Neque gravida in fermentum et. Sit amet nulla
                              facilisi morbi tempus iaculis urna id volutpat. Ultricies
                              lacus sed turpis tincidunt id aliquet risus feugiat in.
                              Dignissim convallis aenean et tortor at. Tellus in metus
                              vulputate eu scelerisque felis imperdiet proin fermentum.
                              Libero volutpat sed cras ornare arcu dui vivamus arcu felis. A
                              arcu cursus vitae congue mauris rhoncus aenean. Suspendisse in
                              est ante in nibh mauris cursus mattis molestie. Velit euismod
                              in pellentesque massa placerat duis ultricies. Lacus sed
                              turpis tincidunt id. Varius duis at consectetur lorem donec
                              massa sapien faucibus et. Nisi quis eleifend quam adipiscing
                              vitae proin sagittis nisl rhoncus. Vitae suscipit tellus
                              mauris a diam. Tellus molestie nunc non blandit massa. Nulla
                              facilisi nullam vehicula ipsum a arcu cursus vitae. Sed
                              viverra ipsum nunc aliquet bibendum enim. Posuere ac ut
                              consequat semper viverra nam libero justo. Enim praesent
                              elementum facilisis leo vel fringilla est ullamcorper eget.
                              Vitae purus faucibus ornare suspendisse sed nisi. Feugiat
                              scelerisque varius morbi enim. Enim facilisis gravida neque
                              convallis a cras semper. Dui nunc mattis enim ut tellus
                              elementum sagittis vitae. Volutpat diam ut venenatis tellus
                              in. Morbi blandit cursus risus at. Dictum sit amet justo donec
                              enim diam. Pellentesque elit ullamcorper dignissim cras
                              tincidunt lobortis. Pharetra diam sit amet nisl suscipit
                              adipiscing bibendum. Elementum facilisis leo vel fringilla est
                              ullamcorper eget nulla. Et netus et malesuada fames ac turpis
                              egestas maecenas pharetra. Sed blandit libero volutpat sed.
                              Arcu dui vivamus arcu felis bibendum ut tristique. Erat
                              pellentesque adipiscing commodo elit at. Fermentum et
                              sollicitudin ac orci phasellus egestas tellus rutrum.
                              Dignissim enim sit amet venenatis urna cursus. Urna id
                              volutpat lacus laoreet non curabitur. Cras fermentum odio eu
                              feugiat pretium. Arcu bibendum at varius vel pharetra vel. A
                              lacus vestibulum sed arcu. Consequat id porta nibh venenatis
                              cras sed felis. Fermentum posuere urna nec tincidunt praesent
                              semper feugiat nibh. Enim diam vulputate ut pharetra sit.
                              Lectus sit amet est placerat in. Adipiscing enim eu turpis
                              egestas pretium aenean pharetra magna ac.Morbi tristique
                              senectus et netus et malesuada fames ac turpis. Sed sed risus
                              pretium quam vulputate dignissim suspendisse. Parturient
                              montes nascetur ridiculus mus. Cursus eget nunc scelerisque
                              viverra. Malesuada fames ac turpis egestas maecenas pharetra.
                              Feugiat in ante metus dictum at. Ultrices tincidunt arcu non
                              sodales neque sodales ut etiam sit. In aliquam sem fringilla
                              ut morbi tincidunt augue interdum. Eget lorem dolor sed
                              viverra ipsum nunc. Placerat orci nulla pellentesque dignissim
                              enim sit. Blandit turpis cursus in hac. Risus feugiat in ante
                              metus dictum at tempor commodo. Fringilla ut morbi tincidunt
                              augue interdum velit. Placerat orci nulla pellentesque
                              dignissim enim. Integer enim neque volutpat ac tincidunt
                              vitae. Ac turpis egestas integer eget aliquet. Quis blandit
                              turpis cursus in hac habitasse. Tellus molestie nunc non
                              blandit. Suspendisse in est ante in nibh mauris cursus mattis.
                              Volutpat est velit egestas dui id ornare arcu odio ut. Sed
                              adipiscing diam donec adipiscing. Ac placerat vestibulum
                              lectus mauris ultrices. Aliquam faucibus purus in massa tempor
                              nec feugiat nisl. Mattis aliquam faucibus purus in massa. Id
                              venenatis a condimentum vitae sapien pellentesque. Nam aliquam
                              sem et tortor consequat id porta nibh. Cursus risus at
                              ultrices mi tempus imperdiet nulla. Turpis in eu mi bibendum
                              neque egestas congue. Amet mattis vulputate enim nulla aliquet
                              porttitor lacus luctus. Sollicitudin aliquam ultrices sagittis
                              orci. Arcu non odio euismod lacinia at quis. Cras semper
                              auctor neque vitae tempus quam pellentesque nec nam. Quisque
                              id diam vel quam elementum pulvinar etiam non quam. Erat nam
                              at lectus urna duis convallis convallis tellus. Tempus egestas
                              sed sed risus pretium quam vulputate dignissim. Fermentum odio
                              eu feugiat pretium nibh ipsum consequat. Imperdiet proin
                              fermentum leo vel. Aliquam sem fringilla ut morbi tincidunt
                              augue interdum velit euismod. Eget velit aliquet sagittis id
                              consectetur purus ut. Sed arcu non odio euismod. Morbi
                              tincidunt ornare massa eget egestas. Eu turpis egestas pretium
                              aenean. Ac ut consequat semper viverra nam libero justo
                              laoreet. Neque vitae tempus quam pellentesque nec nam aliquam.
                              Cursus eget nunc scelerisque viverra mauris in. Eros donec ac
                              odio tempor orci dapibus ultrices. Nulla porttitor massa id
                              neque aliquam vestibulum morbi blandit. Mattis rhoncus urna
                              neque viverra justo nec. Mauris pellentesque pulvinar
                              pellentesque habitant morbi tristique senectus. Vel fringilla
                              est ullamcorper eget nulla facilisi etiam. Risus viverra
                              adipiscing at in tellus integer. Sapien eget mi proin sed
                              libero enim sed. Lectus quam id leo in. Auctor urna nunc id
                              cursus metus aliquam eleifend mi in. Urna cursus eget nunc
                              scelerisque. Sollicitudin aliquam ultrices sagittis orci a.
                              Sed adipiscing diam donec adipiscing tristique risus nec.
                              Dolor magna eget est lorem. Elit at imperdiet dui accumsan.
                              Amet est placerat in egestas erat imperdiet. At ultrices mi
                              tempus imperdiet nulla malesuada pellentesque. Nisi lacus sed
                              viverra tellus in hac habitasse platea dictumst. Non quam
                              lacus suspendisse faucibus interdum posuere lorem ipsum dolor.
                              Pellentesque habitant morbi tristique senectus. Massa id neque
                              aliquam vestibulum morbi blandit cursus. Dolor magna eget est
                              lorem ipsum dolor sit amet consectetur. Netus et malesuada
                              fames ac turpis egestas maecenas. Amet purus gravida quis
                              blandit. Facilisis sed odio morbi quis. Integer eget aliquet
                              nibh praesent tristique magna sit. Erat nam at lectus urna
                              duis convallis convallis. Viverra accumsan in nisl nisi
                              scelerisque eu ultrices vitae auctor. Egestas integer eget
                              aliquet nibh. Augue mauris augue neque gravida in fermentum et
                              sollicitudin. Purus gravida quis blandit turpis cursus in hac
                              habitasse. Vulputate sapien nec sagittis aliquam malesuada
                              bibendum. Non diam phasellus vestibulum lorem sed risus
                              ultricies tristique nulla. Elit eget gravida cum sociis
                              natoque penatibus et magnis dis. Consectetur libero id
                              faucibus nisl tincidunt. Est pellentesque elit ullamcorper
                              dignissim cras. Ipsum dolor sit amet consectetur adipiscing
                              elit pellentesque habitant. Pulvinar elementum integer enim
                              neque volutpat ac tincidunt. Volutpat lacus laoreet non
                              curabitur gravida arcu ac tortor dignissim. Vitae ultricies
                              leo integer malesuada nunc vel risus commodo viverra. Eleifend
                              mi in nulla posuere sollicitudin. Ante in nibh mauris cursus
                              mattis molestie a iaculis. Id volutpat lacus laoreet non
                              curabitur. Sed viverra tellus in hac habitasse platea
                              dictumst. Lectus sit amet est placerat. Convallis tellus id
                              interdum velit laoreet id. Pellentesque id nibh tortor id
                              aliquet. Morbi quis commodo odio aenean sed adipiscing diam
                              donec adipiscing. Mus mauris vitae ultricies leo integer
                              malesuada nunc vel risus. Morbi quis commodo odio aenean sed.
                              Odio tempor orci dapibus ultrices in. Vitae tortor condimentum
                              lacinia quis vel. Varius morbi enim nunc faucibus a
                              pellentesque sit amet. Pellentesque diam volutpat commodo sed
                              egestas egestas fringilla phasellus. Quis blandit turpis
                              cursus in hac habitasse platea dictumst. Sed ullamcorper morbi
                              tincidunt ornare massa eget egestas purus viverra. In egestas
                              erat imperdiet sed. Et tortor consequat id porta nibh
                              venenatis. Viverra accumsan in nisl nisi scelerisque eu
                              ultrices vitae auctor. Curabitur gravida arcu ac tortor.
                              Libero volutpat sed cras ornare arcu dui vivamus arcu felis.
                              Enim lobortis scelerisque fermentum dui faucibus in ornare
                              quam viverra. Morbi tincidunt ornare massa eget egestas.
                              Imperdiet nulla malesuada pellentesque elit eget gravida.
                              Consectetur a erat nam at lectus urna duis convallis. Amet
                              mauris commodo quis imperdiet massa tincidunt nunc pulvinar.
                            </p>
                            <ActionButton
                              size="auto"
                              onClick={(e) => {
                                document.getElementById("acceptCheckbox").checked = true;
                                this.setState({ termsAccepted: true });
                                this.acceptTermsCloseModal();
                              }}
                            >
                              Accept
                            </ActionButton>
                          </div>
                        </div>
                      </CustomModal>
                    </GridContainer>
        );
    }
  }
}

export default withStyles(loginStyle)(LoginNew);
