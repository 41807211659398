/*!
 * Description: this file contains the main component for
 *  the last step of the modules/guides, the recap step
 * Components: GuideRecap
 */
import React from "react";
import ReactHtmlParser from "react-html-parser";

// Styles
import withStyles from "@mui/styles/withStyles";
import { ourGreen } from "assets/jss/incept-sustainability-variables.jsx";
import { ourOrange } from "assets/jss/incept-sustainability-variables.jsx";

// Style components
import MultiChoice from "components/Exercise/MultiChoice.js";

// Forms
import TextArea from "components/CustomInput/TextArea";

//Buttons
import ActionButton from "components/CustomButtons/ActionButton";

import ModalRecap from "components/Modal/ModalRecap";
//Translations
import { withTranslation } from 'react-i18next';
import DropDown from 'components/CustomInput/DropDown.jsx';
import MenuItem from '@mui/material/MenuItem';
import Grid from "@mui/material/Grid";

const styles = {
	root: {
		fontSize: '14px',
		fontWeight: '700'
	},
	cardBase: {
		backgroundColor: "#fff",
		color: "black",
		height: "100%",
	},
	cardHeader: {
		borderBottom: "2px solid #0295a7ff",
	},
	cardBody: {
		height: "100%",
	},
	iconNavBack: {
		fontSize: "30px",
	},
	itemContent: {
		padding: "30px 10px",
		height: "100%",
	},
	itemContainer: {
		paddingBottom: "30px",
	},
	buttonContainer: {
		textAlign: "center",
	},
	buttonAnswer: {
		color: "#fff",
		backgroundColor: ourGreen,
		"&:hover, &:active, &:focus": {
			filter: "brightness(85%)",
			backgroundColor: ourGreen,
		},
		buttonInspire: {
			color: "#fff",
			backgroundColor: ourOrange,
			"&:hover, &:active, &:focus": {
				filter: "brightness(85%)",
				backgroundColor: ourOrange,
			},
		},
		textField: {
			marginTop: "20px",
			marginBottom: "20px",
		},
	},
	submitWrapper: {
		textAlign: 'right'
	},
	submitButton: {
		marginTop: '20px',
	},
	showElement: {
		display: "block"
	},
	hideElement: {
		display: "none !important"
	},
	footertext: {
		fontStyle: "italic",
		fontSize: "11px",
		textAlign: "right"
	},
	rightlabel: {
		textAlign: "right",
		paddingRight: "10px",
		margin: "auto"
	},
	categorygrid: {
		marginTop: "20px"
	}
};


/*
const inspireideas = [
	"What do you think would be the most popular sustainable initiative in your department?",
	"What do you think would be the least popular sustainable initiative in your department?",
	"What is the biggest sustainability challenge in your everyday work?",
	"What are you most proud of that your company does?",
	"Where do you see your company in 5 years?",
	"Which sustainability directions would be most interesting for your company?",
	"What would be the easiest sustainability issue to solve in your opinion?",
	"Have you heard of anything cool that other companies are doing?",
	"What do you think should be prioritised more/ higher in your company?",
	"What would help you be more sustainable in your work?",
	"What should your company stop doing to become more sustainable?",
	"How can your company be better at prioritising sustainability?",
	"Are there any companies you think are inspiring in their way of working with sustainability - and why?",
	"What would help remind people that sustainability is an important topic?",
	"What is the most sustainable thing your company has done within the last month?",
	"What is the least sustainable thing your company has done within the last month?",
];*/

/*function randomidea() {
	document.getElementById("inspiremecontainer").innerHTML =
		inspireideas[Math.floor(Math.random() * inspireideas.length)];
	document.getElementById("inspiremecontainer2").innerHTML =
		inspireideas[Math.floor(Math.random() * inspireideas.length)];
}*/

/*
 * Description: renders the component for the last step of the guides
 */
class GuideRecap extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			allideas: "",
			showideabox: true,
			valueDD: 0,
			limitText: false,
			noComments: false,
		};
		this.timeoutIdeas = 0;
	}
	
	handleNoComments = (event) => {
		if (event.target.checked) {
			this.setState({ noComments: true });
		} else {
			this.setState({ noComments: false });
		}
	}


	handleChange(e) {
		const limitText = e.target.value;
		if (limitText.length <= 10 && limitText.length > 0) {
			this.setState({ limitText: true });
		}
		else {
			this.setState({ limitText: false });
		}
		const allideas = e.target.value;
		this.setState({ allideas: allideas });
	}

	clearTimeout() {
		clearTimeout(this.timeoutIdeas);
	}

	handleChange2(e) {

		const ideaObj = {
			idea: this.state.allideas,
			label: this.state.valueDD
		};

		if (this.state.limitText == false) {

			if (this.state.allideas === "") {
				alert("Text field cannot be empty");
				return;
			}
			this.props.saveresloc("Ideas", ideaObj, this.props.indexk + 1);
			this.setState({ showideabox: false, allideas: "" });
			/*document.getElementById("thankudiv3").style.display = "block";
			document.getElementById("ideabox3").style.display = "none";*/

			this.timeoutIdeas = setTimeout(() => {
				this.setState(prevState => ({
					showideabox: true
				}));
			}, 2000);

			//this.setState({ allideas: "" });
		}
		else {
			this.setState({ limitText: true });
		}
	}

	onChangeDD(event) {
		const answerIndexSelected = parseInt(event.target.value);
		this.setState({ valueDD: answerIndexSelected });
	}

	render() {
		const {
			classes,
			ideatext,
			learnings,
			saveresloc,
			indexk,
			labels,
			datasav,
			showModal,
			onHide,
			onFinishClick,
			t
		} = this.props;
		return (
			<ModalRecap
				className={classes.root}
				show={showModal}
				onHide={onHide}
				cleartimeout={this.clearTimeout}
			>
				<h1>{t('Wrap up')}</h1>
				<h2>{t('Comments and ideas')}</h2>
				<div>{ReactHtmlParser(ReactHtmlParser(ideatext))}</div>
				<div id="ideabox3" className={this.state.showideabox ? classes.showElement : classes.hideElement}>
					<TextArea
						disabled={this.state.noComments}
						onChange={(e) => this.handleChange(e)}
						value={this.state.allideas}
					/>
					{this.state.limitText == true ? <p style={{ color: "red" }}>{t("Please submit a valid idea (a minimum of 10 characters)")}</p> : ""}
					{(labels !== undefined && labels.length > 0) && <Grid container className={classes.categorygrid}>
						<Grid item xs={12} md={8} className={classes.rightlabel}>
							<p style={{ marginBottom: "0" }}>{t("Please select the category") + ":"}</p>
						</Grid>
						<Grid item xs={12} md={4}>
							<DropDown
								value={this.state.valueDD}
								color="black"
								paddingShort={true}
								onChange={(event) => this.onChangeDD(event)}
							>
								{labels.map(
									(item) => <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
								)}
								<MenuItem value={0}>
									<em>Other</em>
								</MenuItem>
							</DropDown>
						</Grid>
					</Grid>}
					<p className={classes.footertext}>
						{t('no personal data',
							{
								defaultValue: 'Please keep your submission anonymous, do not write any personal data in your submission.'
							})}
					</p>
					<div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start'}}>
						<label htmlFor="addComments" className={classes.addComments} style={{marginRight: "2%", fontWeight: 'bolder'}}>
							{t('I don\'t have any comments')} </label>
						<input type="checkbox" style={{transform: "scale(1.5)", cursor: "pointer"}} onChange={(e) => this.handleNoComments(e)}/>
					</div>
					<div className={classes.submitWrapper}>
						<ActionButton
							onClick={(e) => this.handleChange2(e)}
							disabled={this.state.noComments}
							className={classes.submitButton}>
							{t('Submit')}
						</ActionButton>
					</div>
				</div>
				<div style={{ margin: "30px 0", textAlign: "center" }}
					className={"responsive " + (this.state.showideabox === false ? classes.showElement : classes.hideElement)}>
					<h1 style={{ color: ourGreen }}>{t('Thank you!')} </h1>
					<h2>{t('Your comment has been received') + "."}</h2>
				</div>
				{(learnings.options !== undefined && learnings.options !== "" && learnings.options.length > 0) &&
					<span><h2>{t('Your take-away')}</h2>
						<div><p>{t('Please check the box if you agree with the following statements') + ":"}</p></div>
						<MultiChoice
							exercise={learnings}
							save2={saveresloc}
							inkey={indexk}
							comesf={"learnings"}
							datasav2={datasav}
							showModal={showModal}
						/></span>}
				<ActionButton size="large" centered onClick={onFinishClick}>{t('Finish')}</ActionButton>
			</ModalRecap>
		);
	}
}

GuideRecap.defaultProps = {};

export default withTranslation()(withStyles(styles)(GuideRecap));