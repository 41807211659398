/*!
* Description: this file contains the component for
*  the section "example"
* Components: GuideExampleInteractive
*/
import React from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Container from '@mui/material/Container';
import withStyles from "@mui/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { ourBlue60,ourOrange,ourBoxGreen } from 'assets/jss/incept-sustainability-variables.jsx';
import { GuideCardTitle } from "components/GuidePage/GuideStyleCommon.js";
//Buttons
import arrowdown from 'assets/img/down-arrow.svg';
import checkmark from 'assets/img/checkmark-green.svg';
import greyCheckmark from 'assets/img/checkmark-grey.svg';
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import Collapse from "@mui/material/Collapse";
//Translations
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from "react-html-parser";
import { useMediaQuery } from 'react-responsive';
import guideIcon from 'assets/img/information.svg';
import { ourDarkGreen } from "assets/jss/incept-sustainability-variables";
import {flipCards_MobileFriendly} from "assets/js/flip-cards.js";

const styles = {
	root: {},
	cardExample: {
        marginBottom: "10px",
        minHeight: "110px",
		marginTop: "0"
	},
	cardBodyText: {
		marginTop: "20px",
        fontSize: ".95rem",
	},
	container: {
		"@media only screen and (max-width: 960px)": {
			padding: "0",
		}
	},
	buttonContainer: {
		textAlign: "end",
		margin: "5px 20px",
		"@media only screen and (max-width: 960px)": {
			margin: "10px 0"
		},
		"@media only screen and (max-width: 600px)": {
			textAlign: "center",
		},
	},
    blockContainer: {
        flex: "1 1 auto",
		padding: "0 0 0 0",
        "@media only screen and (max-width: 960px)": {
			height: "auto"
		},
    },
    blockContainer_vertical: {
        height: "100%",
		//padding: "10px 0 0 0",
    },
    blockContainer2: {
        padding: "0 0 0 0",
        cursor: "pointer",
        flex: "0 1 auto",
        "@media only screen and (max-width: 960px)": {
			height: "auto"
		},
	},
    gridItem: {
		textAlign: "center",
		paddingRight: '10px',
		paddingBottom: '50px',
	},
    imgContainer: {
		padding: "0",
        height: "100%",
        cursor: "pointer"
    },
	boxContainer: {
        width:'100%',
		borderRadius: "0 16px 16px 0",
		padding: "1.3rem 1rem",
		height: "100%",
        "& p": {
            marginBottom: "0"
        }
	},
    boxContainer_vertical: {
        width:'100%',
        height: "100%",
		padding: "1.3rem 1rem",
        placeSelf: "center",
        display: "flex",
        "& p": {
            marginBottom: "0"
        },
        "@media only screen and (max-width: 1500px)": {
			height: "auto"
		}
	},
    subcontainer0: {
        height: "100%",
    },
    containerRow: {
        marginBottom: "30px"
    },
    paddingBox: {
        //padding: "30px 0",
        display: "grid"
    },
    boxContent: {
        //fontSize: "1rem"
    },
    visible: {
        visibility: "visible",
        transition: "opacity 1s ease"
    },
    invisible: {
        visibility: "hidden",
        opacity: "0",
        transition: "opacity 1s ease"
    },
    flexcolumn: {
        display: "flex",
        flexFlow: "column",
        height: "100%"
    },
	iconDownArrow: {
		cursor: "pointer",
	},
	iconDownArrowDiv: {
		paddingTop: "20px",
		textAlign: "center",
	},
	titleMargin: {
		marginLeft: "20px"
	},
    checkmark: {
        "@media only screen and (max-width: 960px)": {
            float: "right",
        },
      },
};

const ContentContainer = withTranslation()(withStyles(styles)(
	({ classes, onClick, content, showboxContent,position,title, onclick, t }) => (
		<React.Fragment>
			<Container style={{placeSelf:"center"}}>
				{/*<Collapse className={classes.boxContent} in={showboxContent}>{ReactHtmlParser(ReactHtmlParser(content))}</Collapse>*/}
                {!showboxContent && 
                    <div className={`${classes.subcontainer0} ${classes.boxContent}`}>
                        <h3 style={{fontWeight:"200",color:ourDarkGreen}}>{title}</h3>   
                                    
                    </div>}
                <div className={`${showboxContent ? classes.visible:classes.invisible} ${classes.subcontainer0} ${classes.boxContent}`}>
                    {showboxContent && 
                    <span>
                        <h4 style={{fontWeight:"200",color:ourDarkGreen}}>{title}</h4>
                        <span>{ReactHtmlParser(ReactHtmlParser(content))}</span>
                    </span>  
                    }                
                </div>
			</Container>
            {!showboxContent && <div 
            style={{padding:"20px 24px", 
                    fontWeight:"700",color:ourOrange, width:"100%",
                    display:"table",
                    textAlign: position}}>
                <div style={{display:"table-cell",verticalAlign:"bottom"}}>
                    <a onClick={onclick} style={{cursor:"pointer"}}>{t("Learn more")}</a>
                </div>
            </div> }
		</React.Fragment>
	)
));

const ContentContainerFixed = withTranslation()(withStyles(styles)(
	({ classes, onclick, content, showboxContent,title, t }) => (
		<React.Fragment>
			<Container className={classes.subcontainer0} style={{padding:"0"}}>
				<div className={classes.subcontainer0}>
                    {<span style={!showboxContent ? {display:"block"}:{display:"none"}}>
                        <h4 style={{fontWeight:"200",color:ourDarkGreen,textAlign:"center"}}>{title}</h4>
                        <div 
                            style={{padding:"0 24px",
                                fontWeight:"700",color:ourOrange, width:"100%",
                                display:"table",textAlign:"right"}}>
                            <div style={{display:"table-cell",verticalAlign:"bottom"}} >
                                <a onClick={onclick} style={{cursor:"pointer"}}>{t("Learn more")}</a>
                            </div>
                        </div>
                    </span>  }
                    {<span className={`${showboxContent ? classes.visible:classes.invisible}`}>
                        <h4 style={{fontWeight:"200",color:ourDarkGreen}}>{title}</h4>
                        <span>{ReactHtmlParser(ReactHtmlParser(content))}</span>
                        {/*A trick to make the box have the same height*/}
                        <span style={showboxContent ? {display:"block",visibility: "hidden"}:{display:"none"}}>
                        <h4 style={{fontWeight:"200",color:ourDarkGreen,textAlign:"center"}}>{title}</h4>
                        <div 
                            style={{padding:"0 24px",
                                fontWeight:"700",color:ourOrange, width:"100%",
                                display:"table",textAlign:"right"}}>
                            <div style={{display:"table-cell",verticalAlign:"bottom"}} >
                                <a onClick={onclick} style={{cursor:"pointer"}}>{t("Learn more")}</a>
                            </div>
                        </div>
                        </span>
                    </span>  }
                </div>
			</Container>
		</React.Fragment>
	)
));

function withMyHook(Component) {
    return function WrappedComponent(props) {
      const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
      return <Component {...props} isMobile={mobile} />;
    }
}

class GuideExampleInteractive_b extends React.Component {

	constructor(props){
		super(props);
        const { boxes } = props;
		const { options } = boxes;
		this.state = {
			isdefaultView: (this.props.videoContent!="") ? true:false,
			defaultView: (this.props.defaultView==="Video content" ) ? false:true,
			loading: true,
            contentExample: options,
            contentBox: 0,
			defaultFirst: (this.props.defaultFirst) ? true: false,
			showboxContent: Array(options.length).fill(false),
            mobileView: false,
            //colorBoxes: Array(DIMENSIONS.length).fill(this.props.colorBox)
		}
	}

    componentDidMount() {
		flipCards_MobileFriendly();
	};

	handleSubmit(e) {
		e.preventDefault();
		this.setState({ defaultView: !this.state.defaultView });
	}

    handleExampleContent(e,n) {
		e.preventDefault();
        this.setState((state) => {
			const sa = state.showboxContent;
			sa[n] = !sa[n];
			return { showboxContent: sa };
		});
	}

	render() {
		const { classes, title,videoContent,textContent,inColumns, t,saveresloc,
            isLastSection,isContent,showcard,openSection,datasav,smoothtrans } = this.props;
        let colorBox = ourBoxGreen;
        var grid_buttons;
        let stylePaddingBody = (showcard) ? {padding: "0 30px 30px 30px"}:{padding: "0 30px"};
        const emptycontent = <ContentContainer
                content={"Click icon"}
                showboxContent={true}
            />;
        if (!inColumns) {
            grid_buttons = this.state.contentExample.map((item, index) => {
                let breakpoint1 = 4;
                let breakpoint2 = 8;
                let content2 = <ContentContainer
                        content={item.content}
                        title={item.title}
                        position={"right"}
                        onclick={(e) => this.handleExampleContent(e,index)}
                        showboxContent={this.state.showboxContent[index]}
                    />;
                let content1 = <img className='responsive_nopointer2' style={(!this.props.isMobile) ? {borderRadius: "16px 0 0 16px"}:{}} src={item.img}/>;
                let class1 = classes.imgContainer;
                let class2 = classes.boxContainer_vertical;
                if (index % 2 !== 0 && !this.props.isMobile) {
                    breakpoint1 = 8;
                    breakpoint2 = 4;
                    content1 = <ContentContainer
                            content={item.content}
                            title={item.title}
                            position={"left"}
                            onclick={(e) => this.handleExampleContent(e,index)}
                            showboxContent={this.state.showboxContent[index]}
                        />;
                    content2 = <img className='responsive_nopointer2' style={{borderRadius: "0 16px 16px 0"}} src={item.img}/>;
                    class1 = classes.boxContainer_vertical;
                    class2 = classes.imgContainer;
                }
                return (
                    <GridContainer container style={{paddingTop:"40px"}} key={index}>
                        <GridItem xs={12} sm={12} lg={breakpoint1} spaceLeft={0} spaceNoPadding={true} className={classes.gridItem}>
                            <div className={classes.blockContainer_vertical}>
                                <div className={`${(index % 2 !==0 && !this.props.isMobile) ? classes.paddingBox:""} ${classes.subcontainer0}`}>
                                    <div className={class1} 
                                    style={(index % 2 !== 0 && !this.props.isMobile) ? {backgroundColor:colorBox,borderRadius: "16px 0 0 16px", flexWrap: "wrap"}:{}}>
                                    {content1}</div>
                                </div>
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} lg={breakpoint2} spaceLeft={0} spaceNoPadding={true} className={classes.gridItem}>
                            <div className={classes.blockContainer_vertical}>
                                <div className={`${(index % 2 ==0 || this.props.isMobile) ? classes.paddingBox:""} ${classes.subcontainer0}`}>
                                <div className={class2} 
                                    style={(index % 2 == 0 && !this.props.isMobile) ? {backgroundColor:colorBox,borderRadius: "0 16px 16px 0", flexWrap: "wrap"}:(this.props.isMobile) ? {backgroundColor:colorBox,flexWrap: "wrap"}:{}}>
                                    {content2}</div>
                                </div>
                            </div>
                        </GridItem>
                    </GridContainer>
                );
            });
        }
        else {
            let gridTYpe = (this.state.contentExample.length==2) ? 6:4;
            grid_buttons = this.state.contentExample.map((item, index) => {
                return (
                    <GridItem xs={12} sm={12} md={gridTYpe} spaceLeft={0} style={{marginTop:"30px"}} key={index}>
                        <div className={classes.flexcolumn}>
                                <div className={classes.blockContainer2}>
                                    <div className={classes.subcontainer0}>
                                        <div className={classes.imgContainer}>
                                        <img 
                                        style={{borderRadius: "16px 16px 0 0"}}
                                        className={`responsive_nopointer2`} 
                                        src={item.img}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.blockContainer}>
                                    <div className={classes.subcontainer0}>
                                    <div className={classes.boxContainer} 
                                    style={{backgroundColor:colorBox,borderRadius: "0 0 16px 16px"}}>
                                        <ContentContainerFixed
                                            content={item.content}
                                            onclick={(e) => this.handleExampleContent(e,index)}
                                            title={item.title}
                                            showboxContent={this.state.showboxContent[index]}
                                        />
                                    </div>
                                    </div>
                                </div>
                        </div>
                    </GridItem>
                );
            });
        }

		return (
			<div className={classes.root}>
				<Container className={classes.container}>
					<Card className={classes.cardExample}>
                    <CardBody 
						style={ (this.props.isMobile) ? {padding: "0 0 0 30px"}:stylePaddingBody}		>

                        <GridContainer justifyContent="center" 
                            style={
                                {
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    minHeight: "110px",
                                    flexWrap: (this.props.isMobile) ? "wrap" : "nowrap",
                                    flexDirection: (this.props.isMobile) ? "column" : "row",
                                    gap: (this.props.isMobile) ? "1rem" : undefined,
                                    margin: (this.props.isMobile) ? "20px 0" : "auto",
                                }}
                        >
								<GridItem style={{ width: "100%" }} spaceLeft={0}>
									<GridContainer 
                                        alignItems="center"
                                        justifyContent="space-between"
                                        style={this.props.isMobile ? { width: "100%", flexDirection: "row"}:{ width: "100%", flexDirection: "row", flexWrap: "nowrap" }}
                                    >
                                        <GuideCardTitle style={{marginBottom:"0"}}>
                                            <GridContainer
                                                style={{
                                                    alignItems:"center",
                                                    flexDirection: "row",
                                                    flexWrap: "nowrap",
                                                    gap: "1rem",
                                                    flexGrow: "1",
                                                    width: "100%"
                                                }}
                                            >
                                                {/* Static width is required here. */}
                                                <GridItem  space={0} spaceNoPadding={true}>
                                                <img
                                                    src={(typeof datasav !== "undefined") ? checkmark : greyCheckmark}
                                                    className={classes.dotimage2}
                                                    width="80px"
                                                    style={{paddingRight:"20px"}}
                                                    alt='Information'
                                                />
                                                </GridItem>
                                                <GridItem 
                                                    md="auto"
                                                    lg="auto"
                                                    spaceLeft={0}
                                                    style={{ flexGrow: "1", width: "100%", maxWidth: "100%" }}
                                                >
                                                    <span>{
                                                    <GuideCardTitle>{(isContent) ? "":t('Example')+":"} {title}
                                                    </GuideCardTitle>}</span>
                                                </GridItem>
                                            </GridContainer>
                                        </GuideCardTitle>
										
                                <GridItem xs={12} md={3} spaceLeft={0} 
									style={this.props.isMobile ? {cursor:"pointer",
                                    marginTop:`${showcard ? "20px":"10px"}`}:
                                    {cursor:"pointer"}}>
									<div className={`${classes.iconDownArrowDiv}`}
                                            style={(title=="") ? 
                                                {marginBottom: "12.8px",paddingTop:"7px",textAlign:"right",display:"flex"} :
                                                {paddingTop:"0",textAlign:`${this.props.isMobile ? "center":"right"}`}}
                                        >
                                        {showcard && this.state.isdefaultView && 
                                        <ActionButton 
                                            onClick={(e) => this.handleSubmit(e)} 
                                            type="submit" 
                                            size="auto"
                                            centered={true}
                                            style={(this.state.isdefaultView && showcard) ? 
                                                {width:"auto",
                                                marginRight: `${this.props.isMobile ? "0":"20px"}`,
                                                marginLeft: `${this.props.isMobile ? "30px":"0"}`,
                                                marginBottom:`${this.props.isMobile ? "20px":"0"}`}:
                                                {visibility:"hidden"}}
                                        >
                                            {(this.state.defaultView) && t('See video instead')}
                                            {(!this.state.defaultView) && t('See text instead')}
                                        </ActionButton>		}									
									</div>
                                    </GridItem>
                                    </GridContainer>
								</GridItem>
                                <img
                                    src={arrowdown}
                                    style={{
                                        transform: (showcard) ? "rotate(-180deg)": undefined,
                                        display: "block",
                                        margin: `${this.props.isMobile ? "0":"0 auto"}`,
                                        flexGrow: 0,
                                        marginLeft:`${this.props.isMobile ? "-30px":"0"}`
                                    }}
                                    alt="Go to next section"
                                    title="Go to next section"
                                    onClick={openSection}
                                    className={classes.iconDownArrow}
                                />
							</GridContainer>

                            <Collapse in={showcard} 
                            style={{margin:`${this.props.isMobile ? "10px 0 20px -20px":"auto"}`}}
                            addEndListener={() => {
                                if (showcard) {
									saveresloc();
									setTimeout(() => {smoothtrans.scrollIntoView({behavior: "smooth"})}, 100);
								}
							}} >
                            <div className={classes.cardBodyText}>
							{(this.state.defaultView) && 
								<div>{textContent}</div>}

                            {(this.state.defaultView) && inColumns && 
                                <GridContainer container style={{justifyContent: "center"}}>
                                    {grid_buttons}
                                </GridContainer>}

                            {(this.state.defaultView) && !inColumns && grid_buttons}

                            {(!this.state.defaultView) && <div className={classes.cardBodyText}>
								{videoContent}</div>}		
                            </div>
							
                            {/*!isLastSection && <div onClick={goNextSection} className={`${classes.iconDownArrowDiv} moveArrow`}>
								<img
									src={arrowdown}
									alt="Go to next section"
									title="Go to next section"
									
									className={classes.iconDownArrow}
								/>
						    </div>*/}
                            </Collapse>
						</CardBody>                        
					</Card>
				</Container>
			</div>
		);
	}
}

GuideExampleInteractive_b.defaultProps = {
	children: (<p>Here comes the content for this Example</p>),
	title: 'Example',
};


export default withTranslation()(withMyHook(withStyles(styles)(GuideExampleInteractive_b)));