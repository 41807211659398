/*!
* Description: this file contains a component
*  that renders a disabled lookalike button that contains numbers or text
*/

import React, { useState, useEffect,forwardRef } from 'react';
import withStyles from "@mui/styles/withStyles";
import guidesStyle from "assets/jss/incept-custom-css.jsx";

import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

import analyticsStyle from "assets/jss/views/analyticsStyle.jsx";
import { n_comp_pck, isAuthenticated,ourBlue,actions_post  } from "assets/jss/incept-sustainability-variables.jsx";
import Modal from "react-bootstrap/Modal";
import MyActions_Header from "views/MyActions/MyActions_Header.jsx";
import MyActions_Filter from "views/MyActions/MyActions_FilterBar.jsx";
import MyActions_cards from "views/MyActions/MyActions_cards.jsx";
import loadinggif from "assets/img/loading.gif";
import { IconButton } from "@mui/material";
import icon7 from "assets/img/icon7.png";
import listimg from "assets/img/listimg.png";
import axios from "axios";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
//Translations
import { withTranslation, Trans, useTranslation } from 'react-i18next';
import i18n from "i18next";
import i18next from "i18next";
import { action } from '@storybook/addon-actions';
import { Link,useLocation,useHistory } from "react-router-dom";
import newtab from "assets/img/newtabOrange.png";
import DatePicker from 'react-date-picker';
import DropDown from "components/CustomInput/DropDown.jsx";
import MenuItem from "@mui/material/MenuItem";
import calendar from "assets/img/calendar.png";
import Form from "react-bootstrap/Form";
import ActionButton from "components/CustomButtons/ActionButton";
import TextareaAutosize from 'react-textarea-autosize';
import FlipMove from 'react-flip-move';
import throttle from 'lodash/throttle';
import Grid from "@mui/material/Grid";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

const useStyles = (theme) => ({
	cardPaddingRight: ({color, textColor}) => ({
		marginRight: "30px",
		"@media only screen and (max-width: 960px)": {
			marginRight: "15px",
		}
	}),
	modalCancelButton: {
		right: "0",
		position: "relative",
		zIndex: "3",
		padding: "0px",
		float: "right",
	},
	sourceBox: {
		maxHeight: "200px",
		overflow: "auto"
	}
	
});

function MyActionsPage(props) {
	const {classes,location, content} = props;
	const [loadingfirst, setloadingfirst] = useState(true);
	const [submittingNewLabel, setsubmittingNewLabel] = useState(false);
	const [managers, setManagers] = useState([]);
	const [deleteActionBl, setdeleteActionBl] = useState(false);
	const [delActionid, setdelActionid] = useState(-1);
	const [deleteActionBlName, setdeleteActionBlName] = useState("");
	const [departments, setDepartments] = useState([]);
	const [sourcePage, setSourcePage] = useState({idea: "0",activity:"0", sourcetext:""});
	const [actions, setActions] = useState([]);
	const [actionsFiltered, setActionsFiltered] = useState([]);
	const [headerInfo, setheaderInfo] = useState({active: 0,completed:0,unassigned:0});
	const [currentFilters, setcurrentFilters] = useState({actionfor:0,responsible: 0,source:0,status:0});
	const [showDetails, setshowDetails] = useState(false);
	const { t, i18n } = useTranslation();
	const [startDate, setStartDate] = useState(new Date());
	const [sourceIdeas, setsourceIdeas] = useState([]);
	const [sourceIdeasRender, setsourceIdeasRender] = useState(<span></span>);
	const [modalInfo, setmodalInfo] = useState({actionid:0, 
												title:"",
												isIdea: 0,
												basedIdeas: 0,
												activities: [],
												departmentid: 0,
												responsibleid:0,
												description:"",
												source:"",
												sourceActions: false,
												responsible:"", 
												completed_by:"",
												status:""});

	useEffect(() => {
		// You need to restrict it at some point
		// This is just dummy code and should be replaced by actual
		if (loadingfirst) {
			const {search } = location;
			const searchParams = new URLSearchParams(search);
			if (searchParams.has("idea")) {
				let ideaid = searchParams.get("idea");
				setSourcePage({...sourcePage,idea:ideaid});
				getncomplpack(ideaid,"idea");
				return;
			}
			else if (searchParams.has("activity")) {
				let activityid = searchParams.get("activity");
				setSourcePage({...sourcePage,activity:activityid});
				getncomplpack(activityid,"activity");
				return;
			}			
			getncomplpack();
		}
	  }, []);

	useEffect(() => {
		//add the flip move
		const ideasList = ( ideas ) => ( 
			<FlipMove
				staggerDurationBy="30"
				duration={500}
				typeName="ul"
				enterAnimation="fade" 
				leaveAnimation="fade"
				style={{padding:"0"}}
			>
			{ideas.map((idea,index) => (
				<FunctionalIdea key={idea.id} 
				id={idea.id} 
				index={index}
				text={idea.text}
				dateidea={idea.dateidea}/>
			))}
			</FlipMove>
		);
		let {sourceActions, ...restItem} = modalInfo;
		setmodalInfo({...restItem, sourceActions: true});
		setsourceIdeasRender(ideasList(sourceIdeas));
		
	}, [sourceIdeas]);

	var statuses = ["To do","In Progress","Completed"];

	const moveIdeas = (id) => {
		const auxx = sourceIdeas
		.filter((r,index) => { return r.id!==id; });
		setsourceIdeas(auxx);
	}

	//Get ideas from backend
	const getncomplpack = async (fromid=0,source="") => {
		if (!isAuthenticated()) {
			props.isExpired();
		}
		const token = localStorage.getItem("token");

		const insdata = {
			fromid: fromid,
			source: source,
		};

		const authstr = "Bearer " + token;
		const useLang = i18n.language;
		const urlapi = n_comp_pck + "actions?wpml_language=" + useLang;
		axios
			.get(urlapi, { headers: { Authorization: authstr },params: insdata })
			.then((response) => {
				console.log(response.data);
				setActions(response.data.actions);
				setActionsFiltered(response.data.actions);
				setManagers(response.data.managers);
				setDepartments(response.data.departments);
				setheaderInfo({active:response.data.active,completed:response.data.completed,unassigned:response.data.unassigned});
				if (source==="idea") 
					setSourcePage({idea:fromid, activity:"0", sourcetext:response.data.sourceinfo});
				else if (source==="activity") 
					setSourcePage({idea:"0", activity:fromid, sourcetext:response.data.sourceinfo});
				else 
					setSourcePage({idea:"0", activity:"0", sourcetext:""});
				setloadingfirst(false);
			})
			.catch((err) => {
				console.log(err);
				setloadingfirst(false);
			});
	};

	//filter per source
	const filterSource = (event, type) => {
		//filter source
		setcurrentFilters({
			actionfor: 0,
			responsible: 0,
			source: event.target.value,
			status: 0
		  });
		if (event.target.value===0) {
			setActionsFiltered(actions);
			return;
		}
		let actionsFilteredL = actions.filter(function(e) {
			return event.target.value === e.type;
		});
		setActionsFiltered(actionsFilteredL);
	};

	const filterStatus = (event, type) => {
		//filter source
		setcurrentFilters({
			actionfor: 0,
			responsible: 0,
			source:0,
			status: event.target.value
		  });
		if (event.target.value===0) {
			setActionsFiltered(actions);
			return;
		}
		let actionsFilteredL = actions.filter(function(e) {
			return event.target.value === e.status;
		});
		setActionsFiltered(actionsFilteredL);
	};

	const filterAction = (event, type) => {
		//filter source
		setcurrentFilters({			
			actionfor: event.target.value,
			responsible: 0,
			source:0,
			status:0
		  });
		if (event.target.value===0) {
			setActionsFiltered(actions);
			return;
		}
		let actionsFilteredL = actions.filter(function(e) {
			return parseInt(event.target.value) === e.departmentid;
		});
		setActionsFiltered(actionsFilteredL);
	};

	const filterResponsible = (event, type) => {
		//filter source
		setcurrentFilters({			
			actionfor: 0,
			responsible: event.target.value,
			source:0,
			status:0
		  });
		if (event.target.value===0) {
			setActionsFiltered(actions);
			return;
		}
		let actionsFilteredL = actions.filter(function(e) {
			let res = (e.responsible!==null) ? e.responsible.user_id:"";
			return event.target.value === res;
		});
		setActionsFiltered(actionsFilteredL);
	};

	function clearFilters() {
		setActionsFiltered(actions);
		setcurrentFilters({actionfor: 0,responsible: 0,source:0,status:0});
		if (sourcePage.idea!=="0" || sourcePage.activity!=="0") {
			setloadingfirst(true);
			removeParamsUrl();
			getncomplpack();
		}
	}

	function removeParamsUrl () { 
		const {search } = location;
		const searchParams = new URLSearchParams(location.search);		
		if (searchParams.has('idea')) {
			searchParams.delete('idea');
			history.pushState("", document.title, window.location.pathname
														   + searchParams);
		}
		else if (searchParams.has('activity')) {
			searchParams.delete('activity');
			history.pushState("", document.title, window.location.pathname
														   + searchParams);
		}
	}
	
	const submitEditAction = (e) => {
		if (modalInfo.title == null || modalInfo.title == "") {
			//setnewLabel(false);
		} else {
			setsubmittingNewLabel(true);

			if (!isAuthenticated()) {
				props.isExpired();
			}

			const insdata = {
				actionid: modalInfo.actionid,
				title: modalInfo.title,
				status: modalInfo.status,
				description: modalInfo.description,
				department: modalInfo.departmentid,
				responsible: modalInfo.responsibleid,
				completedby: modalInfo.completed_by.toDateString(),
				basedIdeas: modalInfo.basedIdeas,
				ideas: JSON.stringify(sourceIdeas),
				activities: JSON.stringify(modalInfo.activities)
			};

			const token = localStorage.getItem("token"); //token needed
			const authstr = "Bearer " + token;
			//post
			axios
				.post(actions_post + "edit", insdata, {
					headers: { Authorization: authstr },
				})
				.then((res) => {
					console.log(res);
					//modified the actions array 
					let headeraux = {active:headerInfo.active,completed:headerInfo.completed,unassigned:headerInfo.unassigned};
					let actionsFilteredL = actions.map((action, index) => {
						if (action.idaction === modalInfo.actionid) {
							let { title, description, departmentid, responsibleid,responsible,completed_by,status,ideas, ...restItem } = action;
							var datestring = modalInfo.completed_by.getDate()  + "-" + (modalInfo.completed_by.getMonth()+1) + "-" + modalInfo.completed_by.getFullYear();
							let edited_action = {
								title: modalInfo.title,
								description: modalInfo.description,
								departmentid: modalInfo.departmentid,
								responsibleid: modalInfo.responsibleid,
								responsible: managers[parseInt(modalInfo.responsibleid)],
								completed_by: datestring,
								ideas: sourceIdeas,
								status: modalInfo.status,
								...restItem
							}
							if (action.status!==modalInfo.status) {
								if (action.status==="Completed") {
									headeraux.completed = (headeraux.completed>0) ? headeraux.completed-1:0;
									headeraux.active = headeraux.active+1;
								}
								else if (action.status==="In Progress") {
									if(modalInfo.status!=="To do") {
										headeraux.completed = headeraux.completed+1;
										headeraux.active = (headeraux.active>0) ? headeraux.active-1:0;
									}
								}
							}
							if (parseInt(action.responsible.user_id)!==parseInt(modalInfo.responsibleid)) {
								if (modalInfo.responsibleid!==0)
									headeraux.unassigned = (headeraux.unassigned>0) ?  headeraux.unassigned-1:0;
								else 
									headeraux.unassigned += 1; 
							}
							return edited_action;
						}
						return action;
					});
					setheaderInfo(headeraux);
					setActionsFiltered(actionsFilteredL);
					setActions(actionsFilteredL);
					setshowDetails(false);
					setsubmittingNewLabel(false);
				})
				.catch((err) => {
					console.log(err);
					setsubmittingNewLabel(false);
					
				});
		}
	}

	const FunctionalIdea = forwardRef((props, ref) => (
		<div ref={ref} style={{padding:"0",margin:"0 10px 0 1px"}}>
			<Card style={{marginTop:"0"}}>
			<CardBody
				style={{ padding: "0" }}
			>									
				<ListItem  key={props.id}
				index={props.index}
				>
						<ListItemText disableTypography>
							{'"'+props.text+'"'}
						<i><b>{" Received on: "}</b></i>{props.dateidea+"."}
						</ListItemText>
						<ListItemAvatar>
							<div className={"closeContainerAM"} onClick={throttle(() => moveIdeas(props.id), 800)}>
								<div className={"leftrightAM"}></div>
								<div className={"rightleftAM"}></div>
								<label className={"closeAM"}>{"remove?"}</label>
							</div>
						</ListItemAvatar>	
					</ListItem>
			</CardBody>
			</Card>
		</div>
	  ));

	function openModal(actionDetail) {
		let source = (actionDetail.ideas.length>0) ? "Ideas and comments":"Employee feedback";
		let respon = (actionDetail.responsible!==null) ? actionDetail.responsible.first_name+" "+actionDetail.responsible.last_name:"Unassigned";
		let compl_date = "";
		if (actionDetail.completed_by!=="") {
			var splitted = actionDetail.completed_by.split('-');
			compl_date = new Date(splitted[2],splitted[1]-1,splitted[0]);
		}

		/*const ideasList = actionDetail.ideas.map((idea, index) => {
				<FunctionalArticle 
				key={article.id} {...article} />
		});*/
		setsourceIdeas(actionDetail.ideas);

		const activities = actionDetail.activities.map((activity, index) => {
			return (
					<div style={{color:ourBlue,textAlign:"center",marginTop:"20px"}} key={activity.id}>
						<b>{activity.title}</b><br></br>
						<Link target="_blank"  to={{pathname:"/admin/analytics_company#"+activity.id, query:0}} style={{marginTop:"20px"}}>
							{" (See activity"}
							<img
								src={newtab}
								width="10"
								alt=""
								style={{ marginLeft: "5px" }}
							/>
							{") "}
						</Link>
					</div>
			)
		});

		//sourceDescription
		setmodalInfo({
			actionid: actionDetail.idaction,
			title: actionDetail.title,
			description: actionDetail.description,
			completed_by: compl_date,
			responsible: respon,
			responsibleid: (actionDetail.responsible!==0) ? actionDetail.responsible.user_id:0,
			status: actionDetail.status,
			isIdea: actionDetail.ideas.length>0,
			activities: activities,
			sourceActions: modalInfo.sourceActions,
			basedIdeas: actionDetail.ideas.length>0 ? 1:0,
			departmentid: actionDetail.departmentid,
			source: source
		});
		
		setshowDetails(true);
	}

	const CustomInputActionDate = React.forwardRef((props, ref) => {
		//let auxx = modalactioninfo.compltedby.toISOString().replace(/T.*/,'').split('-').reverse().join('-')
		let auxx = "";
		return (
			<span onClick={props.onClick}><span className={classes.datepickerinput} style={{verticalAlign:"middle"}}>{auxx}</span><img
			src={calendar}
			alt="calendar icon"
			style={{ width: "20px", height: "20px" }}
		/></span>
		);
	  });

	const handleChange = (e, type) => {
		try {
			if (type === "description") {
				let {description, ...restItem} = modalInfo;
				setmodalInfo({...restItem, description: e.target.value});
			}
			else if (type === "title") {
				let {title, ...restItem} = modalInfo;
				setmodalInfo({...restItem, title: e.target.value});
			}
		}
		catch(error) {
			throw new Error("Component: MyActionsPage - handleChange");
		}
	}

	const deleteActionModal = (e,idaction,name) => {
		setdeleteActionBl(!deleteActionBl);
		setdelActionid(idaction);
		setdeleteActionBlName(name);
	}

	const deleteCloseModal = () => {
		setdeleteActionBl(!deleteActionBl);
		setdelActionid(-1);
		setdeleteActionBlName("");
	}

	const deleteAction = (e) => {
		if (delActionid===-1) {
			//setnewLabel(false);
		} else {
			setsubmittingNewLabel(true);

			if (!isAuthenticated()) {
				props.isExpired();
			}

			const insdata = {
				actionid: delActionid
			};

			const token = localStorage.getItem("token"); //token needed
			const authstr = "Bearer " + token;
			//post
			axios
				.post(actions_post + "delete", insdata, {
					headers: { Authorization: authstr },
				})
				.then((res) => {
					console.log(res);
					//modified the actions array 
					setActionsFiltered(prevState => {
						// Object.assign would also work
						//Return something simpler!
						const actionsaux = {wildcard: ""};
						return {...prevState, actionsaux};
					  });
					//setActions(actionsaux);
					deleteCloseModal();
					setsubmittingNewLabel(false);
				})
				.catch((err) => {
					console.log(err);
					setsubmittingNewLabel(false);
					
				});
		}
	}

	const onChangeAction = (event, type) => {
		if (type === "Market") {
			let {departmentid, ...restItem} = modalInfo;
			setmodalInfo({...restItem, departmentid: event.target.value});
		} else if (type === "Responsible") {
			let {responsibleid, ...restItem} = modalInfo;
			setmodalInfo({...restItem, responsibleid: event.target.value});
		} else if (type === "statuses") {
			let {status, ...restItem} = modalInfo;
			setmodalInfo({...restItem, status: event.target.value});
		} else if (type === "completedby") {
			let {completed_by, ...restItem} = modalInfo;
			setmodalInfo({...restItem, completed_by: event});
		}
	};

	return (
        <LoadingOverlay
			active={loadingfirst}
			spinner={<BounceLoader />}
		>
		<div className={classes.containerAnalytics} id="testscroll">
			<h2 className={classes.titleHeader}>
				{'My Actions'}
			</h2>
			<div className={classes.cardPaddingRight}>
				<MyActions_Header 	
				headerInfo={headerInfo}
				/>
			</div>
			<div style={{margin:"50px 0 20px 0"}}>
				<MyActions_Filter 
				managers={managers}
				departments={departments}
				sourcePage={sourcePage}
				onChangeSource={filterSource}
				onChangeStatus={filterStatus}
				onChangeResponsible={filterResponsible}
				onChangeActionFor={filterAction}
				currentFilters={currentFilters}
				clearFilters={clearFilters}
				/>
			</div>
			<div style={{margin:"0 0 50px 0"}}>
				<MyActions_cards 
				actions={actionsFiltered}
				openModal={openModal}
				departments={departments}
				deleteActionModal={deleteActionModal}
				/>
			</div>
		</div>
		<Modal
          id="0"
          show={showDetails}
          onHide={() => {
            setshowDetails(false);
          }}
          centered
          size="xl"
          className={classes.modalBox}
        >
          <Modal.Body>
            <IconButton
                onClick={() => setshowDetails(false)}
                disabled={false}
                className={classes.modalCancelButton}
                size="large">
              <img
                src={icon7}
                alt="closeAM icon"
                style={{ width: "40px", height: "40px" }}
              />
            </IconButton>
            <div style={{ padding: "2rem" }}>
			<Form>
				<div style={{marginBottom: "20px"}}>
					<h4 className={classes.formTitle} style={{margin:"5px 0"}}>{"Title: "}</h4>
					<Form.Control 
					rows="1" 
					value={modalInfo.title}
					className={classes.texttitle} 
					onChange={(e) => handleChange(e,"title")} />
				</div>
              <div>
			  	<div>
				  <h4 className={classes.formTitle} style={{margin:"5px 0"}}>{"Description: "}</h4>
					<div>
					<TextareaAutosize 
					minRows="2"
					maxRows="3"
					className={classes.textareaAutoSize}
					value={modalInfo.description}
					style={{borderRadius:"0"}}
					onChange={(e) => handleChange(e,"description")}
					/>
					</div>
				</div>
				<div style={{marginTop:"30px"}}>
					<h4 className={classes.formTitle} style={{margin:"5px 0"}}>
						{(modalInfo.basedIdeas) ? "Based on the following ideas ":"Based on the following Activity: "}
					{modalInfo.basedIdeas===1 && <Link target="_blank"  to={{pathname:"/admin/analytics_ideas", query:0}} style={{marginTop:"20px"}}>
						{"(All ideas"}
						<img
							src={newtab}
							width="10"
							alt=""
							style={{ marginLeft: "5px" }}
						/>
						{"): "}
					</Link>}
					</h4>
					<div className={classes.sourceBox} style={{marginBottom:"20px"}}>
					<List>{!modalInfo.isIdea && modalInfo.activities}</List>
					<List style={{padding:"0"}}>{modalInfo.sourceActions && sourceIdeasRender}</List>
					</div>
					<div>
					<span className={classes.formTitle+" gMedium"} style={{margin:"25px 5px 0 0"}}>{"Action for: "}</span>
					<span>
						{" "}
						<DropDown
							value={modalInfo.departmentid}
							analytics={true}
							paddingShort={true}
							fullWidth={false}
							otherClasses={classes.doubleLineDD2}
							style={{
								width: "250px",
								color: "#009df0",
								fontWeight: "700",
							}}
							onChange={(e) => onChangeAction(e,"Market")}
						>
							<MenuItem key={0} value={0}>
									{"All"}
							</MenuItem>
							{departments.map((item) => (
								<MenuItem key={item.id} value={parseInt(item.id)}>
									{item.name}
								</MenuItem>
							))}
						</DropDown>
					</span>
				</div>
				<div style={{margin:"25px 0 0 0"}}>
					<span className={classes.formTitle+" gMedium"} >{"Responsible: "}</span>
					<span style={{marginLeft:"5px"}}>
						{" "}
						<DropDown
							value={modalInfo.responsibleid}
							analytics={true}
							paddingShort={true}
							fullWidth={false}
							otherClasses={classes.doubleLineDD2}
							style={{
								width: "250px",
								color: "#009df0",
								fontWeight: "700",
							}}
						onChange={(e) => onChangeAction(e,"Responsible")}>
								<MenuItem key={0} value={0}>
							{"Unassigned"}
							</MenuItem> 
								{Object.keys(managers).map(key => 
								<MenuItem key={key} value={key} name={key}>
								{managers[key].first_name+" "+managers[key].last_name}
								</MenuItem>
							)}
						</DropDown>
					</span>
				</div>
				<div style={{margin:"25px 0 0 0"}}>
					<span className={classes.formTitle+" gMedium"} >{"Status: "}</span>
					<span style={{marginLeft:"5px"}}>
						{" "}
						<DropDown
							value={modalInfo.status}
							analytics={true}
							paddingShort={true}
							fullWidth={false}
							otherClasses={classes.doubleLineDD2}
							style={{
								width: "250px",
								color: "#009df0",
								fontWeight: "700",
							}}
							onChange={(e) => onChangeAction(e,"statuses")}>
							{statuses.map((item,index) => (
								<MenuItem key={index} value={item}>
									{item}
								</MenuItem>
							))}
						</DropDown>
					</span>
				</div>
				<div style={{ display: "flex",margin:"25px 0 0 0" ,alignItems:"center"}}>
					<span className={classes.formTitle+" gMedium"}>{"Complete by: "}</span>
					<span className={classes.datepicker}>
						<DatePicker 
						className={classes.datepickercl}
						format={"dd-MM-y"}
						onChange={(date) => onChangeAction(date,"completedby")}
						value={modalInfo.completed_by} />
					</span>
				</div>		
					<div style={{marginTop:"20px"}}>
						<Grid container spacing={2}  justifyContent="flex-end" style={{float:"right"}}>
							{submittingNewLabel && (
								<div style={{ paddingTop: "18px", textAlign: "right" }}>
									<img
										style={{marginRight:"20px"}}
										src={loadinggif}
										width="25"
										alt=""
									/>
								</div>
							)}
							<div style={{marginRight:"20px"}}>
								<ActionButton className={classes.submitButton}
								disabled={submittingNewLabel}
								lighten onClick={() => setshowDetails(false)}>{t('Cancel')}</ActionButton>
							</div>
							<div>
								<ActionButton className={classes.submitButton}
								disabled={submittingNewLabel}
								onClick={(e) => submitEditAction(e)}>{t('Save changes')}</ActionButton>
							</div>
						</Grid>
					</div>			
				</div>
				{/*<div><b>{"Responsible: "}<span style={{margin:"5px 0",color:ourBlue}}>{modalInfo.responsible}</span></b></div>
				<div><b>{"Completed by: "}<span style={{margin:"5px 0",color:ourBlue}}>{modalInfo.completed_by}</span></b></div>
				<div><span style={{margin:"5px 0"}}><b>{"Source: "}</b>{modalInfo.source}</span></div>
		{"details of actions"}*/}
              </div>
			  </Form>
            </div>
          </Modal.Body>
        </Modal>
		<Modal
			id="4"
			show={deleteActionBl}
			onHide={() => {
				setdeleteActionBl(false);
				setdelActionid(-1);
			}}
			centered
			size="md"
			className={classes.modalBox}
		>
			<Modal.Body>
				<IconButton
                    onClick={() => {
						setdeleteActionBl(false);
						setdelActionid(-1);
					}}
                    disabled={submittingNewLabel}
                    className={classes.modalCancelButton}
                    size="large">
					<img
						src={icon7}
						alt="cancel icon"
						style={{ width: "40px", height: "40px" }}
					/>
				</IconButton>
				<div style={{ padding: "2rem" }}>
					<h5 style={{ fontWeight: "bold", marginTop: "20px" }}>
						{"Are you sure you want to delete action: \"" +
							deleteActionBlName +
							"\"? (Action will be deleted forever)"}
					</h5>
					<div
						id="ideabox"
					>
						<Form>
							<ActionButton
								onClick={(e) => deleteAction(e)}
								style={{ float: "right" }}
								disabled={submittingNewLabel}
								className={classes.submitButton}
							>
								{t("Yes")}
							</ActionButton>
							<ActionButton
								onClick={(e) => deleteCloseModal(e)}
								disabled={submittingNewLabel}
								className={classes.submitButton}
							>
								{t("No")}
							</ActionButton>
						</Form>
					</div>
					{submittingNewLabel && (
						<div style={{ paddingTop: "20px" }}>
							<img
								className="responsive"
								src={loadinggif}
								width="25"
								alt=""
							/>
						</div>
					)}
				</div>
			</Modal.Body>
		</Modal>
		</LoadingOverlay>
    );
}
export default
withStyles(
    (theme) => ({
      ...analyticsStyle(theme),
      ...useStyles(theme),
	  ...guidesStyle(theme)
    }),
    { withTheme: true },
  )(MyActionsPage);
