import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";

const style = ({spaceCustom}) => ({
  grid: {
    padding: "0 15px !important",
    "@media only screen and (max-width: 600px)": {
      padding: "0 !important",
      width: "100%"
		},
    "@media only screen and (max-width: 1280px)": {
      marginTop: "20px"
		}
  },
  gridL: {
    padding: "0 15px 0 0 !important"
  },
  gridR: {
    padding: "0 0 0 15px !important"
  },
  gridTitle: {
    padding: "0 15px !important",
  },
  gridPaddingHeightAlign: {
    padding: "0 15px 30px 0 !important"
  },
  gridNoPadding: {
    padding: "0"
  },
  gridEvenPadding: {
	padding: "0 7px 0 7px !important"
  },
  gridRightDashboardModule: {
    padding: "0 0 30px 15px !important"
  },
  

});

function GridItem({ ...props }) {
  const { classes, children, space, spaceNoPadding,nospaceRight,gridEvenPadding,spaceLeft,spaceRight,spaceRigh,paddingHeightAlign, spaceRightDashboardModule, otherclasses, ...rest } = props;
  var spaceclass = classes.grid;
  var otherclass = (otherclasses!==undefined) ? otherclasses: "";
  var styleCustom = "";
  if (space===0 || spaceLeft===0) {
    if (space===0) {
      spaceclass = "";
    }
	if (gridEvenPadding) 
	  spaceclass = classes.gridEvenPadding;
    else if (spaceNoPadding)
      spaceclass = classes.gridNoPadding;
    else 
      spaceclass = classes.gridL;
  }
  else if (nospaceRight) {
    spaceclass = classes.gridR;
  }
  else if (spaceRight===0) {
    spaceclass = classes.gridTitle;
  }
  else if (paddingHeightAlign) {
    spaceclass = classes.gridPaddingHeightAlign;
  }
  else if (spaceRightDashboardModule) {
    spaceclass = classes.gridRightDashboardModule;
  }
  return (
    <Grid item {...rest} className={spaceclass+" "+otherclass}>
      {children}
    </Grid>
  );
}

GridItem.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node
};

export default withStyles(style)(GridItem);
