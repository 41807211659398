const cardAnalyticsBodyStyle = {
    cardBody: {
      padding: "0.8rem 2rem 2rem 2rem",
      flex: "1 1 auto",
      WebkitBoxFlex: "1",
      position: "relative",
      borderRadius: "16px",
      fontSize: "14px",
      fontFamily: "gotham-font-light"
    }
  };
  
  export default cardAnalyticsBodyStyle;
  