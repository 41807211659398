import React from 'react';
import { ResponsiveBar  } from '@nivo/bar';

export default ({ data, term,keys,color,legendaux,theme,defs,t /* see data tab */ }) => (
    <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={term}
        margin={{ top: 0, right: 20, bottom: 10, left: 0 }}
        padding={0.3}
        layout="horizontal"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={color}
        borderRadius={3}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        enableGridY={false}
        labelSkipWidth={12}
        labelSkipHeight={22}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        label={d => `${d.value}%`}
        theme={theme}
        role="application"
        tooltipLabel={d => `${legendaux[d.id]}`}
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={function(e){return e.id+": "+e.formattedValue+" in country: "+e.indexValue}}
    />
);