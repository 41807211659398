import {IdeasAndCommentsFilter} from "../BaseFilters/IdeasAndCommentsFilter";
import {ResetFilter} from "../BaseFilters/ResetFilter";
import {DepartmentFilter} from "../Filters/DepartmentFilter";
import {LabelFilter} from "../Filters/LabelFilter";
import {ModuleFilter} from "../Filters/ModuleFilter";
import {EmployeeFilter} from "../Filters/EmployeeFilter";
import {ClearFilter} from "../Filters/ClearFilter";


abstract class FilterClass<T, K> {

    public static readonly filterTypes: Record<string, FilterClass<any, any>> = {
        'department': DepartmentFilter,
        'label': LabelFilter,
        'module': ModuleFilter,
        'employeeFilter': EmployeeFilter,
        'clear': ClearFilter,
    };

    public static getAllFilterTypes(): Record<string, FilterClass<any, any>> {
        return this.filterTypes;
    }

    public static getFilterType(type: string): FilterClass<any, any> {
        return this.filterTypes[type];
    }

    abstract createFilter(): IdeasAndCommentsFilter<T, K> | ResetFilter;
    abstract getClassName(): string;
}

export { FilterClass }
