import React from 'react'
import jwt from "jwt-decode";

function AssessmentsCategories() {
  const paths = window.location.href.split('/')
  const categoryId = paths[paths.length - 1] !== '' ? paths[paths.length - 1] : paths[paths.length - 2]
  
  const jwtToken = jwt(localStorage.getItem('token'));
  console.log('PATH ====> ', window.location.href.split('/'))
  console.log('TOKEN ====> ', jwtToken)
  const token = localStorage.getItem('token');
  // const url = `https://ndstaging.inceptsustainability.com/assessments/categories/${categoryId}`
  console.log('CATEGORY ID:: ', categoryId)

  // TODO: Find how to transfer jwt token to the next.js app

  return (
    <div style={{
        width: "100vW",
        height: "100vh"
    }}>
      <iframe
        src="https://app.inceptsustainability.com/login" // Use next frontend URL for listing categories
        title={`iframe`}
        width="100%"
        height="100%"
        style={{
          border: 'none',
          marginTop: '10px',
          marginBottom: '10px'
        }}
      />
    </div>
  )
}

export default AssessmentsCategories