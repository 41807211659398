/*!
* Description: this file contains a component
*  that renders a disabled lookalike button that contains numbers or text
*/

import React, { useState } from 'react';
import withStyles from "@mui/styles/withStyles";
import moreInfo from 'assets/img/moduleIcon.svg';
import analyticsStyle from "assets/jss/views/analyticsStyle.jsx"; //style for all analytics
import { ourGreen20,ourGreen,ourYellow,ourOrange80,ourOrange,ourBlue60 } from "assets/jss/incept-sustainability-variables.jsx";

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import Checkbox from "@mui/material/Checkbox";
import CardAnalytics from "components/CardAnalytics/Card.jsx";
import CardAnalyticsHeader from "components/CardAnalytics/CardHeader.jsx";
import CardAnalyticsBody from "components/CardAnalytics/CardBody.jsx";
import ResponsiveBar from "components/Nivo/ResponsiveBar.jsx";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Tooltip from '@mui/material/Tooltip';
import { useMediaQuery } from 'react-responsive'; //for mobile responsiveness

const useStyles = (theme) => ({
    responsivePie: {
        '& g:nth-child(2)':{
			'& g:nth-child(1) text':{
                fontSize: "28px !important"
            }
		}
    },
    pieLabel: {
        fontSize: "18px",
        fontWeight: "bold"
    },
    pieLabelVal: {
        fontSize: "26px",
        fontWeight: "bold",
        color: ourGreen
    },
    boxdiv: {
        alignItems:"center",
        display:"flex"
    },
    box: {
        height:"20px",
		width:"20px",
        marginRight: "10px",
        display: "inline-block",
        verticalAlign: "middle"
    },
    green: {
        backgroundColor: ourGreen
    },
    gray: {
        backgroundColor: "#bebebe"
    },
    yellow: {
        backgroundColor: ourYellow
    },
});


const themePie = {
    "legends": {
        "text": {
            "fontSize": 18,
        },
    }
  };

function withMyHook(Component) {
    return function WrappedComponent(props) {
      const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
      return <Component {...props} isMobile={mobile} />;
    }
}

const themeBar = {
    "labels": {
        "text": {
            "fontSize": 18,
        },
    }
  };

  const TextOnlyTooltip = withStyles({
	tooltip: {
		fontSize: "14px"
	}
})(Tooltip);

function StackedBarCard(props) {
	const {classes, content} = props;

	let barColors = [ourGreen,ourYellow,ourOrange80,ourBlue60, "#b23984", "#39b28e", ourGreen20];

    const items = [];
	const legends = [];
    let i = 0;
    props.data.forEach(arrayItem => {
		const arrWithProps = [];
		for (let key in props.legends) {
			arrWithProps[key+""] = props.legends[key]+"%";
		}
		let databar = [arrayItem];
		if (props.type=="standard") {
			barColors = [ourGreen,ourYellow,"#bebebe",ourOrange,ourGreen20];
			databar = (props.includeEmpty) ? [arrayItem.forChart]:[arrayItem.forChart_nempty]
		}
                items.push(
                    <GridContainer key={i} justifyContent="center" style={{width:"100%"}}>                        
                        <GridItem xs={12} sm={12} md={12} lg={12} style={{alignContent:"center"}}>
                            <div style={{fontWeight:"bold"}}>{arrayItem[props.term]}</div>
                            <div style={{height:"60px",width:"100%",position:"relative"}}>
                            <ResponsiveBar
                                data={databar}
								keys={props.keysId}
								term={props.term}
                                color={barColors}
                                legend={props.keysTitle}
                                legendaux={arrWithProps}
                                theme={themeBar}
                            />
                            </div>
                        </GridItem>
                    </GridContainer>
                );
                i++;
    });
	
	props.keysTitle.forEach((arrayBox, index) => {
		legends.push(
			<div className={classes.boxdiv}><div className={classes.box} style={{backgroundColor: barColors[index]}}></div>{arrayBox}</div>
		)
	});

	return (
            <CardAnalytics>
                <CardAnalyticsHeader title={props.title}>
                    <div style={{marginLeft:"auto",cursor:"pointer"}}>
                        {!props.isCustomManager && <ActionButton
                                type="submit"
                                isAnalytics={true}
                                style={{marginRight:"10px"}}
								onClick={(e) => props.addActionModal(props.title,props.exerciseid,props.index)}
                            >
                                {'+add to actions page'}
                        </ActionButton>}
                        <TextOnlyTooltip title={"How are the stats calculated?"} arrow>
                            <img
                                className="responsive2"
                                src={moreInfo}
                                alt="How are the stats calculated?"
                                onClick={(e) => props.showHideModal("bar")}
                            />
                        </TextOnlyTooltip>
                    </div>
                </CardAnalyticsHeader>
                <CardAnalyticsBody>
                    {props.children}
                    <div style={{marginBottom:"60px",marginTop:"0px"}}>
                        <GridContainer key={"legendcontainer"} justifyContent="center">    
                            <GridItem xs={12} sm={12} md={12} key={"legend"} style={{marginBottom:"30px"}}>
                                {legends}
                            </GridItem>
                        </GridContainer>
                        <GridContainer justifyContent="center">
                            {items}
                        </GridContainer>
                    </div>
                </CardAnalyticsBody>
            </CardAnalytics>
	);
}
export default
withMyHook(withStyles(
    (theme) => ({
      ...analyticsStyle(theme),
      ...useStyles(theme),
    }),
    { withTheme: true },
  )(StackedBarCard));
