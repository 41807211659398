import React from "react";
import withStyles from "@mui/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import {Link } from "react-router-dom";
import {
	Card,
	CardImg,
	CardBody,
	CardSubtitle,
} from "reactstrap";
import { ourGreen, ourOrange } from "assets/jss/incept-sustainability-variables.jsx";
import ActionButton from "components/CustomButtons/ActionButton";
//Translations
import about from "assets/img/moduleIcon.svg";
import GridContainer from "components/Grid/GridContainer.jsx";
import ModuleLenght_tooltip from "components/Tooltip/ModuleLenght_tooltip";
import Plausible from 'plausible-tracker';
const { trackEvent } = Plausible();

const style = (theme) => ({
	button: {
		width: "110px",
		backgroundColor: ourOrange,
		color: "white",
		border: "1px",
		borderRadius: "20px",
		cursor: "pointer",
		fontSize: "15px",
		padding: "2px 8px",
		display: "block",
		position: "inherit",
		"&:hover": {
			backgroundColor: ourOrange,
			opacity: "0.6"
		},
		[theme.breakpoints.down('lg')]: {
			marginBottom: "5px",
		},
	},
	moduleCard: {
		borderRadius: "16px",
		//marginBottom: "2.2rem",
		padding: "20px 10px",
		marginBottom: "10px",
		[theme.breakpoints.down('md')]: {
			marginBottom: "20px"
		},
	},
	moduleCardWrapper1: {
		margin: "0.5rem auto 0 auto",
		padding: "3%",
		textAlign: "center",
		backgroundColor: ourGreen,
		borderRadius: "50%",
		width: "70%",
		[theme.breakpoints.down('md')]: {
			width: "45%",
		},

		"@media (max-width: 600px)": {
			width: "80%",
		},
	},
	moduleCardWrapper: {
		margin: "0.5rem auto 0 auto",
		padding: "3%",
		textAlign: "center",
		backgroundColor: "#CBCBCB",
		borderRadius: "50%",
		width: "70%",
		[theme.breakpoints.down('md')]: {
			width: "45%",
		},

		"@media (max-width: 600px)": {
			width: "80%",
		},
		
	},
	moduleCardInnerWrapper: {
		backgroundColor: "white",
		borderRadius: "50%",
		width: "100%",
		height: "100%",
		margin: "auto",
		[theme.breakpoints.down('lg')]: {},
	},
	"@supports not (-ms-ime-align: auto)": {
		buttonContainer: {
		  //justifyContent: "space-evenly !important"
		  textAlign: "center"
		}
	  },
	  
	buttonContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "space-around",
		margin: "1rem 0",
		[theme.breakpoints.down('lg')]: {
			flexDirection: "column",
			alignItems: "center",
		},
		[theme.breakpoints.down('md')]: {
			flexDirection: "row",
		},
	},
	moduleCardInnerWrapper1: {
		backgroundColor: "white",
		borderRadius: "50%",
		width: "100%",
		height: "auto",
		margin: "auto",
	},
	moduleTitle: {
		lineHeight: "28.8px",
		marginTop: "20px",
		marginBottom: "15px",
		fontWeight: "bold",
		fontSize: "1.2rem",
		[theme.breakpoints.down('md')]: {
			marginTop: "1rem",
		},
	},
	guideTitle: {
		marginTop: "2px",
		fontSize: "0.85rem",
		fontWeight: "500",
	},
	colorLabel: {
		display: 'inline-block',
		justifySelf: 'flex-start',
		alignSelf: 'flex-start',
		padding: '4px 25px',
		marginLeft: '11px',
		borderRadius: '10px',
		fontWeight: '100',
	},
});

const textColor = (colorInput) => {
	switch (colorInput) {
		// Green
		case "#d1e2c0":
			return "#688f73";

		// Dark Blue
		case "#d0d6e4":
			return "#5f749c";

		// Red
		case "#fbe7dd":
			return "#80576d";

		// Standard fontColor
		default:
			return "#ffffffff";
	}
};


const ListModules = (withStyles(style))(
	(props) => {
		const { classes, t, showHideModal, button_word, iskb } = props;
		let modulesToRender = props.list_modules;
		let renderList = modulesToRender.map((module_guide, index) => {

			let moduleType = module_guide.module_types?.map((module_type, index) => {
				
				return (
					<div key={index}>
							<h6 className={classes.colorLabel}
							style={{backgroundColor: `#${module_type.color}`, color: textColor(`#${module_type.color}`)}}>{module_type.name}</h6>
						</div>
				)
			});


			let isAfter = false;
			if (module_guide.complete_before!==undefined) {
				let compbef = module_guide.complete_before;
				let pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
				let dt = new Date(compbef.replace(pattern,'$3-$2-$1'));
				let today = new Date();
				isAfter = (today>dt);
			}

			let imglogo = (module_guide.completed === 1) ? module_guide.logo_aft : module_guide.logo_bef;
			let paramClass = (module_guide.moduleClass!==undefined) ? "/"+module_guide.moduleClass+"/":"/act/";
			if (iskb) {
				paramClass = props.type;
				imglogo = module_guide.logo;
			}
			return (
				<GridItem xs={12} sm={6} md={props.Dashboard ? 6 : 3}
				nospaceRight={props.Dashboard && !props.isMobile ?  true : ''}
				space={(index === 0 && props.Dashboard) && !props.isMobile  ? 0:''} 
				spaceNoPadding={(index === 0 && props.Dashboard) && !props.isMobile  ? true:false}
				paddingHeightAlign={(index === modulesToRender.length - 1 && props.Dashboard) && !props.isMobile  ?  '' : true } key={index}
				>
					<Card className={classes.moduleCard} key={index} style={{height: "100%"}}>

						{/* This is the label in the top left!  */}
						<div style={{display: 'flex', justifyContent: 'space-between', minHeight: props.Dashboard ? '44px':''}}>
							<div style={{display: 'flex', justifyContent: 'space-between'}}>{moduleType}</div>
							{props.showHideModal && <img
									src={about}
									alt="about"
									style={{cursor:"pointer", marginRight: '11px', display: 'inline-block', alignSelf: 'flex-start'}}
									onClick={(e) => showHideModal(index + 1, module_guide.name, module_guide.des, module_guide.img)}
								/>}
						</div>
						
						

						{/* This is the image!  */}
						<div className={module_guide.completed === 1 ?
							classes.moduleCardWrapper1 : classes.moduleCardWrapper}>
							{" "}
							<div className={classes.moduleCardInnerWrapper1}>
								<CardImg
									className="mx-auto d-block"
									top
									// height="120"
									src={imglogo}
									alt="Card image cap"
									style={{ }}
								/>
							</div>
						</div>

						{/* This is the title!  */}
						<div style={{ textAlign: "center" }}>
							<CardSubtitle className={classes.moduleTitle} style={{margin:"25px 25px 15px 25px", fontSize: '1.3rem'}}>
								{module_guide.name+" "}
							</CardSubtitle>
						</div>

						{/* This is the description!  */}
						<CardBody style={{padding:"0"}}>  
						<div style={{textAlign:"center"}}>
							{module_guide.type!==null && module_guide.type!=="" && <CardSubtitle className={classes.guideTitle}>
							<span style={{fontWeight:"700"}}>{t("Type")+": "}</span>{module_guide.type}
							</CardSubtitle>}
							{module_guide.complete_before!==undefined && module_guide.complete_before!=="" && <CardSubtitle className={classes.guideTitle}>
							<span style={{fontWeight:"700",color:(isAfter ? "red":"initial")}}>{(!isAfter) ? (t("Complete before")+": "):(t("Overdue")+": ")}</span>
							{module_guide.complete_before}
							</CardSubtitle>}
							{module_guide.module_length!==undefined && module_guide.module_length!==null && module_guide.module_length!=="" &&
							<CardSubtitle className={classes.guideTitle}>
							<span style={{fontWeight:"700"}}>
								{t("Module length")+": "}
							</span>
							<ModuleLenght_tooltip tooltipid={"tool1"} lengthM={module_guide.module_length}>
							</ModuleLenght_tooltip>							
							</CardSubtitle>}
							{module_guide.completed === 1 && module_guide.modpdfurl!==undefined && module_guide.modpdfurl!=="" && 
							<div><a href={module_guide.modpdfurl} style={{fontSize:"0.85rem",textDecoration:"underline"}}
							target="_blank">Download Module PDF</a></div>}
						</div>  
						</CardBody>                
						
						{/* This is the action button for either recap or continue */}
						<div className={classes.buttonContainer}>
							<Link to={"/guides/" + module_guide.id + props.startModule+paramClass} onClick={() => trackEvent('KB Module '+module_guide.name)}>
								<ActionButton className={classes.button}>
									{button_word(module_guide.started,module_guide.completed,t)}
								</ActionButton>
							</Link>
						</div>
						{/* </CardBody> */}
					</Card>
				</GridItem>
			);

		})
		return (
			<GridContainer>
				{renderList}
			</GridContainer>

		)
});

export default ListModules;