import { useState } from 'react';

const useModulesData = () => {
    const [modules, setModules] = useState([]);

    return {
        modules, setModules,
    };
};

export default useModulesData;
