import axios from "axios";
import { is_g_comp, node_elearningpage } from "assets/jss/incept-sustainability-variables.jsx";

export const fetchGuides = async (token, insdata, useLang) => {
    const authstr = "Bearer " + token;
    try {
        const noderequest = node_elearningpage + "?lang=" + useLang;
        const wprequest = is_g_comp + "actmodule?wpml_language=" + useLang;
        const response = await axios.get(wprequest, { headers: { Authorization: authstr }, params: insdata });
        let modules = response.data.modules;
        let started = Object.entries(response.data.guideStarted);
        let date_completed = response.data.date_completed;
        for (const [guideid, isstarted] of started) {
            const index = modules.findIndex(module => module.id === guideid);
            if (index !== -1)
                modules[index].started = isstarted;
        }
        return {
            numModules: response.data.nModules,
            all_modules: modules,
            date_completed: date_completed,
            complete_before: response.data.completeb,
            userrealname: response.data.flname,
            allcompleted: modules.every((elem) => elem.completed === 1)
        };
    } catch (err) {
        console.log(err);
        throw err;
    }
};