/*!
 * Description: StepZero component refers to the very first step in a guide
 * It is supposed to be used as an introduction to the guide.
 */

//Basic Import
import React from "react";
import ReactHtmlParser from "react-html-parser";
//Verify types
import PropTypes from 'prop-types';

// Material-UI Styles and Helpers
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Container from '@mui/material/Container';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { Link as RouterLink } from "react-router-dom";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import Typography from "@mui/material/Typography";
//Translations
import { withTranslation } from 'react-i18next';
import { ourGreen, ourBlue, standardRadious, ourOrange, ourDarkGreen, ourLightGreen } from 'assets/jss/incept-sustainability-variables.jsx';
import {
	CardImg,
	CardSubtitle
} from "reactstrap";
import GoalImage from "assets/img/SustainabilityLevelIcon.png"
import activity from 'assets/img/activity.svg';
import { useMediaQuery } from 'react-responsive';
import Link from '@mui/material/Link';
import Modal from "react-bootstrap/Modal";
import { ourBlueLight } from "assets/jss/incept-sustainability-variables";

const colors = {
	textBase: '#000',
	scrollbar: '#FFC5C3'
};

const numIcons = {
	root: {
		width: '36px',
		lineHeight: '36px', //has to be the same as width
		borderRadius: '50%',
		textAlign: 'center',
		fontSize: '24px',
		fontWeight: '700',
		color: '#fff',
		background: ourBlue,
		display: 'inline-block'
	}
};

/* Base object to make two types of stepMarkers and to avoid
installing plugins to make CSS extend */
// This is used below in "style" object.
const stepMarker = {
	background: ourOrange,
	height: '20px',
	width: '130px',
	position: 'relative',
	'&::before': {
		content: '""',
		display: 'inline-block',
		height: '100%',
		width: '10px',
		position: 'absolute',
		borderTop: '10px solid transparent',
		borderBottom: '10px solid transparent',
		borderLeft: '8px solid white',
	},
	'&::after': {
		content: '""',
		display: 'inline-block',
		height: '100%',
		width: '15px',
		left: '100%',
		position: 'absolute',
		borderTop: '10px solid transparent',
		borderBottom: '10px solid transparent',
		borderLeft: '8px solid ' + ourOrange,
	}
};

const style = (theme) => ({
	root: {
		padding: '20px 50px',
		color: colors.textBase,
		"@media only screen and (max-width: 767px)": {
			padding: '40px 0',
		}
	},
	card: {
		borderRadius: '16px',
		marginBottom: "0",
		marginTop: "15px"
	},
	title: {
		marginBottom: "0"
	},
	introduction: {
		/*marginTop : '40px'*/
		margin: "0 80px",
		"@media only screen and (max-width: 1280px)": {
			margin: "0"
		}
	},
	courseContent: {
		marginTop: '40px',
	},
	courseContentHeading: {
		fontSize: '20px',
		fontWeight: '700',
	},
	summaryWrapper: {
		marginTop: '40px',
	},
	learningsWrapper: {
		padding: '0 50px 20px 0',
		"@media only screen and (max-width: 900px)": {
			padding: '0 0 20px 0',
		}
	},
	learningsHeading: {
		fontSize: '20px',
		fontWeight: '900',
	},
	feedbackWrapper: {
		background: ourDarkGreen,
		padding: '20px',
		borderRadius: standardRadious,
		color: "white"
	},
	feedbackHeading: {
		fontSize: '20px',
		fontWeight: '700',
		color: 'white'
	},
	buttonWrapper: {
		textAlign: 'center',
	},
	buttonStart: {
		maxWidth: '400px',
		color: 'white',
		fontSize: '24px',
		borderRadius: '20px',
		backgroundColor: colors.attention,
		"&:hover, &:active, &:focus": {
			filter: "brightness(85%)",
			backgroundColor: colors.attention,
		},
	},
	stepMarker: {
		...stepMarker,
		color: "white",
		fontSize: "10px",
		lineHeight: "20px"
	},
	beginStepMarker: {
		...stepMarker,
		'&::before': {
			display: 'none'
		},
		borderTopLeftRadius: '10px',
		borderBottomLeftRadius: '10px',
		textAlign: "center",
		color: "white",
		fontSize: "10px",
		lineHeight: "20px"
	},
	stepText: {
		textAlign: "center",
		display: "inline-block",
		width: "100%"
	},
	stepItem: {
		display: 'inline-flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		justifyContent: 'center',
		marginRight: '8px',
	},
	stepItemText: {
		transform: 'rotate(-135deg)',
		writingMode: 'vertical-rl',
		flex: '1 1 200px',
		maxWidth: "50px"
	},
	stepItemsWrapper: {
		display: 'flex',
		overflowX: 'auto',
		paddingBottom: '15px',
		'&::-webkit-scrollbar': {
			height: '7px'
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: colors.scrollbar,
			borderRadius: '20px'
		},
		scrollbarColor: `${colors.scrollbar} transparent`, //only firefox > 64
		scrollbarWidth: 'thin', //only firefox > 64
	},
	'scrollIconContainer': {
		textAlign: 'center',
		marginTop: '10px'
	},
	titleStepZero: {
		padding: "10px 20px",
		backgroundColor: ourGreen,
		color: "white",
		borderRadius: "2rem",
		textAlign: "center",
		marginBottom: "60px"
	},
	titleMobile: {
		"@media only screen and (max-width: 960px)": {
			marginTop: "10px",
			textAlign: "center"
		},
	},
	moduleCardInnerWrapper1: {
		backgroundColor: "white",
		borderRadius: "50%",
		width: "100%",
		height: "100%",
		margin: "auto",
	},
	moduleCardWrapper1: {
		//margin: "2rem auto 0 auto",
		margin: "0 auto 0 auto",
		padding: "3%",
		textAlign: "center",
		backgroundColor: ourGreen,
		borderRadius: "50%",
		width: "70%",
		[theme.breakpoints.down('md')]: {
			width: "45%",
		},
		"@media only screen and (max-width: 960px)": {
			marginBottom: "10px"
		},
	},
	smallButton: {
		backgroundColor: "#EEF9FF",
		color: "black",
		border: "0px",
		borderRadius: "20px",
		width: "100%",
		//cursor: "pointer",
		fontWeight: "bolder",
		//marginRight: "1rem",
		/* [theme.breakpoints.down("sm")]: {
		  marginBottom: "1rem",
		}, */
	},
	guideTitle: {
		marginTop: "2px",
		fontSize: "0.85rem",
		fontWeight: "500",
	},
	buttonContainer: {
		textAlign: "right",
		paddingRight: "0",
		margin: "50px 0 30px 0",
		//marginBottom: "60px",
		"@media only screen and (max-width: 960px)": {
			marginTop: "30px"
		}
	},
	startbuttonContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end", // Align vertically to the end
		alignItems: "flex-end", // Align horizontally to the end
		flexGrow: 1, // Add this line
		marginTop: "30px",
	},
	indexNumbers: {
		"@media only screen and (max-width: 960px)": {
			marginRight: '20px'
		}
	},
});

/** Helper Component to encapsulate Number Icons used here */
const NumberIcon = withStyles(numIcons)(
	({ classes, children }) => <span className={classes.root}>{children}</span>
);

const LearningItem = (
	({ classes, number, content, props }) => {
		const textComponent = <p style={{fontSize: props.isMobile ? "15px" : "18px"}}> {content} </p>;
		return (
			<GridContainer style={{ alignItems: "start", paddingBottom: "1rem" }}>
				<GridItem xs={props.isMobile ? 3 : 2} sm={props.isMobile ? 2 : 1} md={props.isMobile ? 2 : 1} space={0}>
					<div style={{width: "115%"}}>
						<button disabled className={classes.smallButton} style={{fontSize: props.isMobile ? "15px" : "20px"}}>
							{number}.
						</button>
					</div>
				</GridItem>
				<GridItem xs={9} sm={11} md={11} space={0}>
					{ textComponent }
				</GridItem>
			</GridContainer>
		)
	}
);

const StepItem = (
	//type: can be either 'standard' or 'begin'
	//this type defines which step marker should be displayed
	//'begin' type is supposed to have a rounded right side.
	({ classes, text, index, type = 'standard' }) => (
		/*<div className={classes.stepItem}>
			<div className={classes.stepItemText}>{text}</div>
			<div className={type==='begin' ? classes.beginStepMarker : classes.stepMarker}>
				<span className={classes.stepText}>{index+1}</span></div>
		</div>*/
		<GridContainer style={{ alignItems: "center", marginBottom: "10px" }}>
			<GridItem xs={2} sm={2} md={1} >
				<span className={classes.indexNumbers} style={{ fontSize: "22px", color: ourBlue, fontWeight: "900", display: 'flex', justifyContent: 'center', alignSelf: 'left !important' }}>{index + 1}</span>
			</GridItem>
			<GridItem xs={10} sm={10} md={11} spaceRigh={0}>
				<div style={{ backgroundColor: "#F7F6F3", padding: "7px 5px 7px 15px", marginLeft: "-10px", borderRadius: "8px" }}>
					{ReactHtmlParser(ReactHtmlParser(text))}</div>
			</GridItem>
		</GridContainer>
	)

);

function withMyHook(Component) {
	return function WrappedComponent(props) {
		const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
		return <Component {...props} isMobile={mobile} />;
	}
}


class StepZero extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showScrollIcon: false,
			showReferencesModal: false,
		};
	}

	handleResize = () => {
		const x = document.getElementById("checkOverflow");
	}

	componentDidMount() {
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	handleShowReferences = () => {
		this.setState({ showReferencesModal: true });
	};

	showHideModal = () => {
		this.setState({
			showReferencesModal: !this.state.showReferencesModal
		});
	};

	render() {
		const { classes,
			title,
			introduction,
			steps,
			imageModule,
			learnings,
			feedbacks,
			type,
			completebefore,
			modlength,
			startLink,
			acknowledgments,
			references,
			t,
			colorTitle,
		} = this.props;

		const stepItems = steps.map((text, index) =>
			<StepItem key={index} classes={classes} index={index} type={index === 0 ? 'begin' : 'standard'} text={text} />);

			learnings.map((text, index) => console.log('TEXT --->  ', text))

		const learnItems =
			learnings.map((text, index) =>
				<LearningItem key={index} number={index + 1} classes={classes} content={text} props={this.props}/>);

		return (
			<div className={classes.root}>
				<Container>
					<div className={classes.titleMobile}><h2 style={{ color: colorTitle ? colorTitle : '' }}>{title}</h2></div>
					<GridContainer container>
						<GridItem xs={12} sm={12} md={12} spaceLeft={0}>
							<Card className={classes.card}>
								<CardBody>
									<GridContainer container >
										<GridItem xs={12} sm={12} md={9}
											style={(!this.props.isMobile) ? { borderRight: "1px solid" } : {}}
											spaceLeft={0}>
											<GridContainer container
												alignItems="center"
												justifyContent="center"
											>
												<GridItem xs={12} sm={12} md={4} spaceLeft={0}>
													<div className={classes.blockContainer}>
														<div className={classes.moduleCardWrapper1} style={{ width: '230px', height: '230px' }}>
															<div className={classes.moduleCardInnerWrapper1}>
																<CardImg
																	className="mx-auto d-block"
																	top
																	// height="120"
																	src={imageModule}
																	alt="Card image cap"
																/>
															</div>
														</div>
													</div>
												</GridItem>
												<GridItem xs={12} sm={12} md={8} spaceLeft={0}>
													<div className={classes.blockContainer}>
														<div style={{ margin: "10px 0" }}>
															{ReactHtmlParser(ReactHtmlParser(introduction))}
														</div>
													</div>
												</GridItem>
											</GridContainer>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<div className={classes.blockContainer}
												style={{
													minHeight: "100%",
													display: "flex",
													flexDirection: "column"
												}}>
												<div style={{ margin: "10px 0" }}>
													<div>
														{type !== null && <CardSubtitle className={classes.guideTitle}>
															<span style={{ fontWeight: "900" }}>{t("Type") + ": "}</span>{type}
														</CardSubtitle>}
														{completebefore !== "" && <CardSubtitle className={classes.guideTitle}>
															<span style={{ fontWeight: "900" }}>{(t("Complete before") + ": ")}</span>
															{completebefore}
														</CardSubtitle>}
														{modlength !== null && <CardSubtitle className={classes.guideTitle}>
															<span style={{ fontWeight: "900" }}>{t("Module length") + ": "}</span>{modlength}
														</CardSubtitle>}
													</div>
												</div>
												{references !== undefined && references !== "" &&
													<Link component="button"
														style={{ marginTop: "auto" }}
														onClick={() => this.handleShowReferences()}>
														{t("Click here for the list of references")}</Link>
												}
											</div>
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
					<GridContainer container>
						<GridItem item xs={12} md={12} spaceLeft={0} >
							<Card className={classes.card}>
								<CardBody>
									<div className={classes.learningsWrapper} style={{ display: !this.props.isMobile ? 'flex' : '', justifyContent: !this.props.isMobile ? 'space-between' : '' }}>
										<div style={{ flexGrow: 1 }}>
											<p className={classes.learningsHeading} style={{ color: ourBlue, fontWeight: "bolder" }}>{t('Learning goals')}</p>
											{learnItems}
										</div>
										{this.props.isMobile ?
										<img
										style={{ alignSelf: 'center', marginLeft: '1rem' }}
										src={GoalImage}
										width={"80%"}
										height={"80%"}
										alt="Goal image"
									/> : 
									<img
											style={{ alignSelf: 'center', marginTop: '0.6rem'}}
											src={GoalImage}
											width={"16%"}
											height={"16%"}
											alt="Goal image"
										/> 
										}
									</div>
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
					<GridContainer container>
						<GridItem item xs={12} md={7} spaceLeft={0} >
							<Card className={classes.card} style={{ background: ourLightGreen }}>
								<CardBody>
									<GridContainer style={{ alignItems: "center" }}>
										<GridItem item xs={2} md={1}>
											<img
												src={activity}
												className={classes.dotimage2}
												width="60px"
												style={{ paddingRight: "20px" }}
												alt='Feedback activity'
											/>
										</GridItem>
										<GridItem item xs={10} md={11}>
											<div>
												<p
													style={{ margin: "0 0 0 15px" }}
													className={classes.learningsHeading}>
													{t('Feedback activities')}</p>
											</div>
										</GridItem>
										<div style={{ marginTop: "10px" }}>{ReactHtmlParser(ReactHtmlParser(feedbacks))}</div>
									</GridContainer>
								</CardBody>
							</Card>
						</GridItem>
						<GridItem item xs={12} md={5} spaceRigh={0} style={{ display: 'flex', flexDirection: 'column' }}>
							<Container className={classes.startbuttonContainer}>
								<ActionButton
									size="large"
									style={{ minWidth: '360px', borderRadius: '5px', fontSize: "1.5rem", marginRight: this.props.isMobile ? "" : "-1.4rem" }}
									centered={true}
									component={RouterLink}
									to={startLink}>
									{t('Start the module')}
								</ActionButton>
							</Container>
						</GridItem>
						<GridItem item xs={12} md={7} spaceLeft={0} >
							<span style={{ fontStyle: "italic", fontSize: "11px", marginTop: "20px" }}>
								{ReactHtmlParser(ReactHtmlParser(acknowledgments))}
							</span>
						</GridItem>
					</GridContainer>
					<Modal
						id="19"
						show={this.state.showReferencesModal}
						onHide={this.showHideModal}
						centered
						size="lg"
						className={classes.modalBox}
					>
						<Modal.Body style={{ wordBreak: 'break-word' }}>
							<h4>{t("References")}</h4>
							<div>
								{references !== undefined && references !== "" &&
									ReactHtmlParser(ReactHtmlParser(references))
								}
							</div>
						</Modal.Body>
					</Modal>
				</Container>
			</div>
		);
	}
}

//If you want a quick example on how to use each prop, you can uncomment this.
// StepZero.defaultProps = {
// 	learnings:[],
//	steps:[]
// };

//This is useful to avoid any errors at development stage, and when testing.
StepZero.propTypes = {
	learnings: PropTypes.arrayOf(PropTypes.string),
	steps: PropTypes.arrayOf(PropTypes.string)
};

export default withTranslation()(withMyHook(withStyles(style)(StepZero)));