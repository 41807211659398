import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from 'assets/languages/en/translation.json';
import translationDK from 'assets/languages/da/translation.json';
import translationFI from 'assets/languages/fi/translation.json';
import translationSV from 'assets/languages/sv/translation.json';


// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: translationEN
  },
  /*es:{
    translation: translationES
  },*/
  fi:{
    translation: translationFI
  },
  da:{
    translation: translationDK
  },
  sv: {
    translation: translationSV
  }
};

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const saveCache = urlParams.get('svlng');


i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    //supportedLngs: ["en", "da"],
    supportedLngs: ["en","fi","da", "sv"],
    fallbackLng: "en",
    returnEmptyString: false, //This is needed to avoid empty translations for keys with empty strings
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    detection: {
      caches: saveCache!=="0" ? ['localStorage']:[],
      order: ['localStorage']
    }
  });

  export default i18n;