/*!
 * Description: this file contains the component for
 *  the type of exercise SelfWriting, ex. guide 2, step 5,
 *  					"  Exercise: Win-Win-Win situations at your workplace"
 * Components: SelfWriting
 */
import React from "react";

// Styles
import withStyles from "@mui/styles/withStyles";
import { ourDarkGreen, standardRadious } from "assets/jss/incept-sustainability-variables.jsx";

// Style components
import Container from "@mui/material/Container";
import CardBody from "components/Card/CardBody.jsx";

// Forms
import TextField from "@mui/material/TextField";
import TextArea from "components/CustomInput/TextArea";

import Card from "components/Card/Card.jsx";

//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";
//Translations
import { withTranslation } from 'react-i18next';

const styles = {
	root: {
		"@media only screen and (max-width: 600px)": {
			margin: "0 20px 0 10px"
		}
	},
	buttonContainer: {
		textAlign: "right",
		marginTop: "20px"
	},
	cardAnswer: {
		borderRadius: standardRadious,
		"@media only screen and (max-width: 960px)": {
			borderRadius: "1rem",
			paddingTop: "17px"
		}
	},
	showmessage: {
		display: "block",
		color: ourDarkGreen
	},
	nshowmessage: {
		display: "none"
	},
	footertext: {
		fontStyle: "italic",
		fontSize: "11px"
	},
	textField: {
		'& .MuiInput-underline:after ':{
			borderBottom: "2px solid "+ourDarkGreen,
		}
	}


};

/*
 * component that renders exercise with input field
 */
class SelfWriting extends React.Component {
	state = {
		userAnswer: "",
		messagesent: (typeof this.props.datasav2 !== "undefined") ? true: false,
		messageerror: false
	};

	componentDidMount() {
		//retrieve exercise here
		if (typeof this.props.datasav2 !== "undefined") {
			this.setState({ userAnswer: this.props.datasav2["content"] });
		}
	}

	handleChange(e) {
		this.setState({ userAnswer: e.target.value });
		this.setState({ messagesent: false });
		//this.props.save2("Selfwriting", e.target.value, this.props.inkey, this.props.exercise_id);
	}

	handleSubmit(e) {
		e.preventDefault();
		//Commented to allow empty answers
		//if (this.state.userAnswer !== "") {
			this.props.save2("Selfwriting", this.state.userAnswer, this.props.inkey, this.props.exercise_id);
			this.setState({ messagesent: true });
		//}
	}

	render() {
		const { classes, t } = this.props;

		return (
			<div className={classes.root}>
				<Card className={classes.cardAnswer}>
					<CardBody className={classes.answerBox} isexercise={true}>
						<form
							noValidate
							autoComplete="off"
							onSubmit={(e) => this.handleSubmit(e)}
						>
							<Container>
								<TextArea
									onChange={(e) => this.handleChange(e)}
									value={this.state.userAnswer}
									borderColor={ourDarkGreen}
									borderRadius={standardRadious}
								/>
								<p className={classes.footertext}>
									{t('no personal data',
										{
											defaultValue: 'Please keep your answer anonymous, do not write any personal data in your answer.'
										})}
								</p>
								<p className={(this.state.messagesent) ? classes.showmessage : classes.nshowmessage}>
									{t('Thank you for submitting your answer')}</p>
							</Container>
							<Container className={classes.buttonContainer}>
								<ActionButton
									style={{width:"auto"}}
									type="submit">
									{t('Submit')}
								</ActionButton>
							</Container>
						</form>
					</CardBody>
				</Card>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(SelfWriting));
