import {useState} from "react";

const useLabelsData = () => {
    const [textLabel, setTextLabel] = useState("");
    const [submittingNewLabel, setSubmittingNewLabel] = useState(false);
    const [valueLabel, setValueLabel] = useState(null);
    const [addNewLabel, setAddNewLabel] = useState(false);
    const [editLabel, setEditLabel] = useState(false);
    const [deleteLabel, setDeleteLabel] = useState(false);
    const [deleteLabelValue, setDeleteLabelValue] = useState(" ");
    const [labelIdeaId, setLabelIdeaId] = useState(-1);
    const [labelIdDel, setLabelIdDel] = useState(-1);
    const [labelIdeaIdIndex, setLabelIdeaIdIndex] = useState(-1);
    const [editLabelArray, setEditLabelArray] = useState({});


    return {
        textLabel, setTextLabel,
        submittingNewLabel, setSubmittingNewLabel,
        valueLabel, setValueLabel,
        addNewLabel, setAddNewLabel,
        editLabel, setEditLabel,
        deleteLabel, setDeleteLabel,
        deleteLabelValue, setDeleteLabelValue,
        labelIdeaId, setLabelIdeaId,
        labelIdDel, setLabelIdDel,
        labelIdeaIdIndex, setLabelIdeaIdIndex,
        editLabelArray, setEditLabelArray
    }
}

export default useLabelsData