/*!
 * Description: this file contains the component for
 *  the type of exercise DragAndDropTwoList, ex. guide 1, step 6, "Exercise: Your Sustainable Work Culture"
 * Components: DragAndDropTwoList
 */
import React from "react";


// Styles
import withStyles from "@mui/styles/withStyles";
import clsx from "clsx";

// Style components
import Grid from "@mui/material/Grid";
import circle from "../../assets/img/elements-28.png";

//Select alternative for drag and drop
import DragAndDropBecomesSelect from "components/Exercise/DragAndDropTwoListBecomesSelect";
import { grayCape20, standardRadious, ourDarkGreen,ourMediumGreen } from 'assets/jss/incept-sustainability-variables.jsx';
import Snackbar from "@mui/material/Snackbar";
//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import Container from "@mui/material/Container";
import checked from 'assets/img/checked.png';
//Translations
import { withTranslation } from 'react-i18next';

const colors = {
	attention: '#FF6F69',
	idea: '#7BDCA3',
	textBase: '#000',
	scrollbar: grayCape20
};

const styles = {
	root: {
		paddingTop: "30px",
		"@media only screen and (max-width: 960px)": {
			padding: "30px 20px 0 10px"
		}
	},
	dropzonesContainer: {
		padding: "50px 0 30px 0",
	},
	buttonContainer: {
		textAlign: "right",
		paddingRight: "0",
		//marginBottom: "60px",
		"@media only screen and (max-width: 960px)": {
			marginTop: "30px"
		}
	},
	gridDropItem: {
		padding: "10px",
	},
	dropListBase: {
		padding: "10px 10px 40px 10px",
		//border: `3px solid #FFF`,
		borderRadius: standardRadious,
		backgroundColor: ourMediumGreen,
		width: "100%",
	},
	dropListTitle: {
		marginBottom: "10px",
	},
	dropListOne: {},
	dropListTwo: {},
	dropListIcon: {
		height: "60px",
		margin: "10px",
		"@media only screen and (max-width: 960px)": {
			height: "40px",
			margin: "5px",
		}
	},
	dropListMain: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
	},
	dropZone: {
		minHeight: "100px",
	},
	optionsContainer: {
		/* padding: "20px 50px",*/
		maxHeight: "360px",
		height: "100%",
		minHeight: "100px",
		overflow: "auto",
		border: "2px solid "+ourDarkGreen,
		borderRadius: "2rem",
		'&::-webkit-scrollbar-track': {
			boxShadow: "inset 0 0 5px grey",
			borderRadius: "10px"
		},
		'&::-webkit-scrollbar': {
			height: '7px'
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: colors.scrollbar,
			borderRadius: '20px'
		},
		scrollbarColor: `${colors.scrollbar}`, //only firefox > 64
		scrollbarWidth: 'auto', //only firefox > 64
	},
	gridOptionItem: {
		padding: "0 20px"
	},
	optionBlock: {
		padding: "13px",
		height: "100%",
		width: "100%",
		//backgroundColor: "rgba(203,203,203,0.2)",
		border: "1px solid "+ourDarkGreen,
		color: ourDarkGreen,
		borderRadius: "1.5rem",
		textAlign: "inherit",
		fontWeight: "bold",
		fontSize: "13px",
		"&[draggable=true]": {
			cursor: "grab",
			"&:hover": {
				fontWeight: "bold",
				border: "2px solid "+ourDarkGreen,
			},
			"&:active": {
				cursor: "grabbing",
			},
		},
	},
	optionRow: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		cursor: "pointer",
		height: "100%",
		padding: "10px 0"
	},
	circle: {
		height: "30px",
		width: "30px",
		marginLeft: "10px",
		marginRight: "15px",
	},
	statusContainer: {
		textAlign: "right",
		paddingRight: "0",
		marginBottom: "20px",
		"@media only screen and (max-width: 960px)": {
			marginTop: "20px"
		  }
	}
};

/*
 * Description: component for the DragAndDropTwoList exercise
 */
class DragAndDropTwoList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dragList: [...this.props.exercise.options].map((item) => {
				return { ...item, show: true, statement_id: item.statement_id }; //{show: true, text: 'blah'}
			}),
			dropListOne: [],
			dropListTwo: [],
			openAlert: false,
			containerref: React.createRef(),
			answer_to_save: [],
			submittedanswer: (typeof this.props.datasav2 !== "undefined") ? true: false
		};

		if (typeof this.props.datasav2 !== "undefined" && this.props.datasav2["content"].length>0) {
			console.log(this.props.datasav2["content"]);
			var aux = this.props.datasav2["content"];

			var clonedraglist = this.clone(this.state.dragList); //clone draglist
			var flist = true;
			for (var index0 = 0; index0 < clonedraglist.length; index0++) {
				for (var index2 = 0; index2 < aux.length; index2++) {
					if (clonedraglist[index0].statement_id === aux[index2].statement_id) {
						//this.state.dragList[index0]=false;
						this.state.dragList[index0].show = false;
						continue;
					}
				}
			}
			for (var index = 0; index < aux.length; index++) {
				if (aux[index] === "") {
					flist = false;
					continue;
				}
				let element = clonedraglist.find(x => x.statement_id === parseInt(aux[index].statement_id));
				if (flist) this.state.dropListOne.push(element);
				else this.state.dropListTwo.push(element);
			}
		}
	}

	clone(obj) {
		if (null == obj || "object" != typeof obj) return obj;
		var copy = obj.constructor();
		for (var attr in obj) {
			if (obj.hasOwnProperty(attr)) copy[attr] = this.clone(obj[attr]);
		}
		return copy;
	}

	handleSubmit(e) {
		//if (this.state.answer_to_save.length>0) {
			this.setState({ submittedanswer: true });
			this.props.save2("DragDropTwo", this.state.answer_to_save, this.props.inkey,
					this.props.exercise_id);
		//}
	}

	onDragStart = (event, optionIndex, dragZone, scrolltodiv) => {
		event.dataTransfer.setData("optionIndex", optionIndex.toString());
		event.dataTransfer.setData("dragZone", dragZone.toString());
		//if (scrolltodiv)
		//this.executeScroll();
	};

	onDragOver = (event) => {
		event.preventDefault();
	};

	handleClose(e, reason) {
		if (reason === "clickaway") {
			return;
		}
		this.setState({ openAlert: false });
	}

	onDrop = (event, listID, optIndex = false) => {
		event.preventDefault();
		
		


		let optionIndex = parseInt(event.dataTransfer ? event.dataTransfer.getData("optionIndex") : optIndex);

		//If we don't have a valid Integer, then we must be dropping something else than option.
		if (isNaN(optionIndex)) {
			return;
		}

		let dragZone = event.dataTransfer ? event.dataTransfer.getData("dragZone") : 'start';

		this.setState((state) => {
			const max = this.props.exercise.maxSelection;
			if (listID === "one" && (state.dropListOne.length) >= max) {
				return { openAlert: true };
			}
			if (listID === "two" && (state.dropListTwo.length) >= max) {
				return { openAlert: true };
			}

			let returnObj = {};
			let dropList = [];
			let sendlist = [];
			let dragListRef = [];
			let dropListRef = [];
			let dragListFin = [];
			let draggedItems = [];
			let dragListName = "";
			let dropListName = "";
			if (dragZone === "one") {
				dragListName = "dropListOne";
				dragListRef = state.dropListOne;
			} else if (dragZone === "two") {
				dragListName = "dropListTwo";
				dragListRef = state.dropListTwo;
			} else if (dragZone === "start") {
				dragListName = "dragList";
				dragListRef = state.dragList;
			}
			if (listID === "one") {
				dropListRef = state.dropListOne;
				dropListName = "dropListOne";
			} else if (listID === "two") {
				dropListRef = state.dropListTwo;
				dropListName = "dropListTwo";
			} else {
				dropListRef = state.dragList;
				dropListName = "dragList";
			}

			/* It means we are trying to drop in the same zone so nothing should change */
			if (dragListName === dropListName) {
				return {};
			}
			dragListFin = dragListRef.filter((item, index) => index !== optionIndex);
			draggedItems = dragListRef.filter((item, index) => index === optionIndex);
			dropList = [...dropListRef, ...draggedItems];

			if (dragListName === "dragList") {
				dragListFin = dragListRef.map((item, index) => {
					if (index === optionIndex) {
						item.show = false;
					}
					return item;
				});
			}

			returnObj[dragListName] = dragListFin;

			if (dropListName === "dragList") {
				dropList = dropListRef.map((item, index) => {
					if (dragListRef[optionIndex].text === item.text) {
						item.show = true;
					}
					return item;
				});
			}

			returnObj[dropListName] = dropList;

			if (listID === "one") {
				if (dragZone === "two") {
					sendlist = [...dropList, "", ...dragListFin];
				} else {
					sendlist = [...dropList, "", ...state.dropListTwo];
				}
			} else if (listID === "two") {
				if (dragZone === "one") {
					sendlist = [...dragListFin, "", ...dropList];
				} else {
					sendlist = [...state.dropListOne, "", ...dropList];
				}
			} else {
				if (dragZone === "one") {
					sendlist = [...dragListFin, "", ...state.dropListTwo];
				} else {
					sendlist = [...state.dropListOne, "", ...dragListFin];
				}
			}

			/* remote state */
			//this.props.save2("DragDropTwo", sendlist, this.props.inkey, this.props.exercise_id);
			/*finish remote state */
			returnObj["answer_to_save"] = sendlist;
			returnObj["submittedanswer"] = false;
			return returnObj;
		});
	};

	scrollToRefObject = (ref) => window.scrollTo(0, ref.current.offsetTop);

	executeScroll() { this.scrollToRefObject(this.state.containerref) };

	_renderOption(option, index, dragZone = "start") {
		const { classes } = this.props;
		const { text, imageURL } = option;
		const extraProps = {};
		extraProps["draggable"] = true;
		const pointtodrag = (dragZone === "one" || dragZone === "two") ? false : true;
		extraProps["onDragStart"] = (e) => this.onDragStart(e, index, dragZone, pointtodrag);
		const imgicon = ((imageURL==="") || (dragZone === "one" || dragZone === "two")) ? "" : <img alt="" src={imageURL} className={classes.dropListIcon} id={"icon" + index} />;
		return (
			<div className={classes.optionRow} key={index} {...extraProps}>
				{imgicon}
				<div draggable className={classes.optionBlock}>
					{text}
				</div>
			</div>
		);
	}

	_renderOptions(options) {
		const { classes } = this.props;
		const blocks = this.state.dragList.map((option, index) => {
			if (option.show) {
				return (
					<Grid
						item
						xs={12}
						md={6}
						className={classes.gridOptionItem}
						key={"options" + index}
					>
						{this._renderOption(option, index)}
					</Grid>
				);
			}
			return "";
		});

		return <Grid container>{blocks}</Grid>;
	}

	_renderTwoDropZoneLists(dropListOne, dropListTwo) {
		const { classes } = this.props;

		const classDropListOne = clsx(classes.dropListBase, classes.dropListOne);
		const classDropListTwo = clsx(classes.dropListBase, classes.dropListTwo);

		return (
			<Grid container ref={this.state.containerref}>
				<Grid item xs={12} md={6} className={classes.gridDropItem}>
					<div className={classes.dropListMain} style={{height:"100%"}}>
						<div style={{ position: "relative", textAlign: "center" }}>
							<img alt="" src={circle} className={classes.circle} />
							<div style={{ position: "absolute", top: "1%", color: "white",left: "38%", fontWeight: "bold", fontFamily: "Arial" }}>
								1
              </div>
						</div>
						<div
							className={classDropListOne}
							onDrop={(e) => this.onDrop(e, "one")}
							onDragOver={(e) => this.onDragOver(e)}
						>
							<div className={classes.dropZone}>
								<div style={{ textAlign: "center", margin: "0 5px" }}><h6>{dropListOne.title}</h6></div>
								{this.state.dropListOne.map((option, index) =>
									this._renderOption(option, index, "one")
								)}
							</div>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} md={6} className={classes.gridDropItem}>
					<div className={classes.dropListMain} style={{height:"100%"}}>
						<div style={{ position: "relative", textAlign: "center" }}>
							<img alt="" src={circle} className={classes.circle} />
							<div style={{ position: "absolute", top: "1%", left: "38%", color: "white", fontWeight: "bold", fontFamily: "Arial" }}>
								2
              </div>
						</div>

						<div
							className={classDropListTwo}
							onDrop={(e) => this.onDrop(e, "two")}
							onDragOver={(e) => this.onDragOver(e)}
						>
							<div className={classes.dropZone}>
								<div style={{ textAlign: "center", margin: "5px 0" }}><h6>{dropListTwo.title}</h6></div>
								{this.state.dropListTwo.map((option, index) =>
									this._renderOption(option, index, "two")
								)}
							</div>
						</div>
					</div>
				</Grid>
			</Grid>
		);
	}

	/** this is used for altenative Select component when viewing DragAndDrop in mobiles */

	// CAREFUL: if you make changes in how to save data, you need to make changes in
	// onDrop function too!!!!
	handleSelectChange(event, optionIndex, optionsArray) {
		const dropListIndex = parseInt(event.target.value);
		//-1 means draglist, 0 droplist one, 1 droplist two
		const max = this.props.exercise.maxSelection;
		if (dropListIndex === 0 && (this.state.dropListOne.length) >= max) {
			this.setState({ openAlert: true });
			return;
		}
		if (dropListIndex === 1 && (this.state.dropListTwo.length) >= max) {
			this.setState({ openAlert: true });
			return;
		}
		this.setState((prevState) => {
			const statementID = optionsArray[optionIndex].statement_id;
			// First, we remove it from all lists
			let newDragList = prevState.dragList.map(
				(item) => ({ ...item, show: item.statement_id === statementID ? false : item.show })
			);
			let newDropListOne = prevState.dropListOne.filter(
				(item) => item.statement_id !== statementID
			);
			let newDropListTwo = prevState.dropListTwo.filter(
				(item) => item.statement_id !== statementID
			);
			//Once removed, we search for the proper list it should be added now.
			switch (dropListIndex) {
				case -1:
					newDragList = newDragList.map(
						(item) => ({ ...item, show: item.statement_id === statementID ? true : item.show })
					);
					break;
				case 0:
					newDropListOne = [...newDropListOne, optionsArray[optionIndex]];
					break;
				case 1:
					newDropListTwo = [...newDropListTwo, optionsArray[optionIndex]];
					break;
				default:
					console.log("Error: trying to access a non-existant drop list");
					break;
			}
			//then, we save by concatenating both drop lists
			//this.props.save2("DragDropTwo", [...newDropListOne, "", ...newDropListTwo], this.props.inkey, this.props.exercise_id);
			return {
				dragList: newDragList,
				dropListOne: newDropListOne,
				dropListTwo: newDropListTwo,
				answer_to_save: [...newDropListOne, "", ...newDropListTwo],
				submittedanswer: false
			}
		})
	}

	render() {
		const { classes, t, exercise } = this.props;
		const { options, dropListOne, dropListTwo } = exercise;

		//const touch = window.matchMedia('(hover: none)').matches;
		//This works on andrid as well:
		const touch = window.matchMedia('(pointer: coarse)').matches;

		const dragAndDrop =
			<React.Fragment>
				<div
					className={classes.optionsContainer}
					onDrop={(e) => this.onDrop(e, "start")}
					onDragOver={(e) => this.onDragOver(e)}
				>
					{this._renderOptions(this.state.dragList)}
				</div>
				<div className={classes.dropzonesContainer}>
					{this._renderTwoDropZoneLists(dropListOne, dropListTwo)}
				</div>
			</React.Fragment>;

		const select =
			<DragAndDropBecomesSelect
				questions={options}
				dropListOne={{ title: dropListOne.title, answers: this.state.dropListOne }}
				dropListTwo={{ title: dropListTwo.title, answers: this.state.dropListTwo }}
				onChange={(event, optIndex) => this.handleSelectChange(event, optIndex, options)}
			/>;
		return (
			<div className={classes.root}>
				{ touch ? select : dragAndDrop}
				<div className={classes.statusContainer}>
				{this.state.submittedanswer && <p>{t("Thank you! Your feedback has been submitted")}</p>}
				</div>
				<Container className={classes.buttonContainer}>
					<ActionButton
						style={{width:"auto"}}
						type="submit"
						centered={true}
						onClick={(e) => this.handleSubmit(e)}
					>
						{t('Submit')}
					</ActionButton>
				</Container>
				<Snackbar
					open={this.state.openAlert}
					autoHideDuration={3000}
					onClose={(e, reason) => this.handleClose(e, reason)}
					message={t('You must choose up to ') + this.props.exercise.maxSelection + t(' for each box')}
				></Snackbar>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(DragAndDropTwoList));


