/*!
* Description: this file contains a component
*  that renders a disabled lookalike button that contains numbers or text
*/

import React from 'react';
import withStyles from "@mui/styles/withStyles";

const rightComment = 	{
	marginTop: "-30px",
    paddingTop: "0px",
    position: "relative",
    bottom: "-45px",
    left: "95%",
    borderWidth: "30px 0 0 30px",
    borderStyle: "solid",
    display: "block",
    width: "0"
};

const leftComment = 	{
	marginTop: "-35px",
    paddingTop: "0px",
    position: "relative",
    bottom: "-43px",
    left: "0",
    borderWidth: "30px 30px 0 0",
    borderStyle: "solid",
    display: "block",
    width: "0"
};

const useStyles = () => ({
	speechbubblePer: ({color, textColor,borderColor,isleft}) => ({
		backgroundColor: color,
        borderLeft: isleft ? "5px solid":"",
        borderRight: !isleft ? "5px solid "+borderColor:"",
		color: textColor,
        borderColor: borderColor,
		border: "0px",
		borderRadius: "16px",
		fontSize: "14px",
		padding: "15px 25px",
		marginBottom: "0.5rem",   
        '&::after' : {
            content:'""',
            borderColor: color +" transparent",
            ...(isleft ? leftComment:rightComment),   
        },
        '& p::before': {
            content: '"“"',
            fontFamily: "Georgia",
            fontSize: "40px",
            lineHeight: "0",
            display: "inline-block",
            display: "-webkit-inline-box",
            marginTop: "15px"
        }
	})
});

function CommentBubble(props) {
	const {classes, content,borderColor,textColor,...rest} = props;

	return (
		<div className={classes.speechbubblePer} {...rest}>
		<p>{content}</p>
		</div>
	);
}

export default withStyles(useStyles)(CommentBubble);