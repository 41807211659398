/*!
* Description: this file contains a component
*  that renders a disabled lookalike button that contains numbers or text
*/

import React, { useState } from 'react';
import withStyles from "@mui/styles/withStyles";

import analyticsStyle from "assets/jss/views/analyticsStyle.jsx"; //style for all analytics
import { ourGreen20,ourOrange,standardRadious } from "assets/jss/incept-sustainability-variables.jsx";
import CardAnalytics from "components/CardAnalytics/Card.jsx";
import CardAnalyticsHeader from "components/CardAnalytics/CardHeader.jsx";
import CardAnalyticsBody from "components/CardAnalytics/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomBox from "components/CustomLayout/CustomBox.jsx";
import moreInfo from 'assets/img/moduleIcon.svg';
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import { Link } from "react-router-dom";
import newtab from "assets/img/newtab.png";
import { useMediaQuery } from 'react-responsive'; //for mobile responsiveness
import Tooltip from '@mui/material/Tooltip';

const useStyles = (theme) => ({
    textCard: {
        backgroundColor: ourGreen20,
        borderRadius: standardRadious,
        padding: "30px",
        minHeight: "200px"
    },
    commentsContainer: {
        display: "flex", 
        marginBottom: "1rem",
        "& p": {
            cursor: "pointer",
        } 
    },
    ellipsistext: {
        "& p": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            "@supports (-webkit-line-clamp: 4)": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "initial",
            display: "-webkit-box",
            WebkitLineClamp: "4",
            WebkitBoxOrient: "vertical"
            }
        },
    },
    quoteText: {
        "&:before": {
            fontSize: "1.5rem",
            lineHeight: "0.5rem",
        },
        "&:after": {
            fontSize: "1.5rem",
            lineHeight: "0.5rem",
        },
    }
});


function withMyHook(Component) {
    return function WrappedComponent(props) {
      const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
      return <Component {...props} isMobile={mobile} />;
    }
}

const TextOnlyTooltip = withStyles({
	tooltip: {
		fontSize: "14px"
	}
})(Tooltip);

function FreeTextCard(props) {
	const {classes, exerciseid, filter={}, showButton=true, content} = props;

	console.log(props.filter);

	const items_content = [];
    let index2 = 0;
    let ind = "";
    props.data.forEach((arrayItem,index) => {
		if (!props.hideExportedB || (props.hideExportedB && !arrayItem.isexported)) {
            items_content.push(
                <GridItem xs={12} sm={12} md={6} lg={4} key={props.exerciseid+""+index} otherclasses={classes.columnCardsPadding}>
                    <CustomBox
                    key={props.exerciseid+""+index}
                    index={index}
                    exerciseid={props.exerciseid}
                    content={arrayItem.description}
					id={arrayItem.answerid}
					generalPage={props.generalPage}
					isexported={arrayItem.isexported}
					checked={!props.generalPage && (typeof props.listchecked[index]==="boolean") ? props.listchecked[index]:false}
					handleChangeCheckbox={props.handleChangeCheckbox}
                    />              
                </GridItem>
            );
		}
        index2 = index2+1;
    });

	return (
            <CardAnalytics>
                <CardAnalyticsHeader title={props.title}>
                    <div style={{marginLeft:"auto",cursor:"pointer"}}>
                        <TextOnlyTooltip title={"How are the stats calculated?"} arrow>
                            <img
                                className="responsive2"
                                src={moreInfo}
                                alt="How are the stats calculated?"
                                onClick={(e) => props.showHideModal("freeText")}
                            />
                        </TextOnlyTooltip>
                    </div>
                </CardAnalyticsHeader>
                <CardAnalyticsBody>
                    {props.children}
					{!showButton && <GridContainer space={false} style={{textAlign:"right",marginBottom:"20px"}} direction="row-reverse">
						<GridItem otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"0"}:{}}>
							<ActionButton
								type="submit"
								isAnalytics={true}
								onClick={() => props.hideExportedF()}
							>
								{(props.hideExportedB ? 'Show exported answers':'Hide exported answers')}
							</ActionButton>
						</GridItem>
						<GridItem otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"0"}:{}}>
							<ActionButton
								type="submit"
								isAnalytics={true}
								disabled={props.nonCheckedideas}
								onClick={() => props.exportOpenModal()}
							>
								{'Export to "Ideas and Comments"'}
							</ActionButton>
						</GridItem>
					</GridContainer>}
                    <div style={{marginBottom:"60px"}}>
                        <GridContainer justifyContent="center">
                            {items_content}
                        </GridContainer>
                        <div style={{textAlign:"right",marginRight:"30px"}}>
                        {(props.nitems>5) && (showButton) && 
									<Link 
										target="_blank" 
											to={{pathname:"/admin/custom_activity/"+exerciseid, 
											search:props.filter ? "?filter="+JSON.stringify(props.filter):""
										}}
										style={{marginTop:"20px"}}>
                            <ActionButton 
                                isAnalytics={true}
                                centered={true}
                                nomargin={true}
                            >
                                {'See all custom answers '}
                                <img
                                    className="responsive"
                                    src={newtab}
                                    width="15"
                                    alt=""
                                    style={{ marginLeft: "10px" }}
                                />
                            </ActionButton>
                        </Link>}
                        </div>
                    </div>
                </CardAnalyticsBody>
            </CardAnalytics>
	);
}
export default
withMyHook(withStyles(
    (theme) => ({
      ...analyticsStyle(theme),
      ...useStyles(theme),
    }),
    { withTheme: true },
  )(FreeTextCard));
